


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {IWsMessage} from '@/websocket/messagesType';
import {EventBus} from '@/EventBus';


@Component({
    components: {},
    name: 'WSAlert'
})
export default class WSAlert extends Vue {
    @Prop(Object) private readonly event!: IWsMessage;
    private dismissSecs: number = 10;
    private dismissCountDown: number = 10;

    get message() {
        if (this.event) {
            let message = 'Task ' + this.event.data.task_id;
            switch (this.event.type) {

            }
            message += ' ' + this.event.type;
            return message;
        } else {
            return '';
        }
    }

    private countDownChanged(dismissCountDown: number) {
        this.dismissCountDown = dismissCountDown;
    }

    private clickMessage() {
        this.$store.dispatch('task/fetchById',{id:this.event.data.task_id}).then(()=>{
            EventBus.$emit('openPanel', this.event.data.task_id);
        })
    }
}
