






































import {Component, Prop, Vue} from 'vue-property-decorator';
import Multiselect from "vue-multiselect";
import {usersManager} from "@/datastore/managers/UsersManager";
import {User} from "@/datastore/entities/User";
import {MultiselectUserSchema} from "@/datastore/schemas/UserSchema";

@Component({
    components: {Multiselect},
    name: "UserSelect"
})
export default class UserSelect extends Vue {
    @Prop(Boolean) private readonly allowMultiple!: boolean;
    @Prop({type: Boolean, default: false}) private readonly disabled!: boolean;
    @Prop(Boolean) private readonly allowEmpty!: boolean;
    @Prop(Boolean) private readonly allowCreation!: boolean;
    @Prop(String) private readonly placeholder!: string;
    @Prop() private readonly value!: MultiselectUserSchema | MultiselectUserSchema[];

    private options: MultiselectUserSchema[] = [];
    private isLoading = false;

    public focus(){
        (((this.$refs.multiselect as Vue).$el as HTMLElement).focus())
    }

    private addTag(query: string) {
        if (this.allowCreation) {
            const user: MultiselectUserSchema = {
                name: query,
                email: query,
            };
            if (this.allowMultiple) {
                let v: MultiselectUserSchema[] = [];
                //evito di rimettere i duplicati
                this.options = this.options.filter((e) => {
                    return e.name != user.name
                });
                this.options.push(user);
                v = (this.value as MultiselectUserSchema[]).filter((e) => {
                    return e.name != user.name
                });
                v.push(user);
                this.$emit('input', v);
            } else {
                //evito di rimettere i duplicati
                this.options = this.options.filter((e) => {
                    return e.name != user.name
                });
                this.options.push(user);
                this.$emit('input', user);
            }
        }
    }

    private asyncFind(query: string) {
        this.isLoading = true;
        if (query.length > 0) {
            usersManager.users(query).then(response => {
                this.options = response.map((e: User) => {
                    return e.MultiselectItem;
                });
                this.isLoading = false
            })
        } else {
            this.options = [];
            this.isLoading = false
        }

    }

    private tabEvent(event: any) {
        const ms = this.$refs['multiselect'] as any
        ms.addPointerElement();
    }
}
