












































import {Component, Prop, Vue} from 'vue-property-decorator';
import {User} from '@/datastore/entities/User';
import {Tag} from '@/datastore/entities/Tag';
import {Dashboard} from "@/datastore/entities/Dashboard";
import {MenuRoutes} from "@/routes";
import {EventBus} from "@/EventBus";

@Component({
    components: {},
    name: "DashboardBox"
})

export default class DashboardBox extends Vue {
    @Prop(Boolean) private readonly is_add!: boolean;
    @Prop(Number) private readonly id!: number;
    @Prop(String) private readonly color!: string;
    @Prop(String) private readonly title!: string;
    @Prop(Array) private readonly users!: User[];
    @Prop(Array) private readonly tags!: Tag[];
    @Prop(Date) private readonly created!: Date;
    @Prop(Date) private readonly modified!: Date;
    @Prop(Number) private readonly total_task!: number;
    @Prop(Number) private readonly warning_tasks_counter!: number;
    @Prop(Number) private readonly expired_tasks_counter!: number;
    @Prop(Boolean) private readonly starred!: boolean;
    @Prop(Object) private readonly owner!: User;

    private add: boolean = this.is_add || false;

    get tag() {
        return (this.tags.map((t) => {
            return t.title;
        })).join(',');
    }

    get getColor() {
        return {'background-color': this.color};
    }

    private handleClick() {
        if (!this.is_add) {
            this.$store.dispatch('dashboard/setFilterDashboardId', {id: this.id}).then(() => {
                this.$router.push(MenuRoutes.my.to);
                EventBus.$emit('dashboardChanged');
            })
        }
    }

    private openModalDashboard() {
        this.$bvModal.show('Dashboard');
    }

    private newDashboard() {
        this.$store.dispatch('modal/setActiveDashboardById', {id: -1}).then(() => {
            this.openModalDashboard();
        });
    }

    private openModal(e: MouseEvent) {
        e.stopPropagation();
        this.$store.dispatch('modal/setActiveDashboardById', {id: this.id}).then(() => {
            this.openModalDashboard();
        });
    }

    public toggleStar(e: MouseEvent) {
        e.stopPropagation();
        if (this.starred) {
            Dashboard.unstar(this.id).then((e) => {
                //con il fatto che non rifaccio il refetch delle dashboard aggiorno lo store
                this.$store.dispatch('dashboard/unstar', {id: this.id})
                //oppure posso ripassare l'oggetto che mi viene risposto dal serve
                // this.$store.dispatch('dashboard/updateDashboard', {d:new Dashboard(e)})
            }).catch(() => {

            });
        } else {
            Dashboard.star(this.id).then((e) => {
                //con il fatto che non rifaccio il refetch delle dashboard aggiorno lo store
                this.$store.dispatch('dashboard/star', {id: this.id})
                //oppure posso ripassare l'oggetto che mi viene risposto dal serve
                // this.$store.dispatch('dashboard/updateDashboard', {d:new Dashboard(e)})
            }).catch(() => {

            });
        }
    }
}
