export const MenuRoutes = {
    dashboard: {
        text: 'home',
        to: {
            name: 'dashboard',
            fullPath: '/backoffice/dashboard',
        },
    },
    in: {
        text: 'in',
        to: {
            name: 'details',
            fullPath: '/backoffice/details/in',
            params: {
                type: 'in',
            },
        },
    },
    my: {
        text: 'my',
        to: {
            name: 'details',
            fullPath: '/backoffice/details/my',
            params: {
                type: 'my',
            },
        },
    },
    out: {
        text: 'out',
        to: {
            name: 'details',
            params: {
                type: 'out',
            },
        },
    },
    cc: {
        text: 'cc',
        to: {
            name: 'cc',
        },
    },
    archived: {
        text: 'archived',
        to: {
            name: 'archived',
        },
    },
    messages: {
        text: 'messages',
        to: {
            name: 'messages',
        },
    },
    activities: {
        text: 'activities',
        to: {
            name: 'activities',
        },
    },
    settings:{
        text: 'settings',
        to: {
            name: 'settings',
        },
    }
};

/*link della home*/
export const Home = {
    newtask: {
        text: 'newtask',
        to: {
            name: 'newtask',
        },
    },
    features: {
        text: 'features',
        to: {
            name: 'features',
        },
    },
    signin: {
        text: 'signin',
        to: {
            name: 'signin',
        },
    },
    signup: {
        text: 'signup',
        to: {
            name: 'signup',
        },
    },
};

/* pagine dove non e' richiesta l'autenticazione */
export const PublicAcces = {
    publicTask: {
        text: 'publicTask',
        to: {
            name: 'publicTask',
        },
    },
    sendEmailConfirm: {
        text: 'sendEmailConfirm',
        to: {
            name: 'sendEmailConfirm'
        },
    },
    emailConfirm: {
        text: 'emailConfirm',
        to: {
            name: 'emailConfirm'
        },
    },
    resetPwdConfirm: {
        text: 'resetPwdConfirm',
        to: {
            name: 'resetPwdConfirm',
        },
    },
    resetPwd: {
        text: 'resetPwd',
        to: {
            name: 'resetPwd',
        },
    },
    oauth: {
        text: 'oauth',
        to: {
            name: 'oauth',
        },
    },
};

export function isPublic(name: string) {
    for (const i in PublicAcces) {
        try {
            if ((PublicAcces as any)[i as any].to.name == name) {
                return true;
            }
        } catch (e) {

        }
    }
    return false
}

export function isPrivate(name: string) {
    for (const i in MenuRoutes) {
        try {
            if ((MenuRoutes as any)[i as any].to.name == name) {
                return true;
            }
        } catch (e) {

        }
    }
    return false
}

export const Routes = {Home, MenuRoutes, PublicAcces};
