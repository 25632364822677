import {Dashboard} from "@/datastore/entities/Dashboard";
import {Task} from "@/datastore/entities/Task";
import {Topic} from "@/datastore/entities/Topic";
import {Attachment} from "@/datastore/entities/Attachment";
import {IWsMessage} from '@/websocket/messagesType';

export interface RootState {
    showLoader: boolean;
    errors: string[];
    events: IWsMessage[];
}

export interface IFormError {
    non_field_errors: string[]
}

export enum EPanelType {
    NONE, // NON USARE MAI
    /*indica quando siamo nel modal dove si vedono i topici e se ne puo' creare uno nuovo*/
    SHOW_TOPICS,
    /* indica quando siamo nel modal per la creazione di un nuovo topico*/
    TOPIC,
    /* indica lo stato del modal quando stiamo visualizzando una chat e vogliamo rispondere  ad un messaggio*/
    MESSAGE,
    /* indica quando siamo nel modal per la fase di accept di un nuovo task*/
    ACCEPT,
    /* indica quando siamo nel modal per la creazione di un subtask*/
    SUBTASK,
    OUT,
}

export enum EAttachmentType {
    NONE,
    media,
    file,
    link
}


export interface ITaskDict {
    [key: number]: Task;
}

export interface IDashboardDict {
    [key: number]: Dashboard;
}

export interface ITopicDict {
    [key: number]: Topic;
}

export interface IAttachmentDict {
    [key: number]: Attachment;
}

export interface ITaskTopicsDict {
    [key: number]: number[];
}

export interface ITaskAttachmentDict {
    [key: number]: number[];
}

export interface ITaskExpandedDict {
    [key: number]: boolean;
}

export interface ISignUpError extends IFormError {
    email: string[]
    password1: string[]
    password2: string[]
    username: string[]
}

export interface ISignInError extends IFormError {
    email: string[]
    password: string[]
}

export interface IDashboardError {
    title: string[]
}

export interface ISignInError extends IFormError {
    email: string[]
    password: string[]
}

export interface INewPublicTaskError extends IFormError {
    content: string[];
    due_date: string[];
    owner: string[];
    recipient: string[];
    title: string[];
    attachment: string[];
}

export interface INewTaskError extends IFormError {
    due_date: string[];
    recipient: string[];
    title: string[];
}


export class SignInFormFieldError implements ISignInError {
    email: string[];
    password: string[];
    non_field_errors: string[];

    constructor();
    constructor(data: ISignUpError);
    constructor(data?: any) {
        this.email = data && data.email || [];
        this.password = data && data.password || [];
        this.non_field_errors = data && data.non_field_errors || [];
    }
}

export class SignUpFormFieldError implements ISignUpError {
    email: string[];
    password1: string[];
    password2: string[];
    username: string[];
    non_field_errors: string[];

    constructor();
    constructor(data: ISignUpError);
    constructor(data?: any) {
        this.email = data && data.email || [];
        this.password1 = data && data.password1 || [];
        this.password2 = data && data.password2 || [];
        this.username = data && data.username || [];
        this.non_field_errors = data && data.non_field_errors || [];
    }
}

export class NewPublicTaskFormFieldError implements INewPublicTaskError {
    content: string[];
    due_date: string[];
    owner: string[];
    recipient: string[];
    title: string[];
    attachment: string[];
    non_field_errors: string[];

    constructor();
    constructor(data: INewPublicTaskError);
    constructor(data?: any) {
        this.content = data && data.content || [];
        this.due_date = data && data.due_date || [];
        this.owner = data && data.owner || [];
        this.recipient = data && data.recipient || [];
        this.title = data && data.title || [];
        this.attachment = data && data.attachment || [];
        this.non_field_errors = data && data.non_field_errors || [];
    }
}

export class NewTaskFormFieldError implements INewTaskError {
    due_date: string[];
    recipient: string[];
    title: string[];
    non_field_errors: string[];

    constructor();
    constructor(data: INewTaskError);
    constructor(data?: any) {
        this.due_date = data && data.due_date || [];
        this.recipient = data && data.recipient || [];
        this.title = data && data.title || [];
        this.non_field_errors = data && data.non_field_errors || [];
    }

    public reset() {
        this.due_date = [];
        this.recipient = [];
        this.title = [];
        this.non_field_errors = [];
    }
}

export class DashboardFormFieldError implements IDashboardError {
    title: string[];

    constructor();
    constructor(data: IDashboardError);
    constructor(data?: any) {
        this.title = data && data.title || [];
    }

    public reset() {
        this.title = [];
    }
}

export interface ITableSortConfig {
    order: number
    direction: boolean
    sort_field: string
}

export interface ITableConfig {
    name: string,
    width: number,
    sortable: boolean,
    sort: ITableSortConfig,
    title?: string,
    field?: string,
}

export interface ITableResize {
    resize_drag: boolean
    start_drag_evt: MouseEvent
    drag_col: Node
    perc_on_px: number
    start_conf_width: number[]
    col_idx: number
}
