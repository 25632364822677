














































import {Component, Prop, Vue} from 'vue-property-decorator';
import {TaskStatistics} from '@/datastore/entities/Statistics';
import {MenuRoutes} from '@/routes.ts';

@Component({
    components: {},
    name: "Box"
})
export default class Box extends Vue {
    @Prop(String) private readonly title!: string;
    @Prop(String) private readonly tag!: string;
    @Prop(Object) private readonly to!: object;
    @Prop(Boolean) private readonly small!: boolean;
    @Prop(Object) private readonly data!: TaskStatistics;

    private is_small: boolean = this.small || false;
    private is_selected: boolean = true;
    private routes = MenuRoutes;

}
