

















































































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import {EventBus} from '@/EventBus'
import MessageTab from "@/components/SidePanel/MessageTab.vue";
import MediaTab from "@/components/SidePanel/MediaTab.vue";
import TaskTabHeader from "@/components/SidePanel/TaskTabHeader.vue";
import TabContent from "@/components/SidePanel/TabContent.vue";
import TaskTab from "@/components/SidePanel/TaskTab.vue";
import PanelMixin from "@/components/Mixin/PanelMixin.vue";
import {taskManager} from "@/datastore/managers/TaskManager";
import {EPanelType} from "@/store/types";
import PermissionMixin from "@/components/Mixin/PermissionMixin.vue";
import {IPanelMixin} from "@/components/Mixin/Mixin";
import {ETaskStatus} from "@/datastore/schemas/TaskSchema";

@Component({
    components: {
        TaskTabHeader,
        TabContent,
        TaskTab,
        MessageTab,
        MediaTab
    },
    name: "Panel"
})

export default class Panel extends Mixins<IPanelMixin>(PanelMixin, PermissionMixin) {
    @Prop({type: Boolean, default: true}) private readonly closable!: boolean;

    private show_panel: boolean = false;
    private eTaskStatus = ETaskStatus;

    public handleAccept() {
        const ret = this.processAccept();
        if (ret) {
            ret.then((e) => {
                this.panelType = EPanelType.SHOW_TOPICS;
            }).catch((e) => { /* errore durante l'accettazione del messaggio*/
            });
        }
    }

    private processAccept() {
        if (this.activeTask.isPending) {
            return taskManager.accept(this.activeTask.id)
        } else if (this.activeTask.hasPendingTransfer) {
            return taskManager.transfer_approve(this.activeTask.pending_transfer.id)
        }
    }

    private beforeDestroy(){
        EventBus.$off('openPanel');
        EventBus.$off('showPanel');
        EventBus.$off('hidePanel');
        EventBus.$off('togglePanel');
    }

    private created() {
        EventBus.$on('openPanel', this.openPanel);
        EventBus.$on('showPanel', () => {
            //console.log('showPanel');
            this.show();
        });

        EventBus.$on('hidePanel', () => {
            //console.log('hidePanel');
            this.hide();
        });

        EventBus.$on('togglePanel', () => {
            //console.log('togglePanel');
            this.toggle();
        });

        EventBus.$on('BackOfficeClick', (event: MouseEvent) => {
            if(this.show_panel && event.composedPath().indexOf(this.$el) === -1){
                this.hide();
            }
        });

        this.$store.watch(() => this.$store.getters['modal/activeTask'], ()=>{
            this.refreshTab();
        });

        this.$store.watch(() => this.$store.getters['modal/panelTab'], ()=>{
            this.refreshTab();
        });

    }

    private openPanel(id: number) {
        this.$store.dispatch('modal/setActiveTaskById', {taskid: id}).then(() => {
            const task = this.$store.getters['modal/activeTask'];
            if (task.isPending) {
                /*se il task è pendente e sono il recipient posso solamente accettarlo*/
                if (task.recipient.id === this.$store.getters['account/user'].id) {
                    return this.$store.dispatch('modal/panelType', {type: EPanelType.ACCEPT});
                }
                /*se il task è pendente e sono l'owner non posso fare niente se non vedere l'unico topico*/
                /*se non sono ne' owner ne' recipient allora vedo solo i topici*/
                else {
                    return this.$store.dispatch('modal/panelType', {type: EPanelType.OUT});
                }
            } else if (task.hasPendingTransfer && task.pending_transfer.new_recipient.id === this.$store.getters['account/user'].id) {
                return this.$store.dispatch('modal/panelType', {type: EPanelType.ACCEPT});
            } else {
                /*se il task non e' pendente e sono il recipient allora posso fare topici e subtaskare*/
                /*se il task non e' pendente e sono l'owner allora posso fare topici*/
                /*se il task non e' pendente e sono nei cc allora posso fare topici*/
                if (task.recipient.id === this.$store.getters['account/user'].id ||
                    task.owner.id === this.$store.getters['account/user'].id ||
                    task.cc.some((cc: any) => {
                        return cc.id == this.$store.getters['account/user'].id;
                    })
                ) {
                    return this.$store.dispatch('modal/panelType', {type: EPanelType.SHOW_TOPICS});
                }
                /*se non sono ne' recipient ne' owner non faccio vedere nulla*/
                else {
                    return this.$store.dispatch('modal/panelType', {type: EPanelType.OUT});
                }
            }
        }).then(() => {
            this.$store.dispatch('attachment/fetchAttachmentsByTaskId', {id: this.activeTask.id});
            this.show();
        });
    }

    private toggle() {
        if(this.show_panel){
            this.hide();
        } else {
            this.show();
        }
    }

    private hide() {
        this.show_panel = false;
        this.$store.dispatch('modal/resetActiveTask');
    }

    private show() {
        this.show_panel = true;
        EventBus.$emit('panelShowed');
    }

    private refreshTab(){
        if (this.panelTab==1){
            this.$store.dispatch('modal/resetActiveTopic')
             this.$store.dispatch('topics/fetchTopicsByTaskId', {id: this.activeTask.id}).then(()=>{
                this.$store.dispatch('task/fetchToReadCount', {id: this.activeTask.id});
            });
        } else if (this.panelTab==2){
            this.$store.dispatch('attachment/fetchAttachmentsByTaskId', {id: this.activeTask.id});
        } else if (this.panelTab==0){
            this.$store.dispatch('modal/fetchActiveTaskLogs', {id: this.activeTask.id});
        }
    }

}
