















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';


@Component({
    components: {},
    name: "ModalHeader"
})
export default class ModalHeader extends Vue {

    @Prop(Boolean) private readonly disable!: boolean;

    public close() {
        this.$emit('onClose');
    }
}
