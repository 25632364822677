import {
    IGenericTopicData,
    IGenericMessageData,
    IGenericTaskData, IGenericTransferData,
    IWsMessage,
    WSMessagesType, IGenericAttachmentsData
} from '@/websocket/messagesType';

import store from '@/store/index';
import {ETaskType} from '@/datastore/schemas/TaskSchema';
import axios from 'axios';
import {EPanelType} from '@/store/types';


class WSworker {

    private runningFetch: { [id: string]: any; };
    private fetchTimers: { [id: string]: any; };

    constructor() {
        this.runningFetch = {};
        this.fetchTimers = {};
    }

    public dispatch(data: IWsMessage) {

        switch (data.type) {
            case WSMessagesType.taskTopicCreated:
                this.handleTaskTopicCreation(data.data as IGenericTopicData);
                break;
            case WSMessagesType.taskCreated:
            case WSMessagesType.taskUpdated:
                this.handleTaskUpdating(data.data as IGenericTaskData);
                break;
            case WSMessagesType.taskDeleted:
                this.handleTaskCancellation(data.data as IGenericTaskData);
                break;
            case WSMessagesType.taskMessageCreated:
                this.handleTaskMessageCreation(data.data as IGenericMessageData);
                break;
            case WSMessagesType.transferCreated:
                this.handleTaskUpdating(data.data as IGenericTransferData);
                break;
            case WSMessagesType.transferUpdated:
                this.handleTaskUpdating(data.data as IGenericTransferData);
                break;
            case WSMessagesType.transferDeleted:
                this.handleTaskUpdating(data.data as IGenericTransferData);
                break;
            case WSMessagesType.newCcTask:
                this.handleNewCcTask(data.data as IGenericTaskData);
                break;
            case WSMessagesType.deleteCcTask:
                // todo
                break;
            case WSMessagesType.newAttachment:
                // todo
                break;
            case WSMessagesType.deleteAttachment:
                this.handleDeleteAttachment(data.data as IGenericAttachmentsData);
                break;
        }
    }

    private addTask(t_id: number, where: ETaskType) {
        switch (where) {
            case ETaskType.in:
                store.dispatch('task/removeOut', {id: t_id});
                store.dispatch('task/removeMy', {id: t_id});
                store.dispatch('task/addIn', {id: t_id});
                break;
            case ETaskType.my:
                store.dispatch('task/removeOut', {id: t_id});
                store.dispatch('task/removeIn', {id: t_id});
                store.dispatch('task/removeArchived', {id: t_id});
                store.dispatch('task/addMy', {id: t_id});
                break;
            case ETaskType.out:
                store.dispatch('task/removeIn', {id: t_id});
                store.dispatch('task/removeMy', {id: t_id});
                store.dispatch('task/addOut', {id: t_id});
                break;
            case ETaskType.cc:
                store.dispatch('task/addCc', {id: t_id});
                break;
            case ETaskType.archived:
                store.dispatch('task/removeMy', {id: t_id});
                store.dispatch('task/addArchived', {id: t_id});
                break;
            case ETaskType.rejected:
                store.dispatch('task/deleteById', {id: t_id});
                break;
            case ETaskType.unknown:
                store.dispatch('task/removeIn', {id: t_id});
                store.dispatch('task/removeMy', {id: t_id});
                store.dispatch('task/removeOut', {id: t_id});
                break;

        }
    }

    /*    private handleTaskCreation(data: IGenericTaskData) {
            store.dispatch('task/fetchById', {id: data.task_id}).then(() => {
                if (data.task_parent_id && store.getters['task/taskById'](data.task_parent_id)) {
                    store.dispatch('task/addChildToTask', {parent_id: data.task_parent_id, child_id: data.task_id});
                }
                this.addTask(data.task_id, data.type);
                store.dispatch('account/fetchStatistics');
            }).catch((e) => {
                // console.log('unable to dispatch');
            });
        }*/

    private handleNewCcTask(data: IGenericTaskData) {
        store.dispatch('account/newCcTasks');
    }

    private handleTaskTransferCreation(data: IGenericTransferData){

    }

    private handleTaskCancellation(data: IGenericTaskData) {
        store.dispatch('task/deleteById', {id: data.task_id});
        store.dispatch('account/fetchStatistics');
        if (store.getters['modal/activeTask'] && data.task_id === store.getters['modal/activeTask'].id) {
            store.dispatch('modal/resetActiveTask');
        }
    }

    private handleTaskMessageCreation(data: IGenericMessageData) {

        //Se il panel e' aperto sul tab messaggi li rifeccio
        if (store.getters['modal/panelType'] === EPanelType.MESSAGE &&
            store.getters['modal/panelTab'] === 1) {
            store.dispatch('modal/pushMessage', {topicId: data.topic_id, messageId: data.message_id}).then(() => {
                store.dispatch('task/fetchToReadCount', {id: data.task_id});
                store.dispatch('topics/fetchToReadCount', {id: data.topic_id});
            });

        } else {
            store.dispatch('task/fetchToReadCount', {id: data.task_id});
            store.dispatch('topics/fetchToReadCount', {id: data.topic_id});
        }

    }

    /**
     * qui devo rifetchare i topici di un task ed andare ad aggiornare il contatore dei messaggi del task
     * poi siccome il contatore lo vedo nel modal forzo il rerender del modal
     */
    private handleTaskTopicCreation(data: IGenericTopicData) {

        //Se il panel e' aperto sul tab messaggi li rifeccio
        if ((store.getters['modal/panelType'] === EPanelType.TOPIC ||
            store.getters['modal/panelType'] === EPanelType.SHOW_TOPICS) &&
            store.getters['modal/panelTab'] === 1) {
            return store.dispatch('topics/fetchTopicsByTaskId', {id: data.task_id}).then(() => {
                store.dispatch('task/fetchToReadCount', {id: data.task_id});
            });
        } else {
            store.dispatch('task/fetchToReadCount', {id: data.task_id});
        }

    }

    private handleTaskUpdating(data: IGenericTaskData) {

        if (this.fetchTimers[data.task_id]) {
            window.clearTimeout(this.fetchTimers[data.task_id]);
            this.fetchTimers[data.task_id] = null;
        }

        this.fetchTimers[data.task_id] = window.setTimeout(() => {
            if (this.runningFetch[data.task_id]) {
                this.runningFetch[data.task_id].cancel();
            }

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            this.runningFetch[data.task_id] = source;

            store.dispatch('task/fetchById', {id: data.task_id, token: source.token}).then(() => {
                if (data.task_parent_id && store.getters['task/taskById'](data.task_parent_id)) {
                    store.dispatch('task/addChildToTask', {parent_id: data.task_parent_id, child_id: data.task_id});
                }
                this.addTask(data.task_id, data.type);
                store.dispatch('account/fetchStatistics');
            }).catch((e) => {

                if(e.response && e.response.status === 404){
                    store.dispatch('task/deleteById', {id: data.task_id});
                }

            }).finally(() => {
                delete this.runningFetch[data.task_id];
            });
        }, 150);
    }

    private handleDeleteAttachment(data: IGenericAttachmentsData){
        if(data.message_id){
            store.dispatch('modal/removeMessageAttachment', {m_id: data.message_id, a_id:data.attachment_id})
        }
        store.dispatch('attachment/removeAttachment',{id: data.attachment_id})
    }
}

export default new WSworker();
