

















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {MenuRoutes} from '@/routes.ts';
import {EventBus} from '@/EventBus';
import ChangeStatusModal from '@/components/Modal/ChangeStatusModal.vue';
import wsworker from '@/websocket/WSworker';
import DeleteDashboardModal from '@/components/Modal/DeleteDashboardModal.vue';
import DeleteTaskModal from '@/components/Modal/DeleteTaskModal.vue';
import ArchiveTaskModal from '@/components/Modal/ArchiveTaskModal.vue';
import Panel from '@/components/SidePanel/Panel.vue';
import DashboardModal from '@/components/Modal/DashboardModal.vue';
import TaskModal from '@/components/Modal/TaskModal.vue';
import SubTaskModal from '@/components/Modal/SubTaskModal.vue';
import Debugger from '@/components/Development/Debugger.vue';
import TransferModal from '@/components/Modal/TransferModal.vue';
import {User} from '@/datastore/entities/User';
import {IWsMessage, WSMessagesType} from '@/websocket/messagesType';
import store from '@/store';
import AskSupportModal from '@/components/Modal/AskSupportModal.vue';
import DeleteAttachmentModal from "@/components/Modal/DeleteAttachmentModal.vue";
import TransferDeleteModal from "@/components/Modal/TransferDeleteModal.vue";

@Component({
    components: {
        TransferModal,
        DashboardModal,
        Panel,
        TaskModal,
        AskSupportModal,
        ChangeStatusModal,
        DeleteDashboardModal,
        DeleteTaskModal,
        ArchiveTaskModal,
        SubTaskModal,
        DeleteAttachmentModal,
        TransferDeleteModal,
        Debugger
    },
    computed: {
        ...mapGetters({user: 'account/user'}),
    },
    name: 'Menu'
})
export default class Menu extends Vue {

    private show_panel: boolean = true;
    private mask: number = 1;
    private MenuRoutes = MenuRoutes;
    //le rimetto in un array perche' devono essere ordinati
    private menuItems: object[] = [
        MenuRoutes.dashboard,
        MenuRoutes.in,
        MenuRoutes.my,
        MenuRoutes.out,
        MenuRoutes.cc,
        // MenuRoutes.archived,
        MenuRoutes.messages,
        MenuRoutes.activities,
    ];
    user?: User;

    private deleteSavedSettings() {
        this.$store.dispatch('account/deleteUserSettings');
    }

    get starred() {
        return this.$store.getters['dashboard/starred'];
    }

    get toReadMessages() {
        //console.log(this.$store.getters['account/messageToRead'])
        return this.$store.getters['account/messageToRead'];
    }

    public mounted() {
        EventBus.$on('new-socket-message', (data: string) => {
            let message: IWsMessage = JSON.parse(data);
            if (this.user
                && message.user_email !== this.user.email
            ) {
                if (this.$store.getters['modal/activeTaskId'] !== message.data.task_id) {
                    this.$store.dispatch('addEvent', message);
                }
                if (message.type === WSMessagesType.taskTopicCreated || message.type === WSMessagesType.taskMessageCreated) {
                    this.$store.dispatch('account/fetchMessagesToRead');
                }
            }
            wsworker.dispatch(message);
        });

        this.$store.dispatch('account/fetchMessagesToRead');
    }

    public newTask() {
        // this.$store.dispatch('modal/setType', {type: modalTypeEnum.NEWTASK});
        this.$bvModal.show('Task');
    }

    public askSupport() {
        this.$bvModal.show('AskSupport');
    }


    public selectDashboard(dashboard_id: string) {
        this.$store.dispatch('dashboard/setFilterDashboardId', {id: dashboard_id});
        //il cambio di filtro della dashboard deve andare in my, questo cambio di rotta fa scattare il refetch dei task
        //ma se sono gia' nella pagina devo forzare il refetch
        if (this.$route.fullPath !== MenuRoutes.my.to.fullPath) {
            this.$router.push(MenuRoutes.my.to);
        } else {
            EventBus.$emit('dashboardChanged')
        }
    }

    @Watch('$route')
    public onUrlChange(newVal: any) {
        switch (newVal.name) {
            case 'dashboard':
                this.mask = 1;
                break;
            case 'details':
                switch (newVal.params.type) {
                    case 'in':
                        this.mask = 1 << 1;
                        break;
                    case 'my':
                        this.mask = 1 << 2;
                        break;
                    case 'out':
                        this.mask = 1 << 3;
                        break;
                }
                break;
            case 'cc':
                this.mask = 1 << 4;
                break;
            // case 'archived':
            //     this.mask = 1 << 5;
            //     break;
            case 'messages':
                this.mask = 1 << 5;
                break;
            case 'activities':
                this.mask = 1 << 6;
                break;
            default:
                this.mask = 1;
        }
    }
}
