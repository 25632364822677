




















































































import {Component, Vue} from 'vue-property-decorator';
import PerfectScrollbar from "perfect-scrollbar";
import {mapGetters} from 'vuex';
import Switches from 'vue-switches';
import {User} from "@/datastore/entities/User";

@Component({
    components: {
        Switches,
    },
    computed: {
        ...mapGetters({
            user: 'account/user',
            settings: 'account/settings',
        })
    },
    name: "Settings"
})
export default class Settings extends Vue {
    private ps!: PerfectScrollbar;

    private user!: User;

    private username_edit: boolean = false;
    private usrnm: string = '';
    private username_error: string = '';

    get username() {
        return this.usrnm;
    }

    set username(val: string) {
        this.usrnm = val;
        this.username_error = ''
    }

    private updateUsername() {
        this.username_edit = false;
        this.updateSettings('username', this.usrnm).then(() => {
            this.username_edit = false;
        }).catch((e)=>{
            if(e && e.username && e.username.length){
                this.username_error = e.username[0];
            }
            this.username_edit = true;
        })
    }

    private cancelUserEdit(){
        this.username_edit = false;
        this.username_error = '';
        this.usrnm = this.user.username;
    }

    get settings() {
        return this.$store.getters['account/settings'];
    }

    get notify_new_message() {
        return this.settings.notify_new_message;
    }

    set notify_new_message(val) {
        this.updateSettings('notify_new_message', val);
    }

    get notify_new_topic() {
        return this.settings.notify_new_topic;
    }

    set notify_new_topic(val) {
        this.updateSettings('notify_new_topic', val);
    }

    get notify_new_task() {
        return this.settings.notify_new_task;
    }

    set notify_new_task(val) {
        this.updateSettings('notify_new_task', val);
    }

    get notify_task_status_updated() {
        return this.settings.notify_task_status_updated;
    }

    set notify_task_status_updated(val) {
        this.updateSettings('notify_task_status_updated', val);
    }

    get notify_task_pending_status_updated() {
        return this.settings.notify_task_pending_status_updated;
    }

    set notify_task_pending_status_updated(val) {
        this.updateSettings('notify_task_pending_status_updated', val);
    }

    private enableUsernameEdit() {
        this.username_edit = true;
    }

    private createOrUpdateScroll() {
        if (!this.ps) {
            this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
                suppressScrollX: true
            });
        } else {
            this.ps.update();
        }
    }

    private mounted() {
        this.createOrUpdateScroll();
        this.usrnm = this.user.username;
    }

    private updated() {
        this.createOrUpdateScroll();
        if (this.username_edit) {
            (this.$refs.username as HTMLElement).focus()
        }
    }

    private updateSettings(prop: string, val: boolean | string) {
        const settings: any = {};
        settings[prop] = val;
        return this.$store.dispatch('account/updateUserSettings', {
            settings
        })
    }

    private handleFileUpload() {
        const fl = (this.$refs.file as HTMLInputElement).files as FileList;
        if(fl.length){
            let formData: FormData;
            formData = new FormData();
            formData.append('avatar', fl[0]);
            return this.$store.dispatch('account/updateUserAvatar', {
                avatar:formData
            })
        }
    }

    public openFileManager() {
        (this.$refs.file as HTMLInputElement).click();
    }

    public beforeDestroy() {
        this.ps.destroy();
    }
}
