import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {IAttachmentSchema} from "@/datastore/schemas/AttachmentSchema";


export interface IAttachmentsManager {
    attachment: (formData: FormData) => Promise<IAttachmentSchema>;
    link_attachment: (name: string, url: string, description: string) => Promise<IAttachmentSchema>;
    task_attachments: (id: number) => Promise<IAttachmentSchema[]>;
    delete_attachment: (t_id:number, id: number) => Promise<IAttachmentSchema[]>;
}

export class AttachmentsManager implements IAttachmentsManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public attachment(formData: FormData): Promise<IAttachmentSchema> {
        return this._httpClient.post<IAttachmentSchema>('attachments/',
            formData
            ,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    public link_attachment(url: string, description: string): Promise<IAttachmentSchema> {
        return this._httpClient.post<IAttachmentSchema>('attachments/', {
            url, description
        });
    }

    public task_attachments(id: number): Promise<IAttachmentSchema[]> {
        return this._httpClient.get<IAttachmentSchema[]>('task_attachments/' + id + '/');
    }
    public delete_attachment(t_id:number, id: number): Promise<IAttachmentSchema[]> {
        return this._httpClient.delete<IAttachmentSchema[]>('/task_attachments/'+ t_id +'/'+ id + '/');
    }
}

export const attachmentsManager: AttachmentsManager = new AttachmentsManager(httpClient);
