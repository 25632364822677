import {User} from '@/datastore/entities/User';
import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {IUserSchema} from '@/datastore/schemas/UserSchema';
import {IUsersSettingsRequest, IUsersSettingsSchema} from "@/datastore/schemas/UsersSettingsSchema";

export interface IUserManager {
    users: (search?: string, page?: number) => Promise<User[]>;
    update_users_settings: (id: number, settings: IUsersSettingsRequest) => Promise<IUsersSettingsSchema>;
    delete_users_settings: (id: number) => Promise<IUsersSettingsSchema>;
    reset_new_cc_tasks: (id: number) => Promise<any>;
    reset_new_activities: (id: number) => Promise<any>;
}

interface IPagination {
    search?: string;
    page?: number;
}

export class UsersManager implements IUserManager {

    constructor(private _httpClient: IHttpClient) {
    }

    public update_avatar(id:number,formData: FormData): Promise<any> {
        return this._httpClient.patch<any>('users/' + id+ '/',
            formData
           );
    }

    public async users(search?: string, page?: number): Promise<User[]> {
        const params: IPagination = {
            search: '',
            page: 1,
        };

        if (typeof search == 'string') {
            params.search = search;
        }
        if (page != undefined) {
            params.page = page;
        }
        const res = await this._httpClient.get<IUserSchema[] | any>('users/', {params});

        // TODO cambiare per discorso paginazione
        if (page && res.result) {
            res.results = res.results.map((u: IUserSchema) => {
                return new User(u);
            });
            return res;
        } else {
            return res.map((u: IUserSchema) => {
                return new User(u);
            });
        }
    }

    public update_users_settings(id: number, settings: IUsersSettingsRequest): Promise<IUsersSettingsSchema> {
        return this._httpClient.patch<IUsersSettingsSchema>('users/' + id + '/', settings)
    }

    public delete_users_settings(id: number): Promise<IUsersSettingsSchema> {
        return this._httpClient.patch<IUsersSettingsSchema>('users/' + id + '/', {frontend_settings:""})
    }

    public reset_new_cc_tasks(id: number): Promise<IUsersSettingsSchema> {
        return this._httpClient.patch<IUsersSettingsSchema>('users/' + id + '/', {new_cc_tasks: false})
    }

    public reset_new_activities(id: number): Promise<IUsersSettingsSchema> {
        return this._httpClient.patch<IUsersSettingsSchema>('users/' + id + '/', {new_activities: false})
    }
}

export const usersManager: IUserManager = new UsersManager(httpClient);
