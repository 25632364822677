





































































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

import {taskManager} from '@/datastore/managers/TaskManager';
import {IPanelMixin} from '@/components/Mixin/Mixin';
import PanelMixin from '@/components/Mixin/PanelMixin.vue';
import PermissionMixin from '@/components/Mixin/PermissionMixin.vue';
import UserSelect from '@/components/UserSelect.vue';
import {MultiselectUserSchema} from '@/datastore/schemas/UserSchema';
import {User} from '@/datastore/entities/User';
import {MenuRoutes} from '@/routes';
import {Task} from '@/datastore/entities/Task';
import {ETaskType} from '@/datastore/schemas/TaskSchema';

@Component({
    components: {UserSelect},
    computed: {
        ...mapGetters({
            activeTask: 'modal/activeTask',
        })
    },
    name: "CommonTabContent",
})
export default class CommonTabContent extends Mixins<IPanelMixin>(PanelMixin, PermissionMixin) {

    private ccEdit: boolean = false;
    private titleEdit: boolean = false;
    private dueDateEdit: boolean = false;
    private selected_cc: MultiselectUserSchema[] = [];
    private ttl: string = '';
    private dd: string = '';

    get selectedCC() {
        if (this.ccEdit) {
            return this.selected_cc;
        }
        return this.selected_cc = this.activeTask.cc.map((u: User) => {
            return u.MultiselectItem;
        });
    }

    set selectedCC(val: MultiselectUserSchema[]) {
        this.selected_cc = val;
    }

    get title() {
        if (this.titleEdit) {
            return this.ttl;
        }
        return this.ttl = this.activeTask.title;
    }

    set title(val) {
        this.ttl = val;
    }

    updated() {
        if (this.ccEdit) {
            (this.$refs.cc as UserSelect).focus();
        }
        if (this.$refs.dueDateEdit) {
            (this.$refs.dueDate as HTMLElement).focus();
        }
        if (this.titleEdit) {
            (this.$refs.title as HTMLElement).focus();
        }
    }

    @Watch('activeTask')
    change() {
        this.ccEdit = false;
        this.titleEdit = false;
    }

    private ccEditSave() {
        taskManager.update_task(this.activeTask.id, {
            cc: this.selected_cc.map(e => {
                return e.email;
            })
        }).then((e) => {
            this.ccEdit = false;
        });
    }

    private titleEditSave() {
        taskManager.update_task(this.activeTask.id, {
            title: this.ttl
        }).then((e) => {
            this.titleEdit = false;
        });
    }

    private dueDateEditSave() {
        taskManager.update_task(this.activeTask.id, {
            due_date: new Date(this.dd)
        }).then((e) => {
            this.dueDateEdit = false;
        });
    }

    private filterTask() {
        if (this.$router.currentRoute.name != 'details') {
            this.$store.dispatch('dashboard/resetFilterDashboardId').then(() => {
                this.$store.dispatch('dashboard/setFilterString', {filter: this.activeTask.code}).then(() => {
                    switch ((this.activeTask as Task).imo_type) {
                        case ETaskType.in:
                            this.$router.push(MenuRoutes.in.to);
                            break;
                        case ETaskType.out:
                            this.$router.push(MenuRoutes.out.to);
                            break;
                        default:
                            this.$router.push(MenuRoutes.my.to);
                            break;
                    }

                });
            });
        }

    }

}
