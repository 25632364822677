






























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
    name: "UploadElement"
})
export default class UploadElement extends Vue {
    @Prop(Boolean) private isDownload!: boolean;
    @Prop(String) private readonly name!: string;
    @Prop(Boolean) private readonly success!: boolean;
    @Prop(Boolean) private readonly deleted!: boolean;
    @Prop(String) private readonly error!: string;
    @Prop(String) private readonly url!: string;
    @Prop(Number) private readonly index!: number;

    private a_url: any = '';

    private downlaod() {
        (this.$refs.a as HTMLLinkElement).click()
    }

    private mounted() {
        if (this.isDownload) {

            fetch(this.url)
                .then(resp => resp.blob())
                .then(blob => {
                    this.a_url = window.URL.createObjectURL(blob);
                })
        }
    }

    get bgClass() {
        if (this.isDownload) return '';
        switch (this.success) {
            case true:
                return 'success';
            case false:
                return 'fail';
        }
    }
}
