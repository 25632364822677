











































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {taskManager} from "@/datastore/managers/TaskManager";
import EditorFileUpload from "@/components/EditorFileUpload.vue";
import {INewPublicTaskError, NewPublicTaskFormFieldError} from "@/store/types";

@Component({
    components: {EditorFileUpload},
    name: "NewPublicTaskForm"
})
export default class NewPublicTaskForm extends Vue {
    private owner: string = '';
    private recipient: string = '';
    private title: string = '';
    private date: Date = new Date(0);
    private content: string = '';
    private uploadComplete: boolean = true;
    private disable: boolean = false;
    private attachments: number[] = [];
    private error: NewPublicTaskFormFieldError = new NewPublicTaskFormFieldError();

    private showForm: boolean = true;

    private openFileManager() {
        (this.$refs.fu as EditorFileUpload).openFileManager()
    }

    private fileUploadAddHandler(upls: Promise<any>[]) {
        this.uploadComplete = false;
        Promise.all(upls).then((e) => {
            this.attachments = e.filter((f) => {
                return f;
            });
            this.uploadComplete = true;
        })
    }

    get due_date() {
        if(this.date === null || this.date < new Date()){
            return null
        }
        return this.date.toISOString();
    }

    private send() {
        this.disable = true;
        taskManager.new_public_task(this.owner, this.recipient, this.title, this.content, this.attachments, this.due_date).then(() => {
            this.showForm = false;
            this.owner = '';
            this.recipient = '';
            this.title = '';
            this.content = '';
            this.attachments = [];
            this.date = new Date(0);
        }).catch((e: INewPublicTaskError) => {
            this.error = new NewPublicTaskFormFieldError(e);
        }).finally(() => {
            this.disable = false;
        })
    }
}
