import {User} from '@/datastore/entities/User';
import {Attachment} from '@/datastore/entities/Attachment';
import {ETaskStatus, ETaskType, ITaskNS, ITaskSchema} from '@/datastore/schemas/TaskSchema';
import {Tag} from '@/datastore/entities/Tag';
import {IAttachmentSchema} from '@/datastore/schemas/AttachmentSchema';
import {taskManager} from '@/datastore/managers/TaskManager';
import {taskTopicManager} from "@/datastore/managers/TaskTopicManager";
import {IUserSchema} from "@/datastore/schemas/UserSchema";
import {Transfer} from "@/datastore/entities/Transfer";
import {ETransferStatus} from "@/datastore/schemas/TransferSchema";

export class Task {

    public readonly attachments: Attachment[];
    public readonly children: number[];
    public readonly archived: boolean;
    public readonly archiviation_date: Date;
    public messages_count: number;
    public readonly content: string;
    public readonly created: Date;
    public readonly dueDate: Date | null;
    public readonly id: number;
    public readonly code: string;
    public readonly modified: Date;
    public readonly owner: User;
    public readonly parent: number | null;
    public readonly permission: number;
    public readonly recipient: User;
    public readonly status: ETaskStatus;
    public readonly tags: Tag[];
    public readonly title: string;
    public readonly cc: User[];
    public readonly imo_type: ETaskType;
    public messages_to_read: number;
    public expanded : boolean;
    public pending_transfer : Transfer;
    public can_be_completed : boolean;
    public recipient_labels: string[];
    public is_removed: boolean;


    constructor();
    constructor(data: ITaskSchema);
    constructor(data?: any) {
        this.expanded = false;
        this.id = data && data.id || -1;
        this.code = data && data.code || this.id;
        this.status = data && data.status || ETaskStatus.pending;
        this.title = data && data.title || '';
        this.owner = new User(data && data.owner);
        this.recipient = new User(data && data.recipient);
        this.children = data && data.children || [];
        this.content = data && data.content || '';
        this.dueDate = data && data.due_date ? new Date(data.due_date) : null;
        this.attachments = ((data && data.attachments || []) as IAttachmentSchema[]).map((ad) => {
            return new Attachment(ad);
        });
        this.cc = ((data && data.cc || []) as IUserSchema[]).map((cc) => {
            return new User(cc);
        });
        this.created = new Date(data && data.created || 0);
        this.modified = new Date(data && data.modified || 0);
        this.tags = ((data && data.tags || []) as ITaskSchema[]).map((t) => {
            return new Tag(t);
        });
        this.parent = data && data.parent || null;
        this.messages_count = data && data.messages_count || 0;
        this.messages_to_read = data && data.messages_to_read || 0;
        this.permission = data && data.permission || -1;
        this.archived = data && data.archived || false;
        this.archiviation_date = new Date( data && data.archiviation_date || 0);
        this.pending_transfer = new Transfer(data && data.pending_transfer);
        this.can_be_completed = data && data.can_be_completed || false;
        this.recipient_labels = data && data.recipient_labels || [];
        this.is_removed = data && data.is_removed || false;
        this.imo_type = data && data.imo_type || ETaskType.unknown;
    }

    public static postTopic(id: number, msg: string, attach: number[]) {
        return taskTopicManager.post_task_topic(id, msg, attach)
    }

    public subtask(data: ITaskNS) {
        return taskManager.sub_task(
            this.id,
            data.attachments,
            data.title,
            data.recipient,
            data.description,
            data.due_date,
            data.tags,
            data.cc
        );
    }
    get isRemoved(){
        return this.is_removed;
    }
    get isCompleted(){
        return this.status == ETaskStatus.completed;
    }
    get isApproved(){
        return this.status == ETaskStatus.approved;
    }
    get isPending(){
        return this.status == ETaskStatus.pending;
    }
    get isRejected(){
        return this.status == ETaskStatus.rejected;
    }

    public complete(message?: string) {
        return taskManager.complete(this.id, message)
    }

    public uncomplete(message?: string) {
        return taskManager.uncomplete(this.id, message)
    }

    public postTopic(msg: string, attach: number[]) {
        return taskTopicManager.post_task_topic(this.id, msg, attach)
    }

    public archive(){
        return taskManager.archive(this.id);
    }

    public unarchive(){
        return taskManager.unarchive(this.id);
    }

    get daysLeft() {
        if(this.dueDate){
            return Math.max(Math.ceil((this.dueDate.getTime() - new Date().getTime()) / 86400000 + 1), 0);
        }
        return -1
    }

    public toggleExpand(){
        this.expanded = !this.expanded
    }

    get hasPendingTransfer(){
        return this.pending_transfer && this.pending_transfer.status == ETransferStatus.pending
    }

    private addAttachments(attachments:number[]){
        const current = this.attachments.map((a)=>{
            return a.id
        })
        return taskManager.update_task(this.id,{
            attachments: [...current, ...attachments]
        })
    }

}
