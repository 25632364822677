import {IMessageSchema} from '@/datastore/schemas/MessageSchema';
import {Attachment} from '@/datastore/entities/Attachment';
import {IAttachmentSchema} from '@/datastore/schemas/AttachmentSchema';
import {User} from '@/datastore/entities/User';

export class Message {

    public readonly id: number;
    public readonly user: User;
    public readonly content: string;
    public readonly attachments: Attachment[];
    public readonly messageCount: number;
    public readonly created: Date;
    public readonly modified: Date;
    public is_read: boolean;

    constructor();
    constructor(data: IMessageSchema);
    constructor(data?: any) {
        this.id = data && data.id || 0;
        this.user = new User(data && data.owner);
        this.content = data && data.content || '';
        this.attachments = ((data && data.attachments || []) as IAttachmentSchema[]).map((ad) => {
            return new Attachment(ad);
        });
        this.messageCount = data && data.message_count || 0;
        this.created = new Date(data && data.created || 0);
        this.modified = new Date(data && data.modified || 0);
        this.is_read = data && data.is_read;
    }

    static messageTypeLabel(messageType: string) {
        switch (messageType) {
            case 'TaskMessage':
                return 'Message';
            case 'TaskTopic':
                return 'Topic';
            default:
                return '';
        }
    }
}


