





































import {Component, Vue} from 'vue-property-decorator';
import {ETaskStatus, ITaskSchema} from "@/datastore/schemas/TaskSchema";
import {EventBus} from "@/EventBus";

@Component({
    components: {},
    name:"ChangeStatusModal"
})
export default class ChangeStatusModal extends Vue {
    private change_msg: string = '';

    get active_modal_task() {
        return this.$store.getters['modal/activeTask'];
    }

    get postStatus(){
        switch (this.active_modal_task.status) {
            case ETaskStatus.completed:
                return 'uncompleted';
            case ETaskStatus.approved:
                return 'completed';
        }
    }

    public handleYes(){
        switch (this.active_modal_task.status) {
            case ETaskStatus.completed:
                this.active_modal_task.uncomplete(this.change_msg).then((e:ITaskSchema)=> {
                    EventBus.$emit('hidePanel');
                    this.change_msg = '';
                    //ci pensa la websocket ad aggiornare tutto
                }).catch(()=>{
                });
                break;
            case ETaskStatus.approved:
                this.active_modal_task.complete(this.change_msg).then((e:ITaskSchema)=> {
                    this.change_msg = '';
                    //ci pensa la websocket ad aggiornare tutto
                }).catch(()=>{
                });
                break;
        }
        this.$bvModal.hide('ChangeStatus');
    }

    public handleNo(){
        this.$bvModal.hide('ChangeStatus');
    }
}
