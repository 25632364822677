
























import {Component, Prop, Vue} from 'vue-property-decorator';
import {INewDashboardSchema} from "@/datastore/schemas/DashboardSchema";
import DashboardForm from "@/components/DashboardForm.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import {DashboardFormFieldError} from "@/store/types";
import {dashboardManager} from "@/datastore/managers/DashboardManager";
import {Dashboard} from "@/datastore/entities/Dashboard";

@Component({
    components: {DashboardForm, ModalHeader},
    name: "DashboardModal",
})
export default class DashboardModal extends Vue {
    private dashboardError: DashboardFormFieldError = new DashboardFormFieldError();

    public send(el: INewDashboardSchema) {
        const d_id = this.$store.getters['modal/activeDashboardId'];

        this.dashboardError.reset();
        const values = el as INewDashboardSchema;
        if (this.$store.getters['modal/activeDashboardId'] > -1) {
            dashboardManager.update_dashboard(
                d_id,
                values.title,
                values.users,
                values.tags,
                values.color,
            ).then((e) => {
                this.$store.dispatch('dashboard/updateDashboard', {d: new Dashboard(e)});
                this.close();
            }).catch((e) => { /* errore durante creazione dashboard*/
                this.dashboardError = new DashboardFormFieldError(e.response.data);
            });
        } else {
            dashboardManager.new_dashboard(
                values.title,
                values.users,
                values.tags,
                values.color,
            ).then((e) => {
                this.$store.dispatch('dashboard/updateDashboard', {d: new Dashboard(e)});
                this.close();
            }).catch((e) => { /* errore durante creazione dashboard*/
                this.dashboardError = new DashboardFormFieldError(e.response.data);
            });
        }
    }

    public close() {
        this.$bvModal.hide('Dashboard');
    }
}
