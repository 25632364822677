




































































import {Component, Vue, Mixins} from 'vue-property-decorator';
import {activityManager} from '@/datastore/managers/ActivityManager';
import {Activity as ActivityEntity} from '@/datastore/entities/Activity'
import PerfectScrollbar from "perfect-scrollbar";
import Grid from "@/components/Grid/Grid.vue";
import SimpleRow from "@/components/Grid/SimpleRow.vue";
import {EventBus} from "@/EventBus";
import Loading from 'vue-loading-overlay';
import EditorViewer from "@/components/EditorViewer.vue";
import ActivityMixin from "@/components/Mixin/ActivityMixin.vue";

@Component({
    components: {
        Grid,
        SimpleRow,
        EditorViewer,
        Loading
    },
    mixins: [ActivityMixin],
    name: "Activities"
})
export default class Activities extends Vue {

    private activities_list: ActivityEntity[] = [];
    private ps!: PerfectScrollbar;

    private currentPage: number = 1;
    private pageSize: number = 50;
    private totalRows: number = 0;

    private loading: boolean = false;
    private gridUpdateDebounce!: number;

    private sort:string[] = [];

    get header() {
        const h = this.$store.getters['account/activityGridSettings'];
        if (h && h.header) {
            return h.header
        }
        return [
            {
                name: "task__code",
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'task__code'},
                title: "ID",
                field: "task__code",
                width: 10
            },
            {
                name: "created",
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'created'},
                title: "Date",
                field: "created",
                width: 10
            },
            {
                name: "user",
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'user__email'},
                title: "User",
                field: "user",
                width: 10
            },
            {
                name: "activities",
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'event_type'},
                title: "Activities",
                field: "id",
                width: 10
            },
            {
                name: "message",
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'message'},
                title: "Message",
                field: "message",
                width: 10
            }
        ]
    }

    public openPanel(id: number) {
        EventBus.$emit('openPanel', id);
    }

    public mounted() {
        if (!this.ps) {
            this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
                suppressScrollX: true
            });
        } else {
            this.ps.update();
        }
        this.fetchActivity();
    }

    private isActiveTask(id: number) {
        return this.$store.getters['modal/activeTask'].id == id;
    }

    private updateSettingsHandler(header: any) {
        clearTimeout(this.gridUpdateDebounce);
        this.gridUpdateDebounce = setTimeout(() => {
            this.$store.dispatch('account/updateUserSettings', {
                settings: {
                    frontend_settings: {activity_grid: {header: header}}
                }
            })
        }, 500)
    }

    private orderChangeHandler(sort:string[]){
        this.sort = sort;
        this.fetchActivity();
    }

    private fetchActivity() {
        this.loading = true;
        const task_to_fetch: number[] = [];
        const pag = {page: this.currentPage, page_size: this.pageSize};
        activityManager.logs(pag, this.sort).then((a) => {
            this.totalRows = a.count;
            this.activities_list = a.results.map((act) => {
                task_to_fetch.push(act.task);
                return new ActivityEntity(act);
            })
        }).then(() => {
            this.$store.dispatch('task/lazyFetchByIds', {ids: task_to_fetch});
            this.$store.dispatch('account/resetNewActivities');
        }).catch(() => {
            //todo errore
        }).finally(() => {
            this.loading = false;
        })
    }

    public beforeDestroy() {
        this.ps && this.ps.destroy();
    }
}
