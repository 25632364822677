export const en: any = {
    menu:{
        home: "home",
        new:"new",
        in: "in",
        my: "my",
        out: "out",
        cc: "cc",
        archived: "archived",
        messages: " messages",
        activities: "activities",
        settings: "settings",
    },
    placeholder: {
        search: "Search",
        message: "Messasge",
        object: "Subject",
        tags: "Tags",
        name: "Name",
        user: "User",
        date: "Date",
        recipient: "Recipient",
        CC: "CC",
        my_email: "My e-mail",
        email: "E-mail",
        email_to: "E-mail to",
        activity_name: "Activity name",
        description: "Description",
        password: "Password",
        repeat_password: "Repeat password",
        username: "Username"
    },
    message: {
        back: "back",
        expand_all: "Expand all",
        collapse_all: "Collapse all",
        home: "home",
        new:"new",
        in: "in",
        my: "my",
        out: "out",
        cc: "CC",
        archived: "archived",
        archive:"archive",
        restore:"restore",
        message: "message | messages",
        activity: "activity | activities",
        new_task: "new task",
        new_attachment:"new attachment",
        favourite: "bookmarks",
        setting: "setting | settings",
        logout: "logout",
        username: "username",
        email: "email",
        avatar: "avatar",
        select_avatar: "Using a photo helps personalize your account",
        notification: "notification",
        new_topic: "new topic",
        new_message: "new message",
        task_status_change: "task status change",
        task_accepted: "task accepted",
        dashboard: "dashboard | dashboards",
        id: "id",
        object: "subject",
        from: "from",
        due_date: "due date",
        tag: "tag | tags",
        to: "to",
        no_task_found_msg: "no task found",
        no_new_message: "no new messages",
        description: "description",
        send: "send",
        submit: "submit",
        task: "task",
        media: "media",
        title: "title",
        delete: "delete",
        attachment: "attachment | attachments",
        no_attachments: "no attachments",
        accept: "accept",
        link: "link | links",
        doc: "doc | docs",
        expand: "expand",
        transfer: "transfer",
        delete_transfer: "delete transfer",
        expired: "expired",
        day: "day | days",
        reject:"reject",
        today: "today",
        mark_complete: "mark complete",
        mark_to_open: "mark to open",
        sub_task: "sub task",
        subtask: "subtask",
        yes: "yes",
        no: "no",
        new_dashboard: "new dashboard",
        remove: "remove",
        save: "send",
        feature: "feature | features",
        sign_in: "sign in",
        sign_up: "sign up",
        reset : "reset",
        confirm : "confirm",
        //////////////
        task_updated: "task updated",
        task_cant_be_completed: "task cannot be completed",
        task_can_be_completed: "task can be completed",
        use_social : "Log in with:",
        resend_confirmation_email : "Resend confirmation email",
        forgot_password : "Forgot password?",
        not_registered : "Sign Up!",
        show_completed: "Show completed tasks",
        hide_completed: "Hide completed tasks",
        success_signup : "Your subscription was successful. Please check your email for instructions.",
        success_pwd_reset : "Check your email. We sent you a link to reset your password",
        task_not_found : "Task not found! :(",
        go_home : "Dashboard",
        attention : "attention",
        ask_transfer : "ask to move the task to",
        ask_support : "Ask support"
    },
    multiselect: {
        new_user: "Press enter to create a user",
        new_tag: "Press enter to create a tag",
    },
}
