import {ITagSchema} from '@/datastore/schemas/TagSchema';
import {Tag} from '@/datastore/entities/Tag';
import {httpClient, IHttpClient} from '@/datastore/HttpClient';

export interface ITagManager {
    fetch_tags: (search?: string, page?: number, page_size?: number) => Promise<Tag[]>;
    new_tags: (title: string) => Promise<ITagSchema>;
}

interface IPagination {
    search?: string;
    page?: number;
    page_size?: number;
}

export class TagManager implements ITagManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public async fetch_tags(search?: string, page?: number, page_size?: number): Promise<Tag[]> {
        let params: object = {};
        if (typeof search == 'string') {
            params = {...params, ...{search}};
        }
        if (page != undefined) {
            params = {...params, ...{page}};
            if (page_size != undefined) {
                params = {...params, ...{page_size: 10}};
            }
        }
        const res = await this._httpClient.get<ITagSchema[] | any>('tags/', {params});

        // TODO cambiare per discorso paginazione
        if (page && res.result) {
            res.results = res.results.map((u: ITagSchema) => {
                return new Tag(u);
            });
            return res;
        } else {
            return res.map((u: ITagSchema) => {
                return new Tag(u);
            });
        }
    }

    public async new_tags(title: string): Promise<ITagSchema> {
        return this._httpClient.post<ITagSchema>('tags/', {title});
    }
}

export const tagManager: TagManager = new TagManager(httpClient);
