import {EventBus} from '@/EventBus';
import {EnvironmentHelper} from '@/EnvironmetHelper';

export interface IWebSocketClient {
    heartbeatSendInterval: number;
    heartbeatTout: number;
    reconnectRetryInterval: number;
    token: string;

    destroy(): void;
}

enum EWsState {
    CONNECTING,
    OPEN,
    CLOSING,
    CLOSED
}

export class WebSocketClient implements IWebSocketClient {

    heartbeatTout = -1;
    reconnectRetryInterval = 5000;
    heartbeatSendInterval = 5000;
    token = '';
    destroyed = false;
    private socket?: WebSocket;

    constructor(token: string) {
        this.token = token;
        this.createSocket();
        return this;
    }

    public destroy() {
        if (this.socket) {
            clearTimeout(this.heartbeatTout);
            this.heartbeatTout = -1;
            //impedisce che sull'evento di close la ws provi a riconnettersi
            this.destroyed = true;
            this.socket.close();
            delete this.socket;
        }
    }

    private heartbeat() {
        if (this.socket && this.socket.readyState) {
            this.socket.send('heartbeat');
            this.heartbeatTout = setTimeout(() => {
                this.heartbeat();
            }, this.heartbeatSendInterval);
        }
    }

    private onError(ev: Event) {
        // console.log('ws error');
        EventBus.$emit('wsError');
    }

    private onOpen(ev: Event) {
        /*se riesce ad aprire cancella l'interval*/
        this.heartbeat();
        EventBus.$emit('wsOpen');
    }

    private onClose(ev: CloseEvent) {
        if (!this.destroyed) {
            // console.log('ws close');
            clearTimeout(this.heartbeatTout);
            this.heartbeatTout = -1;
            setTimeout(() => {
                this.createSocket();
            }, this.reconnectRetryInterval);
            EventBus.$emit('wsClose');
        }
    }

    private onMessage(ev: MessageEvent) {
        EventBus.$emit('new-socket-message', ev.data);
    }

    private createSocket() {
        this.socket = new WebSocket(EnvironmentHelper.wsBaseUrl + 'user/?' + this.token);

        this.socket.onerror = (ev) => {
            this.onError(ev);
        };
        this.socket.onmessage = (ev) => {
            this.onMessage(ev);
        };
        this.socket.onopen = (ev) => {
            this.onOpen(ev);
        };
        this.socket.onclose = (ev) => {
            this.onClose(ev);
        };
    }
}
