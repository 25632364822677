
























import {Component} from 'vue-property-decorator';
import ModalHeader from '@/components/ModalHeader.vue';
import {Mixins} from 'vue-mixin-decorator';
import {ISubTaskModalMixin} from '@/components/Mixin/Mixin';
import StoreMixin from '@/components/Mixin/StoreMixin.vue';
import UserSelect from '@/components/UserSelect.vue';
import {MultiselectUserSchema} from '@/datastore/schemas/UserSchema';
import {taskManager} from '@/datastore/managers/TaskManager';
import IMOEditor from '@/components/IMOEditor.vue';
import {IMOeditorDataSchema} from '@/datastore/schemas/IMOeditorDataSchema';
import {EventBus} from '@/EventBus';

@Component({
    components: {ModalHeader, UserSelect, IMOEditor},
    name: 'TransferDeleteModal'
})
export default class TransferDeleteModal extends Mixins<ISubTaskModalMixin>(StoreMixin) {

    public close() {
        this.$bvModal.hide('TransferDelete');
    }

    public handleYes() {
        taskManager.transfer_delete(this.$store.getters['modal/activeTask'].pending_transfer.id).then((a: any) => {
            // console.log('transfer inviato', a)
            this.close()
        }).catch((e: any) => {
            // console.log("errore transfer", e)
        });
    }

    public handleNo() {
        this.close();
    }
}
