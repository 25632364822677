






















import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {},
    name: "DeleteDashboardModal"
})
export default class DeleteDashboardModal extends Vue {

    get active_modal_dashboard() {
        return this.$store.getters['modal/activeDashboard'];
    }

    public handleYes() {
        this.$bvModal.hide('DeleteDashboard');
        this.$store.dispatch('dashboard/delete', {id: this.active_modal_dashboard.id}).then(() => {
            this.$bvModal.hide('Dashboard');
        }).catch(() => {
            // console.log('errore cancellazione dashboard')
        })
    }

    public handleNo() {
        this.$bvModal.hide('DeleteDashboard');
    }
}
