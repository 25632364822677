














































































import {Component, Vue} from 'vue-property-decorator';
import {ISignUpError, SignUpFormFieldError,} from "@/store/types";

@Component({
    components: {},
    name: "SignUpForm"
})
export default class SignUpForm extends Vue{
    private email: string = '';
    private username: string = '';
    private password1: string = '';
    private password2: string = '';
    private disable: boolean = false;

    private message: string = '';
    private showForm: boolean = true;

    private error: SignUpFormFieldError = new SignUpFormFieldError();

    public signUp() {
        const data: any = {
            email: this.email,
            password1: this.password1,
            password2: this.password2,
        };
        if (this.username !== '') {
            data.username = this.username;
        }
        this.disable = true;
        this.$store.dispatch('account/signUp', data).then(() => {
            this.message = this.$t('message.success_signup') as string;
            this.showForm = false;
        }).catch((e: ISignUpError) => {
            this.error = new SignUpFormFieldError(e);
        }).finally(() => {
            this.disable = false
        })
    }
}
