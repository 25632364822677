import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {Attachment} from "@/datastore/entities/Attachment";
import {ITopicSchema} from "@/datastore/schemas/TopicSchema";

export interface ITopicManager {
    post_task_topic: (task_id:number, content:string, attachments:number[]) => Promise<ITopicSchema>;
    fetch_task_topics: (task_id:number) => Promise<ITopicSchema[]>
    // fetch_by_id: (id:number) => Promise<ITopicSchema>
}

export class TaskTopicManager implements ITopicManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public post_task_topic(task_id:number, content:string, attachments:number[]):Promise<ITopicSchema>{
        return this._httpClient.post<ITopicSchema>('task_topics/' + task_id + '/', {content,set_attachments:attachments})
    }

    public fetch_task_topics(task_id:number):Promise<ITopicSchema[]>{
        return this._httpClient.get<ITopicSchema[]>('task_topics/' + task_id);
    }

    // public fetch_by_id(id:number):Promise<ITopicSchema>{
    //     return this._httpClient.get<ITopicSchema>('task_topic/' + id);
    // }


}

export const taskTopicManager: TaskTopicManager = new TaskTopicManager(httpClient);
