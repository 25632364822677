












































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Editor, EditorContent, EditorMenuBar} from 'tiptap';
import EditorButtons from '@/components/EditorButtons.vue';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';

import EditorFileUpload from '@/components/EditorFileUpload.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import {attachmentsManager} from '@/datastore/managers/AttachmentsManager';
import {ILinkUploadSchema} from '@/datastore/schemas/AttachmentSchema';
import {MaxSize, sendShortcut} from '@/components/sendShortcut';
import {Attachment} from "@/datastore/entities/Attachment";

@Component({
    components: {EditorButtons, EditorContent, EditorMenuBar, EditorFileUpload},
    name: 'IMOEditor'
})
export default class IMOEditor extends Vue {

    @Prop(Boolean) private readonly disabled!: boolean;
    @Prop({type:Number, default:undefined}) private readonly maxLength!: number;
    @Prop(Number) private readonly height!: number;
    @Prop(String) private readonly content!: string;
    @Prop(Array) private readonly attachments!: Attachment[];

    private uploadComplete: boolean = true;
    private fileAttachments: number[] = [];
    private total_upload: number = 0;
    private focus: boolean = false;
    private counter: number = 0;

    private links: string[] = [];
    private linksToUpload: ILinkUploadSchema[] = [];
    private requestStatusLink: Promise<any>[] = [];
    private linkAttachments: number[] = [];


    // private readonly regex_url: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    private readonly regex_url: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    private readonly regex_a: RegExp = /<a\s*.*>.*<\/a>/gi;


    private ps!: PerfectScrollbar;
    private editor = new Editor({
        content: this.content,
        autoFocus: true,
        onUpdate: this.psUpdate,
        onBlur: this.focusUpdate,
        onFocus: this.focusUpdate,
        extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({levels: [1, 2, 3]}),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem({
                nested: true,
            }),
            new TodoList(),
            new Bold(),
            new Code(),
            new Italic(),
            //new Link(),
            new Strike(),
            new Underline(),
            new History(),
            new sendShortcut(this.collect_send),
            new MaxSize({maxSize: this.maxLength}),
        ],
        onPaste: this.paste
    });

    get style() {
        return {
            'height': this.height + 'px'
        };
    }

    get disableButton() {
        if (this.total_upload) {
            return !this.uploadComplete;
        }
        return this.editor.getHTML() === '<p></p>';
    }

    public clearContent() {
        this.editor.clearContent();
        (this.$refs.fu as EditorFileUpload).removeUpload();
        this.fileAttachments = [];
        this.linkAttachments = [];
        this.linksToUpload.splice(0, this.linksToUpload.length);
        this.requestStatusLink = [];
        this.total_upload = 0;
    }

    public beforeDestroy() {
        this.editor.destroy();
        this.ps && this.ps.destroy();
    }

    private clickInput() {
        (this.$refs.fu as EditorFileUpload).openFileManager();
    }

    private fileUploadAddHandler(upls: Promise<any>[]) {
        this.total_upload = upls.length;
        this.uploadComplete = false;
        Promise.all(upls).then((e) => {
            this.fileAttachments = e.filter((f) => {
                return f;
            });
            this.uploadComplete = true;
        });
    }

    private collect_send() {

        const msg = this.editor.getHTML().replace(this.regex_url, (val: string) => {
            return `<a target="_blank" href="` + val + `">` + val + `</a>`;
        });

        const el = {
            content: msg,
            attachments: [...this.fileAttachments, ...this.linkAttachments]
        };
        this.$emit('onSend', el);
        return false;
    }

    private psUpdate(editor: any) {
        // console.log(editor.getHTML());
        this.searchLink(editor);
        if (this.ps) {
            this.ps.update();
        } else {
            this.ps = new PerfectScrollbar((this.$refs.editor as Vue).$el, {
                suppressScrollX: true
            });
        }

        this.counter = (this.$refs.editor as any).$el.textContent.length
    }

    private focusUpdate(obj: any) {
        if (obj.event.type == 'blur') {
            this.focus = false;
        } else if (obj.event.type == 'focus') {
            this.focus = true;
        }
    }

    private searchLink(e: any) {
        if (!this.editor) {
            return;
        }
        const htmlObject = document.createElement('div');
        htmlObject.innerHTML = e.getHTML();
        //rimuovo tutti i link
        this.links = e.getHTML().match(this.regex_url) || [];
        if (this.links) {
            this.links = this.links.filter((item, pos, arr) => {
                return arr.indexOf(item) == pos;
            });
        }
    }

    private uploadLinks() {
        this.linksToUpload = this.links.map((l) => {
            return {
                id: -1,
                url: l,
                description: l,
                success: null,
                error: '',
            };
        });

        this.uploadComplete = false;

        this.linksToUpload.forEach((l, i) => {
            this.requestStatusLink.push(
                new Promise<any>((resolve, reject) => {
                    attachmentsManager.link_attachment(l.url, l.description).then((e: any) => {
                        this.linksToUpload[i].success = true;
                        this.linksToUpload[i].id = e.id;
                        resolve(e.id);
                    }).catch((e: Error) => {
                        this.linksToUpload[i].success = false;
                        this.linksToUpload[i].error = e.message;
                        resolve(undefined);
                    });
                })
            );
        });

        Promise.all(this.requestStatusLink).then((e) => {
            this.linkAttachments = e.filter((l) => {
                return l;
            });
            this.uploadComplete = true;
        }).then(() => {
            this.collect_send();
        });
    }

    private paste(view: any, event: any) {
        let hasFiles = false;
        let files: FileList = event.clipboardData.files;
        [...files].filter(item => item.type.startsWith('image')).map((file: File) => {
            (this.$refs['fu'] as EditorFileUpload).append(file);
            hasFiles = true;
        });
        (this.$refs['fu'] as EditorFileUpload).send();
        if (hasFiles) {
            event.preventDefault();
            return true;
        }
    }

}
