











































import {Component, Mixins, Prop} from 'vue-property-decorator';
import Chat from '@/components/Chat.vue';
import {EPanelType} from '@/store/types';
import {Editor, EditorContent} from 'tiptap';
import {
    Blockquote,
    Bold,
    BulletList,
    Code,
    CodeBlock,
    HardBreak,
    Heading,
    History,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Strike,
    TodoItem,
    TodoList,
    Underline,
} from 'tiptap-extensions';
import {Attachment} from "@/datastore/entities/Attachment";
import StoreMixin from "@/components/Mixin/StoreMixin.vue";
import {ITopicMixin} from "@/components/Mixin/Mixin";
import PanelMixin from "@/components/Mixin/PanelMixin.vue";

@Component({
    components: {Chat, EditorContent},
    name: "Topic"
})

export default class Topic extends Mixins<ITopicMixin>(PanelMixin,StoreMixin) {
    @Prop(Number) private readonly topicId!: number;
    @Prop(Array) private readonly attachments!: Attachment[];
    @Prop(Date) private readonly created!: Date;
    @Prop(String) private readonly content!: string;
    @Prop(String) private readonly avatar!: string;
    @Prop(Number) private readonly userId!: number;
    @Prop(Number) private messages_to_read!: number;
    private cancelscroll!: any;

    private editor = new Editor({
        content: this.content,
        editable: false,
        extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({levels: [1, 2, 3]}),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem({
                nested: true,
            }),
            new TodoList(),
            new Bold(),
            new Code(),
            new Italic(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
        ],
    });

    get isActive() {
        return this.activeTopicId == this.topicId;
    }

    public beforeDestroy() {
        this.editor.destroy();
    }

    private close(e: MouseEvent) {
        this.$store.dispatch('modal/resetActiveTopic');
        this.panelType = EPanelType.SHOW_TOPICS;
    }

    private open() {
        if (!this.isActive) {
            this.panelType = EPanelType.MESSAGE;
            this.$store.dispatch('modal/setActiveTopicById', {id: this.topicId});
            this.$store.dispatch('modal/fetchActiveTopicMessages');
        }
        this.$store.dispatch('topics/resetToReadCount', {id: this.topicId});
    }
}
