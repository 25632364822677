

















import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import WSAlert from '@/components/WSAlert.vue';
import {IWsMessage} from '@/websocket/messagesType';
import {EventBus} from '@/EventBus';

@Component({
    components: {
        'ws-alert': WSAlert
    },
    computed: {
            ...mapGetters({
                events: 'events',
            })
        },
    name: "BackOffice"
})
export default class BackOffice extends Vue {
    events?: IWsMessage[];

    private click(event: any) {
        EventBus.$emit('BackOfficeClick', event);
    }

    get taskEvents() {
        if(this.events){
            let task_index: string[] = [];
            let res =  [...this.events].reverse().reduce((previousValue, currentValue, currentIndex, array) => {
                const val = currentValue.data.task_id.toString();
                if (task_index.indexOf(val) === -1){
                    previousValue.push(currentValue);
                    task_index.push(val);
                }
                return previousValue
            }, [] as IWsMessage[]);
            return res
        }
        return [];
    }
}
