





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Task as TaskModel} from "@/datastore/entities/Task"
import {userManager} from "@/datastore/managers/UserManager";
import {ERole, IPublicSchema} from "@/datastore/schemas/PublicSchema";
import ChangeStatusModal from "@/components/Modal/ChangeStatusModal.vue";
import {User} from "@/datastore/entities/User";
import {httpClient} from '@/datastore/HttpClient';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import wsworker from '@/websocket/WSworker'
import {EventBus} from '@/EventBus';
import {Home, MenuRoutes} from '@/routes';
import Panel from "@/components/SidePanel/Panel.vue";
import NavBar from "@/components/NavBar.vue";
import {router} from '@/router';

@Component({
    components: {
        NavBar,
        ChangeStatusModal,
        Loading,
        Panel
    },
    name: "Public"
})
export default class Public extends Vue {
    @Prop(String) private readonly token!: string;
    //
    private role!: ERole;
    private task_id: number = -1;
    private message: string = '';
    private notFound: boolean = false;
    private Home = Home;

    //
    get task() {
        return this.$store.getters['modal/activeTask'];
    }

    //
    get activateLoad() {
        return !(this.task || this.notFound)
    }

    public created() {
        EventBus.$on('new-socket-message', (data: string) => {
            wsworker.dispatch(JSON.parse(data));
        });
        this.$store.dispatch('account/getProfile').then(() => {
            return router.push(MenuRoutes.dashboard.to);
        }).catch(() => {
            localStorage.setItem('public-token', this.token);
            return this.$store.dispatch('account/setPublicToken', {token: this.token}).then((e) => {
                //settare il token per tutte le chiamate
                httpClient.setAuthToken('PublicToken', this.token);
                return userManager.public(this.token);
            }).then((e: IPublicSchema) => {

                this.$store.dispatch('account/setPublicUser', {user: new User(e.user)});
                this.role = ERole[e.role];
                this.task_id = e.task.id;
                this.$store.dispatch('task/setPublic', {t: new TaskModel(e.task)}).then(() => {
                    EventBus.$emit('openPanel', this.task_id);
                });
            });

        }).catch(() => {
            this.notFound = true;
            this.message = this.$t('message.task_not_found') as string
        })
    }
}
