


































import {Component, Vue} from 'vue-property-decorator';
import {Home} from '@/routes.ts';
import {userManager} from "@/datastore/managers/UserManager";

@Component({
    components: {},
    name: "ResetPassword"
})
export default class ResetPassword extends Vue {
    private email: string = '';
    private: string = '';
    private error: string = '';
    private showForm: boolean = true;
    private disable: boolean = false;
    private message: string = '';

    public reset() {
        this.error = '';
        this.disable = true;
        userManager.resetPassword(this.email).then(() => {
            this.message = this.$t('message.success_pwd_reset') as string;
            this.showForm = false;
        }).catch(() => {
            this.disable = false;
            this.error = `Si è verificato un errore, contatta il tuo amministratore`;
        });
    }
}
