















import {Component, Prop, Vue} from 'vue-property-decorator';
import {EAttachmentType} from "@/store/types";

@Component({
    components: {},
    name: "AttachmentBox",
})
export default class AttachmentBox extends Vue {
    @Prop(Number) private readonly id!: number;
    @Prop(String) private readonly file!: string;
    @Prop(Date) private readonly created!: Date;
    @Prop(String) private readonly name!: string;
    @Prop(String) private readonly url!: string;
    @Prop(String) private readonly description!: string;
    @Prop(String) private readonly preview!: null | string;
    @Prop(Array) private readonly task_set!: number[];
    @Prop(Array) private readonly message_set!: number[];
    @Prop(Number) private readonly attachment_type!: EAttachmentType;

    get Url() {
        switch (this.attachment_type) {
            case EAttachmentType.media:
            case EAttachmentType.file:
                return this.file;
            case EAttachmentType.link:
                return this.url

        }
        return 'javascript:void(0)'
    }

    get style() {
        if (this.preview) {
            return {
                backgroundImage: "url('" + this.preview + "')",
                backgroundSize: 'cover'
            }
        }
        return {}
    }

    private removeAttachment(e:Event) {
        e.stopPropagation()
        e.preventDefault()
        this.$store.dispatch('modal/setDeleteAttachment',{id:this.id}).then(()=>{
            this.$bvModal.show('DeleteAttachment');
        })
    }
}
