




















































































import {Component, Vue} from 'vue-property-decorator';
import {PublicAcces, Home, MenuRoutes} from '@/routes.ts';
import {IFormError, ISignUpError, SignInFormFieldError} from "@/store/types";
import {EnvironmentHelper} from "@/EnvironmetHelper";

@Component({
    components: {},
    name: "SignInForm"
})
export default class SignInForm extends Vue {
    private email_: string = '';
    private password_: string = '';
    private PublicAccesUrls: object = PublicAcces;
    private HomeUrls: object = Home;
    private disable: boolean = false;

    private error: SignInFormFieldError = new SignInFormFieldError();

    get email() {
        return this.email_;
    }

    set email(val: string) {
        this.email_ = val;
    }

    get password() {
        return this.password_;
    }

    set password(val: string) {
        this.password_ = val;
    }

    public signIn() {
        this.disable = true;
        window.localStorage.removeItem('auth-token');
        this.$store.dispatch('account/signIn', {email: this.email, password: this.password}).then((e) => {
            this.$router.push(MenuRoutes.dashboard.to);
        }).catch((e: ISignUpError) => {
            this.error = new SignInFormFieldError(e);
        }).finally(() => {
            this.disable = false;
        })
    }

    public AuthProvider(provider: any): any {

        //@ts-ignore
        this.$auth.authenticate(provider).then((response: any) => {

            this.SocialLogin(provider, response)

        }).catch((err: any) => {
            // console.log({err: err})
        })

    }

    public SocialLogin(provider: any, response: any): any {
        //@ts-ignore
        this.$http.post(EnvironmentHelper.apiBaseUrl + 'rest-auth/' + provider + '/', response).then((response: any) => {

            window.localStorage.setItem('auth-token', response.data.key);
            this.$store.dispatch('account/getProfile').then(()=>{
                this.$router.push(MenuRoutes.dashboard.to);
            })



        }).catch((err: any) => {
            // console.log({err: err})
        })
    }
}

