




























import {Component, Vue} from 'vue-property-decorator';
import NavBar from "@/components/NavBar.vue";

@Component({
    components: {NavBar},
    name: "Home"
})
export default class Home extends Vue {
}
