import {Component, Vue} from 'vue-property-decorator';


@Component({
    name: 'EventBus',
})
class EventBusClass extends Vue {

}

export const EventBus = new EventBusClass();
