





































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Home as homeLinks} from '@/routes';

@Component({
    components: {},
    name: "NavBar"
})
export default class NavBar extends Vue {
    private link = homeLinks;
}
