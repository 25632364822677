import {ActionTree, GetterTree, MutationTree} from 'vuex';
import Vue from 'vue';
import {IDashboardDict, RootState} from '@/store/types';
import {dashboardManager} from "@/datastore/managers/DashboardManager";
import {Dashboard} from "@/datastore/entities/Dashboard";

const namespaced: boolean = true;

interface IDashboardState {
    dashboards: IDashboardDict;
    filterDashboardId: number | undefined;
    //FIXME eventualmente da spostare i campi dell'input search in un nuovo modulo
    filterString: string;
    showCompleted: boolean;
}

const dashboardState: IDashboardState = {
    dashboards: {},
    filterDashboardId: undefined,
    filterString: '',
    showCompleted: true,
};

const getters: GetterTree<IDashboardState, RootState> = {
    filterDashboard(state): Dashboard {
        return state.dashboards[state.filterDashboardId as never];
    },
    filterString(state): string {
        return state.filterString;
    },
    dashboardById: (state) => (id: number) => {
        return state.dashboards[id];
    },
    dashboards(state): IDashboardDict {
        return state.dashboards;
    },
    showCompleted(state): boolean {
        return state.showCompleted;
    },
    dashboardArraySort(state) {
        return Object.values(state.dashboards).sort((a: Dashboard, b: Dashboard) => {
            if (a.starred && !b.starred) {
                return -1;
            }
            if (!a.starred && b.starred) {
                return 1;
            }
            if ((!a.starred && !b.starred) || (a.starred && b.starred)) {
                return a.id - b.id;
            }
            return 0;
        });
    },
    starred(state) {
        return Object.values(state.dashboards).filter((d: Dashboard) => {
            return d.starred
        })
    }
};

const actions: ActionTree<IDashboardState, RootState> = {
    resetFilterDashboardId({commit}) {
        commit('setFilterDashboardId', undefined)
    },
    setFilterDashboardId({commit}, {id}) {
        commit('setFilterDashboardId', id)
    },
    setFilterString({commit}, {filter}) {
        commit('setFilterString', filter)
    },
    updateDashboard({commit}, {d}) {
        commit('setDashboard', d)
    },
    fetchDashboards({commit}) {
        return dashboardManager.dashboards().then((ds) => {
            ds.forEach((d) => {
                commit('setDashboard', d)
            })
        });
    },
    fetchDashboardById({commit}, {id}) {
        return dashboardManager.fetch_by_id(id).then((ds) => {
            commit('setDashboard', new Dashboard(ds))
        });
    },
    star({commit, getters}, {id}) {
        const d = getters.dashboardById(id);
        d.starred = true;
        commit('setDashboard', d)
    },
    unstar({commit, getters}, {id}) {
        const d = getters.dashboardById(id);
        d.starred = false;
        commit('setDashboard', d)
    },
    delete({commit, getters}, {id}) {
        return dashboardManager.delete(id).then(() => {
            commit('deleteDashboard', id)
        })
    },
    hideCompleted({commit, getters}) {
        commit('setShowCompleted', false)
    },
    showCompleted({commit, getters}) {
        commit('setShowCompleted', true)
    },
};

const mutations: MutationTree<IDashboardState> = {
    setDashboard(state, d) {
        Vue.set(state.dashboards, d.id, d);
    },
    setShowCompleted(state, d) {
        state.showCompleted = d;
    },
    setFilterDashboardId(state, f) {
        state.filterDashboardId = f;
    },
    setFilterString(state, s) {
        state.filterString = s;
    },
    deleteDashboard(state, id) {
        Vue.set(state.dashboards, id, undefined);
        delete state.dashboards[id];
    }
};


export default {
    namespaced,
    state: dashboardState,
    getters,
    actions,
    mutations,
};
