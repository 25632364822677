import {IUserSchema, MultiselectUserSchema} from '@/datastore/schemas/UserSchema';
import {IUsersSettingsSchema} from "@/datastore/schemas/UsersSettingsSchema";

export class User {

    public readonly id: number;
    public readonly email: string;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly avatar: string;
    public readonly username: string;
    public readonly newCcTasks: boolean;
    public readonly newActivities: boolean;
    public readonly premium: boolean;

    constructor();
    constructor(data: IUserSchema);
    constructor(data: IUsersSettingsSchema);
    constructor(data?: any) {
        this.id = data && data.id || -1;
        this.email = data && data.email || '';
        this.firstName = data && data.first_name || '';
        this.lastName = data && data.last_name || '';
        this.avatar = data && data.avatar || '';
        this.username = data && data.username || '';
        this.newCcTasks = (data && data.new_cc_tasks) || false;
        this.newActivities = (data && data.new_activities) || false;
        this.premium = (data && data.premium) || false;
    }

    get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    get fullNameOrEmail(): string {
        return this.fullName.length === 0 ? this.fullName : this.email;
    }

    public get toFormatted(): string {
        return this.email + ' ' + this.fullName;
    }

    public get MultiselectItem(): MultiselectUserSchema {
        return {
            name: this.email,
            email: this.email,
        }
    }

    get getAvatar(){
        return this.avatar || 'https://robohash.org/' + this.id;
    }
}

