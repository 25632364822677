import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {EnvironmentHelper} from '@/EnvironmetHelper';

export interface IHttpClient {
    setAuthToken: (name: string, value: string) => void;
    get: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
    delete: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
    post: <T>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
    patch: <T>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
    put: <T>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
}

class HttpClient implements IHttpClient {
    private _http: AxiosInstance;

    constructor(){
        this._http = axios.create({
            baseURL: EnvironmentHelper.apiBaseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this._http.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('auth-token');
                if (token) {
                    config.headers.Authorization = `Token ${token}`;
                }
                return config;
            },

            (error) => {
                return Promise.reject(error);
            },
        );

    }

    public setAuthToken(name: string, value: string) {
        this._http.defaults.headers.Authorization = name + ' ' + value;
    }

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this._http.get(url, config);
        return response.data;
    }

    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this._http.post(url, data, config);
        return response.data;
    }

    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this._http.put(url, data, config);
        return response.data;
    }

    public async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response: AxiosResponse = await this._http.patch(url, data, config);
        return response.data;
    }

    public  async delete<T>(url:string, config?:AxiosRequestConfig):Promise<T>{
        const response: AxiosResponse = await this._http.delete(url,config);
        return response.data;
    }
}

export const httpClient: IHttpClient = new HttpClient();

