



















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import AttachmentList from "@/components/SidePanel/AttachmentList.vue";
import PanelContentTemplate from "@/components/SidePanel/PanelContentTemplate.vue";
import {EAttachmentType} from "@/store/types";
import {Attachment} from "@/datastore/entities/Attachment";
import EditorFileUpload from "@/components/EditorFileUpload.vue";
import {mapGetters} from "vuex";


@Component({
    components: {PanelContentTemplate, AttachmentList, EditorFileUpload},
    name: "MediaTab",
    computed: {
        ...mapGetters({
            activeTask: 'modal/activeTask',
        })
    },
})
export default class MediaTab extends Vue {
    private state: number = 0;
    private total_upload: number = 0;
    private uploadComplete: boolean = false;
    private fileAttachments: number[] = [];

    get isUploading() {
        return this.state === 1
    }

    get isViewing() {
        return this.state === 0
    }

    get attachments() {
        const all = this.$store.getters['attachment/attachmentsByTaskId'](this.$store.getters['modal/activeTask'].id);

        if (all && all.length) {
            //todo: si può migliorare
            switch (this.$store.getters['modal/activeMedia']) {
                case EAttachmentType.media:
                    return [
                        {
                            type: EAttachmentType.media,
                            attachments: all.filter((a: Attachment) => {
                                return a.attachment_type == EAttachmentType.media
                            })
                        }];
                case EAttachmentType.link:
                    return [{
                        type: EAttachmentType.link,
                        attachments: all.filter((a: Attachment) => {
                            return a.attachment_type == EAttachmentType.link
                        })
                    }];
                case EAttachmentType.file:
                    return [{
                        type: EAttachmentType.file,
                        attachments: all.filter((a: Attachment) => {
                            return a.attachment_type == EAttachmentType.file
                        })
                    }];
                case EAttachmentType.NONE:
                    return [
                        {
                            type: EAttachmentType.media,
                            attachments: all.filter((a: Attachment) => {
                                return a.attachment_type == EAttachmentType.media
                            })
                        },
                        {
                            type: EAttachmentType.link,
                            attachments: all.filter((a: Attachment) => {
                                return a.attachment_type == EAttachmentType.link
                            })
                        },
                        {
                            type: EAttachmentType.file,
                            attachments: all.filter((a: Attachment) => {
                                return a.attachment_type == EAttachmentType.file
                            })
                        }
                    ]

            }
        }
        return []
    }

    private fileUploadAddHandler(upls: Promise<any>[]) {
        this.total_upload = upls.length;
        this.uploadComplete = false;
        Promise.all(upls).then((e) => {
            this.fileAttachments = e.filter((f) => {
                return f;
            });
            this.uploadComplete = true;
        });
    }

    private send() {
        //@ts-ignore
        this.activeTask.addAttachments(this.fileAttachments).then((e)=>{
            this.state = 0;
            this.total_upload = 0;
            this.uploadComplete = false;
            this.fileAttachments = [];
            (this.$refs.fu as EditorFileUpload).removeUpload();
        })
    }

    private openFileManager() {
        (this.$refs.fu as EditorFileUpload).openFileManager();
    }

}
