




import {Vue} from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import {EPanelType} from "@/store/types";

@Mixin
export default class PanelMixin extends Vue {

    get panelTab() {
        return this.$store.getters['modal/panelTab'];
    }

    set panelTab(t: number) {
        this.$store.dispatch('modal/panelTab', {tab_index: t});
    }

    public panelMatch(arr: number[]) {
        return this.$store.getters['modal/panelMatch'](arr);
    }

    get panelAccept(){
        return this.panelMatch([EPanelType.ACCEPT]);
    }

    get panelType() {
        return this.$store.getters['modal/panelType'];
    }
    set panelType(t:EPanelType){
        this.$store.dispatch('modal/panelType',{type:t});
    }

    get canShowNewTopicButton(){
        return this.panelMatch([EPanelType.SHOW_TOPICS])
    }
    get panelShowTopic() {
        return this.panelMatch([EPanelType.TOPIC, EPanelType.SHOW_TOPICS])
    }
    get panelCreateTopic(){
        return this.panelMatch([EPanelType.TOPIC])
    }
    get panelShowAllTopics() {
        return this.panelMatch([EPanelType.SHOW_TOPICS, EPanelType.TOPIC])
    }
    get panelShowingChat() {
        return this.panelMatch([EPanelType.MESSAGE])
    }
}
