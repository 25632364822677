import {RootState} from '@/store/types';
import {StoreOptions} from 'vuex';
import account from '@/store/modules/account';
import task from '@/store/modules/tasks';
import Vue from 'vue';
import Vuex from 'vuex';
import dashboard from "@/store/modules/dashboard";
import topics from "@/store/modules/topics";
import modal from "@/store/modules/modal";
import attachment from "@/store/modules/attachment";
import {IWsMessage} from '@/websocket/messagesType';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        showLoader: false,
        errors: [],
        events: [],
    },
    modules: {
        account,
        task,
        dashboard,
        topics,
        modal,
        attachment
    },
    getters: {
        showLoader(state): boolean {
            return state.showLoader;
        },
        errors(state): string[] {
            return state.errors;
        },
        events(state): IWsMessage[] {
            return state.events;
        },
    },
    actions: {
        showLoader({commit}) {
            commit('setLoader', true);
        },
        hideLoader({commit}) {
            commit('setLoader', false);
        },
        addError({commit}, error) {
            commit('addError', error);
        },
        removeErrors({commit}) {
            commit('removeErrors');
        },
        addEvent({commit, dispatch}, event) {
            commit('addEvent', event);
            dispatch('account/newActivities');
        },
    },
    mutations: {
        setLoader(state, show) {
            state.showLoader = show;
        },
        addError(state, error: string) {
            state.errors.push(error);
        },
        addEvent(state, event: IWsMessage) {
            state.events.push(event);
        },
        removeErrors(state) {
            state.errors = [];
        },
    },
};

export default new Vuex.Store(store);
