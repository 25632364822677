














































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import {Editor, EditorContent} from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';
import PerfectScrollbar from "perfect-scrollbar";
import AttachmentBox from "@/components/SidePanel/AttachmentBox.vue";
import {ITaskTabHeaderMixin} from "@/components/Mixin/Mixin";
import PermissionMixin from "@/components/Mixin/PermissionMixin.vue";
import TagSelect from "@/components/TagSelect.vue";
import {MultiselectTagSchema} from "@/datastore/schemas/TagSchema";
import {taskManager} from "@/datastore/managers/TaskManager";
import {Tag} from "@/datastore/entities/Tag";


@Component({
    components: {EditorContent, AttachmentBox, TagSelect},
    computed: {
        ...mapGetters({
            activeTask: 'modal/activeTask',
        })
    },
    name: "TaskTabHeader",
})
export default class TaskTabHeader extends Mixins<ITaskTabHeaderMixin>(PermissionMixin) {
    private editor_scroll!: PerfectScrollbar;
    private tagEdit: boolean = false;
    private contentEdit: boolean = false;
    private content: string = '';
    private selected_tag: MultiselectTagSchema[] = [];
    private editor = new Editor({
        editable: false,
        extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({levels: [1, 2, 3]}),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem({
                nested: true,
            }),
            new TodoList(),
            new Bold(),
            new Code(),
            new Italic(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
        ],
    });

    get contentEditable() {
        return this.contentEdit;
    }

    set contentEditable(val: boolean) {
        this.contentEdit = val
        this.editor.setOptions({editable: val})
        // this.editor.view.update(this.editor.view.props);
    }

    get selectedTags() {
        if (this.tagEdit) {
            return this.selected_tag;
        }
        return this.selected_tag = this.activeTask.tags.map((u: Tag) => {
            return u.MultiselectItem;
        });
    }

    set selectedTags(val: MultiselectTagSchema[]) {
        this.selected_tag = val;
    }

    updated() {
        //todo rimuovere e creare mixin apposito
        //@ts-ignore
        this.editor.setContent(this.activeTask.content);
        this.updateScroll();
    }

    mounted() {
        //todo rimuovere e creare mixin apposito
        //@ts-ignore
        this.editor.setContent(this.activeTask.content);
        this.updateScroll();
    }

    public beforeDestroy() {
        this.editor.destroy();
        this.editor_scroll && this.editor_scroll.destroy();
    }

    private tagEditSave() {
        taskManager.update_task(this.activeTask.id, {
            tags: this.selected_tag.map(e => {
                return e.title
            })
        }).then((e) => {
            this.tagEdit = false;
        });
    }

    private contentEditSave() {
        taskManager.update_task(this.activeTask.id, {
            content: this.editor.getHTML()
        }).then((e) => {
            this.contentEditable = false;
        });
    }

    private updateScroll() {
        if (this.editor_scroll) {
            this.editor_scroll.update()
        } else {
            this.editor_scroll = new PerfectScrollbar((this.$refs.editor_scroll as Vue).$el, {
                suppressScrollX: true
            });
        }
    }
}
