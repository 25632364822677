import {Task} from '@/datastore/entities/Task';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {ITaskDict, RootState} from '@/store/types';
import {taskManager} from '@/datastore/managers/TaskManager';
import {ETaskStatus, ITaskSchema} from '@/datastore/schemas/TaskSchema';
import Vue from 'vue';
import {userManager} from '@/datastore/managers/UserManager';

const namespaced: boolean = true;

interface ITaskState {
    in: number[];
    my: number[];
    out: number[];
    cc: number[];
    archived: number[];
    dictionary: ITaskDict;
    public: number;
}

const taskState: ITaskState = {
    in: [],
    my: [],
    out: [],
    cc: [],
    archived: [],
    dictionary: {},
    public: -1,
};

function schemaToDict(ts: ITaskSchema[]): ITaskDict {
    let t_dict: ITaskDict = {};
    ts.forEach((el: ITaskSchema) => {
        t_dict[el.id] = new Task(el);
    });
    return t_dict;
};

const getters: GetterTree<ITaskState, RootState> = {
    publicTask(state) {
        return state.dictionary[state.public];
    },
    taskById: (state) => (taskid: number): Task => {
        return state.dictionary[taskid];
    },
    children: (state, getters) => (taskid: number): Task => {
        return getters.tasksByIds(getters.taskById(taskid).children)
    },
    tasksByIds: (state) => (ids: number[]): Task[] => {
        return ids.reduce((children: Task[], id) => {
            if (state.dictionary[id]) {
                children.push(state.dictionary[id])
            }
            return children;
        }, [])
    },
    dictionary(state): ITaskDict {
        return state.dictionary;
    },
    in(state): Task[] {
        return state.in.map((id) => {
            return state.dictionary[id];
        });
    },
    my(state): Task[] {
        return state.my.map((id) => {
            return state.dictionary[id];
        });
    },
    out(state): Task[] {
        return state.out.map((id) => {
            return state.dictionary[id];
        });
    },
    cc(state): Task[] {
        return state.cc.map((id) => {
            return state.dictionary[id];
        });
    },
    archived(state): Task[] {
        return state.archived.map((id) => {
            return state.dictionary[id];
        });
    },
    isInIn: (state) => (id: number): boolean => {
        return state.in.includes(id);
    },
    isInOut: (state) => (id: number): boolean => {
        return state.out.includes(id);
    },
    isInMy: (state) => (id: number): boolean => {
        return state.my.includes(id);
    },
    isInCC: (state) => (id: number): boolean => {
        return state.cc.includes(id);
    },
    isInarchived: (state) => (id: number): boolean => {
        return state.archived.includes(id);
    },
};

const actions: ActionTree<ITaskState, RootState> = {
    fetchToReadCount({commit}, {id}) {
        if (id as number > 0) {
            userManager.messagesToRead(id).then(mc => {
                commit('setMessagesToRead', {to_read: mc.to_read, id})
            });
        }
    },
    //ricarica solamente quelli che non sono presenti nel dizionario e non effettua l'aggiornmento ricorsivo sui figli
    lazyFetchByIds({state, commit, dispatch}, {ids}) {
        ids = ids.filter((id: number) => {
            return !state.dictionary[id];
        });
        return dispatch('fetchByIds', {ids});
    },

    fetchByIds({commit, dispatch}, {ids}) {
        if (ids.length) {
            return taskManager.fetch_by_ids(ids).then((t) => {
                commit('mergeDict', schemaToDict(t));
            });
        }
    },

    setPublic({commit}, {t}) {
        commit('setPublic', t);
    },

    deleteById({commit}, {id}) {
        commit('deleteById', id);
    },

    fetchById({commit, dispatch}, {id, token}) {
        return taskManager.fetch_by_id(id, token).then((t) => {
            commit('mergeDict', schemaToDict([t]));
        });
    },
    fetchByIdNoRecursive({commit, dispatch}, {id}) {
        return taskManager.fetch_by_id(id).then((t) => {
            commit('setTask', new Task(t));
        });
    },
    fetchIn({commit, dispatch, rootGetters}, {pagination, order}) {
        const dashboard_id = rootGetters['dashboard/filterDashboard'] ? rootGetters['dashboard/filterDashboard'].id : undefined;
        const search = rootGetters['dashboard/filterString'];
        const pag = {...{page: 1, page_size: 50}, ...pagination};
        return taskManager.in(pag, search, order).then((ins) => {
            commit('mergeDict', schemaToDict(ins.results));
            commit('setIn', ins.results.map((e) => {
                return e.id;
            }));
            delete ins.results;
            return ins
        });
    },
    fetchMy({commit, dispatch, state, rootGetters}, {pagination, order}) {
        const dashboard_id = rootGetters['dashboard/filterDashboard'] ? rootGetters['dashboard/filterDashboard'].id : undefined;
        const search = rootGetters['dashboard/filterString'];
        const pag = {...{page: 1, page_size: 50}, ...pagination || {}};
        return taskManager.my(pag, dashboard_id, search, order).then((mys) => {
            commit('mergeDict', schemaToDict(mys.results));
            commit('setMy', mys.results.map((e) => {
                return e.id;
            }));
            delete mys.results;
            return mys
        });
    },
    fetchOut({commit, dispatch, rootGetters}, {pagination, order}) {
        const dashboard_id = rootGetters['dashboard/filterDashboard'] ? rootGetters['dashboard/filterDashboard'].id : undefined;
        const search = rootGetters['dashboard/filterString'];
        const pag = {...{page: 1, page_size: 50}, ...pagination};
        return taskManager.out(pag, search, order).then((outs) => {
            commit('mergeDict', schemaToDict(outs.results));
            commit('setOut', outs.results.map((e) => {
                return e.id;
            }));
            delete outs.results;
            return outs
        });
    },
    fetchCc({commit, dispatch, rootGetters}, {pagination, order}) {
        const dashboard_id = rootGetters['dashboard/filterDashboard'] ? rootGetters['dashboard/filterDashboard'].id : undefined;
        const search = rootGetters['dashboard/filterString'];
        const pag = {...{page: 1, page_size: 50}, ...pagination};
        return taskManager.cc(pag, search, order).then((ccs) => {
            commit('mergeDict', schemaToDict(ccs.results));
            commit('setCc', ccs.results.map((e) => {
                return e.id;
            }));
            delete ccs.results;
            return ccs
        });
    },
    fetchArchived({commit, dispatch, rootGetters}, {pagination, order}) {
        const dashboard_id = rootGetters['dashboard/filterDashboard'] ? rootGetters['dashboard/filterDashboard'].id : undefined;
        const search = rootGetters['dashboard/filterString'];
        const pag = {...{page: 1, page_size: 50}, ...pagination};
        return taskManager.archived(pag, search, order).then((archived) => {
            commit('mergeDict', schemaToDict(archived.results));
            commit('setArchived', archived.results.map((e) => {
                return e.id;
            }));
            delete archived.results;
            return archived
        });
    },
    removeIn({commit}, {id}) {
        commit('removeIn', id);
    },
    removeMy({commit}, {id}) {
        commit('removeMy', id);
    },
    removeOut({commit}, {id}) {
        commit('removeOut', id);
    },
    removeCc({commit}, {id}) {
        commit('removeCc', id);
    },
    removeArchived({commit}, {id}) {
        commit('removeArchived', id);
    },
    addIn({commit}, {id}) {
        commit('addOrReplaceIn', id);
    },
    addMy({commit}, {id}) {
        commit('addOrReplaceMy', id);
    },
    addOut({commit}, {id}) {
        commit('addOrReplaceOut', id);
    },
    addCc({commit}, {id}) {
        commit('addOrReplaceCc', id);
    },
    addArchived({commit}, {id}) {
        commit('addOrReplaceArchived', id);
    },
    replace({commit}, {t}) {
        const to: ITaskDict = {};
        to[t.id] = t;
        commit('mergeDict', to);
    },
    addChildToTask({commit}, {parent_id, child_id}) {
        commit('addChild', {parent_id, child_id});
    },
    delete({commit}, {id, msg}) {
        return taskManager.delete(id, msg).then(() => {
        })
    },

};

const mutations: MutationTree<ITaskState> = {

    addChild(state, {parent_id, child_id}) {
        if (state.dictionary[parent_id].children.indexOf(child_id) === -1) {
            state.dictionary[parent_id].children.push(child_id);
        }
    },
    setPublic(state, t) {
        Vue.set(state.dictionary, t.id, t);
        state.public = t.id;
    },
    mergeDict(state, d) {
        state.dictionary = {...state.dictionary, ...d};
    },
    setTask(state, t) {
        Vue.set(state.dictionary, t.id, t);
    },
    setIn(state, ins) {
        state.in = ins;
    },
    setMy(state, mys) {
        state.my = mys;
    },
    // mergeMy(state, mys) {
    //     state.my = [...state.my, ...mys];
    // },
    // mergeIn(state, mys) {
    //     state.in = [...state.in, ...mys];
    // },
    // mergeOut(state, mys) {
    //     state.out = [...state.out, ...mys];
    // },
    //
    setOut(state, outs) {
        state.out = outs;
    },
    setCc(state, ccs) {
        state.cc = ccs;
    },
    setArchived(state, archived) {
        state.archived = archived;
    },
    addOrReplaceIn(state, t_id) {
        const idx = state.in.indexOf(t_id);
        if (idx === -1) {
            state.in.unshift(t_id);
        }
    },
    addOrReplaceMy(state, t_id) {
        const idx = state.my.indexOf(t_id);
        if (idx === -1) {
            state.my.unshift(t_id);
        }
    },
    addOrReplaceOut(state, t_id) {
        const idx = state.out.indexOf(t_id);
        if (idx === -1) {
            state.out.unshift(t_id);
        }
    },
    addOrReplaceCc(state, t_id) {
        const idx = state.cc.indexOf(t_id);
        if (idx === -1) {
            state.cc.unshift(t_id);
        }
    },
    addOrReplaceArchived(state, t_id) {
        const idx = state.archived.indexOf(t_id);
        if (idx === -1) {
            state.archived.unshift(t_id);
        }
    },
    removeIn(state, t_id) {
        if (state.in.indexOf(t_id) > -1) {
            state.in.splice(state.in.indexOf(t_id), 1);
        }
    },
    removeMy(state, t_id) {
        if (state.my.indexOf(t_id) > -1) {
            state.my.splice(state.my.indexOf(t_id), 1);
        }
    },
    removeOut(state, t_id) {
        if (state.out.indexOf(t_id) > -1) {
            state.out.splice(state.out.indexOf(t_id), 1);
        }
    },
    removeCc(state, t_id) {
        if (state.cc.indexOf(t_id) > -1) {
            state.cc.splice(state.cc.indexOf(t_id), 1);
        }
    },
    removeArchived(state, t_id) {
        if (state.archived.indexOf(t_id) > -1) {
            state.archived.splice(state.archived.indexOf(t_id), 1);
        }
    },
    deleteById(state, t_id) {

        if (state.in.indexOf(t_id) > -1) {
            state.in.splice(state.in.indexOf(t_id), 1);
        }
        if (state.my.indexOf(t_id) > -1) {
            state.my.splice(state.my.indexOf(t_id), 1);
        }
        if (state.out.indexOf(t_id) > -1) {
            state.out.splice(state.out.indexOf(t_id), 1);
        }
        if (state.cc.indexOf(t_id) > -1) {
            state.cc.splice(state.cc.indexOf(t_id), 1);
        }
        if (state.archived.indexOf(t_id) > -1) {
            state.archived.splice(state.archived.indexOf(t_id), 1);
        }
        //lo devo anche togliere dal padre
        //guardo se ha il padre e il padre è caricato
        if (state.dictionary[t_id] && state.dictionary[t_id].parent && state.dictionary[state.dictionary[t_id].parent as any]) {
            const children = state.dictionary[state.dictionary[t_id].parent as any].children;
            if (children.indexOf(t_id) > -1) {
                children.splice(children.indexOf(t_id), 1);
            }

        }
        delete state.dictionary[t_id];
    },
    setMessagesToRead(state, {to_read, id}) {
        if (state.dictionary[id]) {
            state.dictionary[id].messages_to_read = to_read;
        }
    }
};


export default {
    namespaced,
    state: taskState,
    getters,
    actions,
    mutations,
};
