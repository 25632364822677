import { render, staticRenderFns } from "./RejectForm.vue?vue&type=template&id=04578c98&scoped=true&"
import script from "./RejectForm.vue?vue&type=script&lang=ts&"
export * from "./RejectForm.vue?vue&type=script&lang=ts&"
import style0 from "./RejectForm.vue?vue&type=style&index=0&id=04578c98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04578c98",
  null
  
)

export default component.exports