



























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Editor, EditorContent} from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';
import {Attachment} from "@/datastore/entities/Attachment";
import AttachmentBox from "@/components/SidePanel/AttachmentBox.vue";

@Component({
    components: {EditorContent,AttachmentBox},
    name: "Vue"
})
export default class Chat extends Vue {
    @Prop(Date) private readonly date!: Date;
    @Prop(String) private readonly text!: string;
    @Prop(Number) private readonly userId!: number;
    @Prop(String) private readonly avatar!: string;
    @Prop(Array) private readonly attachments!: Attachment[];
    @Prop(Boolean) private readonly is_new!: boolean;
    @Prop(Boolean) private readonly readonly!: boolean;

    private editor = new Editor({
        extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({levels: [1, 2, 3]}),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem({
                nested: true,
            }),
            new TodoList(),
            new Bold(),
            new Code(),
            new Italic(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
        ],
        content: this.is_new ? '' : this.text,
        editable: !this.readonly,
    });

    get isTextEmpty() {
        return this.editor.getHTML() === '<p></p>'
    }

    get isAttachmentEmpty() {
        return !this.attachments.length
    }

    get noContent(){
        return this.isAttachmentEmpty && this.isTextEmpty
    }

    get is_own() {
        //return Math.round(Math.random())
        return (this.userId == this.$store.getters['account/user'].id);
    }

    public beforeDestroy() {
        this.editor.destroy();
    }
}
