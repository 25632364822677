




import {Vue} from 'vue-property-decorator';
import {Mixin} from 'vue-mixin-decorator';

@Mixin
export default class PermissionMixin extends Vue {

    get user() {
        return this.$store.getters['account/user'];
    }

    get activeTask() {
        return this.$store.getters['modal/activeTask'];
    }

    get canBeEdited() {
        return (this.activeTask && !this.activeTask.isRemoved && this.activeTask.permission >= 2 && !this.activeTask.hasPendingTransfer);
    }

    get canEditTags(){
        return [this.activeTask.recipient.id].includes(this.user && this.user.id) &&
            this.activeTask.isApproved
    }

    get canBeReaded() {
        return (this.activeTask && this.activeTask.permission == 1);
    }

    get canBeTopiced() {
        return (this.activeTask && !this.activeTask.isRemoved);
    }

    get canBeDeleted() {
        return (this.activeTask && !this.activeTask.isRemoved && this.activeTask.permission == 3);
    }

    get canBeTransfered() {
        return [this.activeTask.owner.id, this.activeTask.recipient.id].includes(this.user && this.user.id) &&
            this.activeTask.isApproved && !this.activeTask.isRemoved && !this.activeTask.archived
            && this.activeTask.children.length == 0 && !this.activeTask.hasPendingTransfer && !this.$store.getters['account/isPublic'];
    }

    get canDeleteTransfer(){
        return [this.activeTask.owner.id].includes(this.user && this.user.id) && this.activeTask.hasPendingTransfer
    }

    get canSubTask() {
        return [this.activeTask.recipient.id].includes(this.user && this.user.id)
            && this.activeTask.isApproved && !this.activeTask.isRemoved &&
            !this.activeTask.archived && !this.activeTask.hasPendingTransfer && !this.$store.getters['account/isPublic'];
    }

    get canChangeStatus() {
        return [this.activeTask.owner.id, this.activeTask.recipient.id].includes(this.user && this.user.id)
            && !this.activeTask.isPending && ! this.activeTask.isRejected && !this.activeTask.isRemoved &&
            !this.activeTask.archived && !this.activeTask.hasPendingTransfer;
    }

    get canBeArchived() {
        //this.user && this.user.id va aggiunto perche' se carico un task pubblico il modal cerca l'utente ma potrebbe non essere ancora essere settato
        //alternativamente si puo risolvere settando un utente finto nella before create del componente Public.vue
        return this.activeTask.isCompleted && !this.activeTask.isRemoved &&
            !this.activeTask.archived;
    }

    get canBeUnArchived() {
        //this.user && this.user.id va aggiunto perche' se carico un task pubblico il modal cerca l'utente ma potrebbe non essere ancora essere settato
        //alternativamente si puo risolvere settando un utente finto nella before create del componente Public.vue
        return this.activeTask.isCompleted && !this.activeTask.isRemoved &&
            this.activeTask.archived;
    }

}
