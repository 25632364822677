import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {Dashboard} from '@/datastore/entities/Dashboard';
import {IDashboardSchema} from '@/datastore/schemas/DashboardSchema';

export interface IDashboardManager {
    dashboards: () => Promise<Dashboard[]>;
    fetch_by_id: (id:number) =>Promise<IDashboardSchema>;
    star: (id: number) => Promise<IDashboardSchema>;
    unstar: (id: number) => Promise<IDashboardSchema>;
    new_dashboard: (title:string, users:string[], tags: Array<number | string>, color:string) => Promise<IDashboardSchema>;
    update_dashboard: (id:number, title?:string, users?:string[], tags?: Array<number | string>, color?:string) => Promise<IDashboardSchema>;
    delete: (id: number) => Promise<IDashboardSchema>
}

export class DashboardManager implements IDashboardManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public async dashboards(): Promise<Dashboard[]> {
        return (await this._httpClient.get<IDashboardSchema[]>('dashboards/')).map((d) => {
            return new Dashboard(d);
        });
    }
    public fetch_by_id (id:number): Promise<IDashboardSchema> {
        return this._httpClient.get<IDashboardSchema>('dashboards/' + id + '/');
    }
    public star(id: number): Promise<IDashboardSchema> {
        return this._httpClient.patch<IDashboardSchema>('dashboards/' + id + '/', {starred: true});
    }
    public unstar(id: number): Promise<IDashboardSchema> {
        return this._httpClient.patch<IDashboardSchema>('dashboards/' + id + '/', {starred: false});
    }

    public new_dashboard(title:string, users:string[], tags: Array<number | string>, color:string) : Promise<IDashboardSchema>{
        return this._httpClient.post<IDashboardSchema>('dashboards/', {
            title, users, tags, color
        });
    }

    public update_dashboard(id:number, title?:string, users?:string[], tags?: Array<number | string>, color?:string) : Promise<IDashboardSchema>{
        return this._httpClient.patch<IDashboardSchema>('dashboards/' + id + '/', {
            title, users, tags, color
        });
    }

    public delete(id:number): Promise<IDashboardSchema>{
        return this._httpClient.delete<IDashboardSchema>('dashboards/' + id +'/');
    }
}

export const dashboardManager: DashboardManager = new DashboardManager(httpClient);
