








































import {Component, Vue, Prop} from 'vue-property-decorator';
import Box from '@/components/Box.vue';
import DashboardBoxContainer from '@/components/DashboardBoxContainer.vue';
import SearchBar from '@/components/SearchBar.vue';
import PerfectScrollbar from "perfect-scrollbar";
import {MenuRoutes} from "@/routes";

@Component({
    components: {
        Box, DashboardBoxContainer, SearchBar,
    },
    name: "Dashboard"
})
export default class Dashboard extends Vue {
    private ps!: PerfectScrollbar;
    private routes = MenuRoutes;

    public mounted() {
        this.createOrUpdateScroll();
    }

    public updated() {
        this.createOrUpdateScroll();
    }

    get statistics() {
        return this.$store.getters['account/userTaskStatistics'];
    }

    private beforeDestroy() {
        this.ps.destroy()
    }

    private createOrUpdateScroll() {
        if (this.ps) {
            this.ps.update()
        } else {
            this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
                suppressScrollX: true
            });
        }
    }

    public created() {
        this.$store.dispatch('account/fetchStatistics');
        this.$store.dispatch('dashboard/fetchDashboards')
    }
}
