import {ETransferStatus, ITransferSchema} from "@/datastore/schemas/TransferSchema";

export class Transfer {
    public readonly id: number;
    public readonly status: ETransferStatus;
    public readonly request_by: number;
    public readonly new_recipient: number;

    constructor();
    constructor(data: ITransferSchema);
    constructor(data?: any) {
        this.id = data && data.id || -1;
        this.status = data && data.status;
        this.request_by = data && data.request_by;
        this.new_recipient = data && data.new_recipient;
    }

}
