














import {Component, Prop, Vue} from 'vue-property-decorator';
import PerfectScrollbar from "perfect-scrollbar";
import {EventBus} from "@/EventBus";
import * as jQuery from 'jquery';

@Component({
    components: {},
    name: "PanelContentTemplate",
})
export default class PanelContentTemplate extends Vue {
    @Prop(Boolean) private readonly scrollEnd!: boolean;
    private ps: any = {};
    private is_to_end: boolean = false;
    private timeout: number = -1;

    mounted() {
        this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
            suppressScrollX: true,
        });
        //@ts-ignore
        this.$refs.scroll.addEventListener('ps-y-reach-end', this.reachEnd);
        //@ts-ignore
        this.$refs.scroll.addEventListener('ps-scroll-up', this.scrollTop);
    }

    scrollTop() {
        this.is_to_end = false;
    }

    reachEnd() {
        this.is_to_end = true;
    }

    updated() {
        (this.ps as PerfectScrollbar).update();
        if (this.scrollEnd && this.is_to_end) {
            const sc = this.$refs.scroll as HTMLElement;
            window.clearTimeout(this.timeout);
            this.timeout = window.setTimeout(function () {
                jQuery(sc).animate({ scrollTop: 99999 }, 600);
            }, 200)
        }
    }
}
