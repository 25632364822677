




























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {NewTaskFormFieldError} from '@/store/types';
import {EditorContent} from 'tiptap';
import {ITaskNS} from '@/datastore/schemas/TaskSchema';
import IMOEditor from '@/components/IMOEditor.vue';
import {IMOeditorDataSchema} from '@/datastore/schemas/IMOeditorDataSchema';
import UserSelect from '@/components/UserSelect.vue';
import {MultiselectUserSchema} from '@/datastore/schemas/UserSchema';
import {MultiselectTagSchema} from '@/datastore/schemas/TagSchema';
import TagSelect from '@/components/TagSelect.vue';
import {Attachment} from '@/datastore/entities/Attachment';
import {EnvironmentHelper} from '@/EnvironmetHelper';

@Component({
    components: {IMOEditor, EditorContent, UserSelect, TagSelect},
    name: 'TaskForm'
})
export default class TaskForm extends Vue {
    @Prop(String) private readonly label!: string;
    @Prop(String) private readonly email!: string;
    @Prop(Date) private readonly dueDate!: Date;
    @Prop(String) private readonly title!: string;
    @Prop(String) private readonly content!: string;
    @Prop(Array) private readonly attachments!: Attachment[];
    @Prop(Object) private readonly error!: NewTaskFormFieldError;
    @Prop(Boolean) private readonly support!: boolean;

    private task_due_date: Date | null = this.dueDate || null;
    private task_title: string = this.title || '';
    private imoData!: IMOeditorDataSchema;
    private ccs: MultiselectUserSchema[] = [];
    private recipient: MultiselectUserSchema = {name: '', email: ''};
    private tags: MultiselectTagSchema[] = [];

    private submit(el: IMOeditorDataSchema) {
        this.imoData = el;
        (this.$refs.submit as HTMLButtonElement).click();
    }

    private handleSend() {
        const d = {
            description: this.imoData.content,
            recipient: this.recipient.email,
            due_date: this.task_due_date ? this.task_due_date.toISOString() : null,
            title: this.task_title,
            tags: this.tags.map((t) => {
                return t.title;
            }),
            attachments: this.imoData.attachments,
            cc: this.ccs.map((u) => {
                return u.email;
            }),
        } as ITaskNS;
        if (this.support) {
            d.recipient = EnvironmentHelper.supportEmail
            d.due_date = null
            d.tags = []
            d.cc = []
        }
        this.$emit('onSend', d);
    }

    private mounted() {
        window.setTimeout(() => {
            ((this.$refs.object as Vue).$el as HTMLElement).focus();
        }, 100);
    }
}
