import {EEventType, IActivitySchema} from "@/datastore/schemas/ActivitySchema";
import {User} from "@/datastore/entities/User";
import {ETaskStatus} from "@/datastore/schemas/TaskSchema";

export class Activity {
    public readonly id: number;
    public readonly event_type: EEventType;
    public readonly user: User;
    public readonly task: number;
    public readonly task__code: string;
    public readonly payload: string;
    public readonly task_due_date: Date | null;
    public readonly task_status: ETaskStatus;
    public readonly created: Date;
    public readonly modified: Date;
    public readonly message: string;
    public readonly transfer: number;


    constructor();
    constructor(data: IActivitySchema);
    constructor(data?: any) {
        this.id = data && data.id || 0;
        this.event_type = data && data.event_type || EEventType.U;
        this.user = new User(data && data.user);
        this.task = data && data.task || -1;
        this.task__code = data && data.task__code || this.task.toString();
        this.payload = data && data.payload || '';
        this.task_due_date = data && data.task_due_date ? new Date(data.task_due_date) : null;
        this.task_status = data && data.task_status || ETaskStatus.unknown;
        this.created = new Date(data && data.created || 0);
        this.modified = new Date(data && data.modified || 0);
        this.message = data && data.message || '';
        this.transfer = data && data.transfer || -1;
    }
}
