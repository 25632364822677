import {IUserSchema} from "@/datastore/schemas/UserSchema";
import {IAttachmentSchema} from "@/datastore/schemas/AttachmentSchema";
import {IActivitySchema} from "@/datastore/schemas/ActivitySchema";


export enum EMessagePageType {
    TaskTopic = 'TaskTopic',
    TaskMessage = 'TaskMessage'
}


export interface IGenericPageSchema {
    id: number;
    owner: IUserSchema;
    content: string;
    attachments: IAttachmentSchema[];
    _task: number;
    created: string;
    type: EMessagePageType;
    task_topic?: number;
}


export interface IMessagePaginatedResponseSchema {
    count: number,
    next: string | null,
    previous: string | null,
    results: IGenericPageSchema[]
}
