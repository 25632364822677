import {ITopicSchema} from '@/datastore/schemas/TopicSchema';
import {Attachment} from '@/datastore/entities/Attachment';
import {IAttachmentSchema} from '@/datastore/schemas/AttachmentSchema';
import {taskMessageManager} from "@/datastore/managers/TaskMessageManager";
import {User} from '@/datastore/entities/User';

export class Topic {

    public readonly id: number;
    public readonly user: User;
    public readonly content: string;
    public readonly attachments: Attachment[];
    public readonly messageCount: number;
    public readonly created: Date;
    public readonly modified: Date;
    public readonly task: number;
    public messages_to_read: number;
    public is_read: boolean;

    constructor();
    constructor(data: ITopicSchema);
    constructor(data?: any) {
        this.id = data && data.id || -1;
        this.user = new User(data && data.owner);
        this.content = data && data.content || '';
        this.attachments = ((data && data.attachments || []) as IAttachmentSchema[]).map((ad) => {
            return new Attachment(ad);
        });
        this.messageCount = data && data.message_count || 0;
        this.created = new Date(data && data.created || 0);
        this.modified = new Date(data && data.modified || 0);
        this.task = data && data.task || -1;
        this.messages_to_read = data && data.messages_to_read || 0;
        this.is_read = data && data.is_read;
    }

    public static postMessage(topicId: number, msg: string, attacchments: number[]) {
        return taskMessageManager.post_topic_message(topicId, msg, attacchments)
    }

}
