import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {
    IMessagePaginatedResponseSchema,
} from '@/datastore/schemas/MessagesPageSchema';
import {IPaginationRequestSchema} from "@/datastore/schemas/TaskSchema";
import * as qs from "qs";
import {GenericPageMessage} from '@/datastore/entities/PageMessage';

export interface IMessagesPageManager {
    messages: (pagination: IPaginationRequestSchema, ordering?: string[]) => Promise<IMessagePaginatedResponseSchema>;
    message: (messageId: number) => Promise<GenericPageMessage[]>
}

export class MessagesManager implements IMessagesPageManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public messages(pagination: IPaginationRequestSchema, ordering?: string[]): Promise<IMessagePaginatedResponseSchema> {
        return this._httpClient.get<IMessagePaginatedResponseSchema>('messages/', {
            params: {page: null, page_size: null, ordering: []},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode: false
                    });
            }
        })
    }

    public message(messageId: number): Promise<GenericPageMessage[]> {
        return this._httpClient.get<GenericPageMessage[]>('messages/' + messageId + '/')
    }
}

export const taskMessageManager: MessagesManager = new MessagesManager(httpClient);
