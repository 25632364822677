















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ITableConfig} from "@/store/types";
import {Task} from '@/datastore/entities/Task';

@Component({
    components: {},
    name: "SimpleRow"
})
export default class SimpleRow extends Vue {
    @Prop(Array) private readonly header!: string[];
    @Prop() private readonly bind!: any;

    styleBuilder(h: ITableConfig) {
        return {
            flex: '0 0 ' + h.width + '%'
        }
    }
}
