


























import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import {Editor, EditorContent} from 'tiptap';
import {
    Bold,
    Italic,
} from 'tiptap-extensions';

@Component({
    components: {EditorContent},
    computed: {
        ...mapGetters({
            activeTask: 'modal/activeTask',
        })
    },
    name: "Log",
})
export default class Log extends Vue {
    @Prop(String) private readonly content!:string;
    @Prop(Date) private readonly date!:Date;
    @Prop(String) private readonly avatar!:string;

    private editor = new Editor({
        extensions: [
            new Bold(),
            new Italic(),
        ],
        content: this.content,
        editable: false,
    });

}
