import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import BackOffice from './views/BackOffice.vue';
import Dashboard from './views/backoffice/Dashboard.vue';
import Menu from './views/backoffice/Menu.vue';
import NewPublicTaskForm from './views/home/NewPublicTaskForm.vue';
import signInForm from './views/home/SignInForm.vue';
import signUpForm from './views/home/SignUpForm.vue';
import TaskDetails from './views/backoffice/TaskDetails.vue';
import Message from '@/views/backoffice/Message.vue';
import Activities from '@/views/backoffice/Activities.vue';
import store from './store/index';
import {
    Home as home_paths,
    isPublic,
    isPrivate
} from './routes';
import Public from '@/views/Public.vue';
import EmailConfirm from '@/views/EmailConfirm.vue';
import dashboard from '@/store/modules/dashboard';
import ResetPassword from '@/views/home/ResetPassword.vue';
import ResetPwdConfirm from '@/views/home/ResetPwdConfirm.vue';
import ConfirmEmail from "@/views/home/ConfirmEmail.vue";
import Settings from "@/views/backoffice/Settings.vue";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/auth/:provider/callback',
            name: 'oauth',
            component: {
                template: `<div></div>`
            }
        },
        {
            path: '/public/:token',
            name: 'publicTask',
            component: Public,
            props: true,
        },
        {
            path: '/confirm/:qkey',
            name: 'emailConfirm',
            component: EmailConfirm,
            props: true,
        },
        {
            path: '/',
            component: Home,
            redirect: 'newtask',
            props: true,
            children: [{
                path: 'signin',
                name: 'signin',
                components: {
                    form: signInForm,
                },
            }, {
                path: 'signup',
                name: 'signup',
                components: {
                    form: signUpForm,
                },
            }, {
                path: 'features',
                name: 'features',
                components: {

                },
            }, {
                path: 'newtask',
                name: 'newtask',
                components: {
                    form: NewPublicTaskForm,
                },
            }, {
                path: 'resetpwd',
                name: 'resetPwd',
                components: {
                    form: ResetPassword,
                },
            }, {
                path: 'confirmemail',
                name: 'sendEmailConfirm',
                components: {
                    form: ConfirmEmail,
                },
            }, {
                path: 'resetpasswordconfirm/:uid/:token',
                name: 'resetPwdConfirm',
                props: {form: true},
                components: {
                    form: ResetPwdConfirm,
                },
            }],
        },
        {
            path: '/backoffice/',
            name: 'BackOffice',
            component: BackOffice,
            children: [{
                path: 'dashboard',
                name: 'dashboard',
                components: {
                    default: Dashboard,
                    menu: Menu,
                },
            }, {
                path: 'details/:type',
                name: 'details',
                components: {
                    default: TaskDetails,
                    menu: Menu,
                },
                props: {default: true, menu: true},
            }, {
                path: 'cc',
                name: 'cc',
                components: {
                    default: TaskDetails,
                    menu: Menu,
                },
                props: {default: {type: 'cc'}, menu: {type: 'cc'}},
            }, {
                path: 'archived',
                name: 'archived',
                components: {
                    default: TaskDetails,
                    menu: Menu,
                },
                props: {default: {type: 'archived'}, menu: {type: 'archived'}},
            }, {
                path: 'messages',
                name: 'messages',
                components: {
                    default: Message,
                    menu: Menu,
                },
            }, {
                path: 'activities',
                name: 'activities',
                components: {
                    default: Activities,
                    menu: Menu,
                },
            }, {
                path: 'settings',
                name: 'settings',
                components: {
                    default: Settings,
                    menu: Menu,
                },
            }],
        },
    ],
});

/**
 * controllo che l'utente sia loggato prima di poter andare sulla dashboard
 */
router.beforeEach((to, from, next) => {
    if (isPublic(to.name as string)) {
        next();
    } else if (to.name === 'signin' && store.getters['account/isAuthenticated']) {
        next({name: 'dashboard'});
    } else {
        if (isPrivate(to.name as string)) {
            if (!store.getters['account/isAuthenticated']) {
                next({name: 'newtask'});
            } else {
                next();
            }
        } else {
            //mi serve per gestire il caso in cui vada su url che non esiste ed evitare di vedere la pagina bianca QUANDO NON SONO LOGGATO, in tal caso viene gestito dalla init di vue
            if(to.matched.length){
                next();
            } else {
                //in ultima istanza reindirizza alla home
                router.push(home_paths.newtask.to);
            }
        }
    }
});
