









































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Task} from '@/datastore/entities/Task';
import {ITableConfig} from '@/store/types';


@Component({
    components: {},
    name: 'GridRow'
})
export default class GridRow extends Vue {
    @Prop(Array) private readonly header!: string[];
    @Prop(Task) private readonly task!: Task;
    @Prop(Number) private readonly deep!: number;
    @Prop(Boolean) private readonly expanded!: boolean;

    get getChildren() {
        return this.$store.getters['task/children'](this.task.id);
    }

    private get margin() {
        return {
            marginLeft: this.deep * 10 + 'px'
        };
    }

    styleBuilder(index: number, h: ITableConfig) {
        return {
            flex: '0 0 ' + h.width + '%'
        };
    }

    private isActiveTask(id: number) {
        return this.$store.getters['modal/activeTask'].id == id;
    }

    private getColor(t: Task) {

        if (t.isCompleted) {
            return 'task_completed';
        } else if (t.hasPendingTransfer) {
            return 'task_transfer';
        } else if (t.isPending) {
            return 'task_transfer';
        } else {
            return 'task_uncompleted';
        }
    }

    private getExpand(id: number) {
        return this.$store.getters['modal/isExpanded'](id);
    }

}
