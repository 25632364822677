import {IDashboardSchema} from '@/datastore/schemas/DashboardSchema';
import {User} from '@/datastore/entities/User';
import {Tag} from '@/datastore/entities/Tag';
import {ITaskSchema} from '@/datastore/schemas/TaskSchema';
import {IUserSchema} from '@/datastore/schemas/UserSchema';
import {dashboardManager} from "@/datastore/managers/DashboardManager";

export class Dashboard {

    public readonly id: number;
    public readonly color: string;
    public readonly title: string;
    public readonly users: User[];
    public readonly tags: Tag[];
    public readonly created: Date;
    public readonly modified: Date;
    public readonly tasks_counter: number;
    public readonly expired_tasks_counter: number;
    public readonly warning_tasks_counter: number;
    public readonly starred: boolean;
    public readonly owner: User;

    constructor();
    constructor(data: IDashboardSchema);
    constructor(data?: any) {
        this.id = data && data.id || -1;
        this.color = data && data.color || '';
        this.title = data && data.title || '';
        this.users = ((data && data.users || []) as IUserSchema[]).map((ud) => {
            return new User(ud);
        });
        this.tags = ((data && data.tags || []) as ITaskSchema[]).map((td) => {
            return new Tag(td);
        });
        this.created = new Date(data && data.created || 0);
        this.modified = new Date(data && data.modified || 0);
        this.tasks_counter = data && data.tasks_counter || 0;
        this.expired_tasks_counter = data && data.expired_tasks_counter || 0;
        this.warning_tasks_counter = data && data.warning_tasks_counter || 0;
        this.starred = data && data.starred || false;
        this.owner = new User(data && data.owner);
    }

    static star(id:number){
        return dashboardManager.star(id)
    }

    static unstar(id:number):Promise<IDashboardSchema>{
        return dashboardManager.unstar(id)
    }

    public star(){
        return dashboardManager.star(this.id)
    }

    public unstar(){
        return dashboardManager.unstar(this.id)
    }
}
