

















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {EditorMenuBar} from 'tiptap';

@Component({
    components: {EditorMenuBar},
    name: "EditorButtons"
})
export default class EditorButtons extends Vue {
    @Prop(Object) private readonly editor!: object;

}
