

































import {Component, Vue} from 'vue-property-decorator';
import {Home} from '@/routes.ts';
import {userManager} from "@/datastore/managers/UserManager";

@Component({
    components: {},
    name: "ConfirmEmail"
})
export default class ConfirmEmail extends Vue {
    private email: string = '';
    private: string = '';
    private error: string = '';
    private showForm: boolean = true;
    private disable: boolean = false;
    private message: string = '';


    public reset() {
        this.error = '';
        this.disable = true;
        userManager.sendRegistrationConfirmationEmail(this.email).then(() => {
            this.message = `We have sent you a email, check your box and follow the instructions`;
            this.showForm = false;
        }).catch(() => {
            this.error = `An error occurred, try again later`;
        }).finally(()=>{
            this.disable = false;
        })
    }
}
