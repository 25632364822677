










































import {Component, Prop, Vue} from 'vue-property-decorator';
import UploadElement from "@/components/UploadElement.vue";
import {IAttachmentSchema, IFileUploadSchema} from "@/datastore/schemas/AttachmentSchema";
import {attachmentsManager} from "@/datastore/managers/AttachmentsManager";
import {Attachment} from "@/datastore/entities/Attachment";
import {EnvironmentHelper} from "@/EnvironmetHelper";
import {mapGetters} from "vuex";
import {User} from "@/datastore/entities/User";

@Component({
  components: {UploadElement},
  name: "EditorFileUpload",
  computed: {
    ...mapGetters({user: 'account/user'}),
  },
})
export default class EditorFileUpload extends Vue {

  @Prop(Boolean) private readonly buttonVisibility!: boolean;
  @Prop(String) private readonly uploadClass!: string;
  @Prop(Array) private readonly defaultAttachments!: Attachment[];

  private filesToUpload: IFileUploadSchema[] = [];
  private layerVisibility: boolean = false;
  private requestStatus: Promise<any>[] = [];
  private alertUploadSize: number = 0;

  user?: User;

  beforeMount() {
    if (this.defaultAttachments) {

      this.defaultAttachments.forEach((d) => {
        this.filesToUpload.push({
          file: null,
          id: d.id,
          success: true,
          name: d.name,
          error: '',
        })
      })
      this.requestStatus = this.filesToUpload.map((a) => {
        return new Promise<any>((resolve, reject) => {
          resolve(a.id)
        })
      })
      this.$emit('onFileUploadAdd', this.requestStatus);
    }
  }

  mounted() {
    document.addEventListener('dragenter', (e: DragEvent) => {
      e.preventDefault();
      this.layerVisibility = true;
      return false
    }, false);
    document.addEventListener('dragleave', (e: DragEvent) => {
      e.preventDefault();
      return false
    }, false);
    document.addEventListener('drop', (e: DragEvent) => {
      this.layerVisibility = false;
      e.preventDefault();
      return false
    }, false);
    //@ts-ignore
    this.$refs.drag.addEventListener('dragover', this.onDragover, false);
    //@ts-ignore
    this.$refs.drag.addEventListener('drop', this.onDrop, false);
    //@ts-ignore
    this.$refs.drag.addEventListener('dragleave', (e: DragEvent) => {
      this.layerVisibility = false;
      e.preventDefault();
      return false
    }, false)
  }

  public removeUpload() {
    this.filesToUpload.splice(0, this.filesToUpload.length);
    this.requestStatus = [];
  }

  public onDragover(ev: any) {
    ev.preventDefault();
  }

  public openFileManager() {
    (this.$refs.file as HTMLInputElement).click();
  }

  public append(f: File) {
    if ((this.user && this.user.premium) || f.size <= parseInt(EnvironmentHelper.premiumUploadSize))
      this.filesToUpload.push({
        file: f,
        id: -1,
        success: null,
        name: f.name,
        error: '',
      })
    else {
      this.alertUploadSize = 5;
    }
  }

  public send() {
    let formData: FormData;
    let a = 0;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      //quando è null vuol dire che non è mai stato tentato un upload
      //true andato a buon fine
      //false fallito
      if (this.filesToUpload[i].success === null) {
        formData = new FormData();
        formData.append('file', this.filesToUpload[i].file || '');

        this.requestStatus.push(
            new Promise<any>((resolve, reject) => {
              this.sendAJAX(formData).then((e: any) => {
                this.filesToUpload[i].success = true;
                this.filesToUpload[i].id = e.id;
                resolve(e.id);
              }).catch((e: Error) => {
                this.filesToUpload[i].success = false;
                this.filesToUpload[i].error = e.message;
                resolve(undefined);
              })
            })
        );
        this.$emit('onFileUploadAdd', this.requestStatus);
      }
    }
  }

  private onDrop(ev: any) {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          if (file) {
            this.append(file);
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        this.append(ev.dataTransfer.files[i]);
      }
    }
    this.send();
  }

  private handleFileUpload() {
    const fl = (this.$refs.file as HTMLInputElement).files as FileList;
    for (let i = 0; i < fl.length; i++) {
      this.append(fl[i]);
    }
    this.send();
  }

  private sendAJAX(formData: FormData): Promise<IAttachmentSchema> {
    return attachmentsManager.attachment(formData);
  }

  private remove(index: number) {
    this.filesToUpload.splice(index, 1);
    this.requestStatus.splice(index, 1);
    this.$emit('onFileUploadAdd', this.requestStatus);
  }
}
