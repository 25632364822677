
import {
    EMessagePageType,
    IGenericPageSchema,
} from "@/datastore/schemas/MessagesPageSchema";


export class GenericPageMessage {
    private readonly id: number;
    private readonly owner: string;
    private readonly content: string;
    private readonly created: Date;
    public readonly task: number;
    private readonly taskCode: string;
    public readonly type: EMessagePageType;
    public readonly topic?: number;
    public is_read: boolean;

    constructor();
    constructor(data: IGenericPageSchema);
    constructor(data?: any) {
        this.id = data && data.id || -1;
        this.owner = data && data.owner && data.owner.email || '';
        this.content = data && data.content || '';
        this.created = new Date(data && data.created || 0);
        this.task = data && data._task || -1;
        this.taskCode = data && data.task__code || this.task.toString();
        this.type = data && data.type || EMessagePageType.TaskTopic;
        this.topic = data && data.topic;
        this.is_read = data && data.is_read;
    }
}

