import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {IMessageSchema} from '@/datastore/schemas/MessageSchema';
import {Message} from '@/datastore/entities/Message';
import {Attachment} from "@/datastore/entities/Attachment";

export interface IMessageManager {
    fetch_messages_by_topic_id: (topic_id: number) => Promise<Message[]>;
    post_topic_message: (task_id:number, content:string, set_attachments:number[]) => Promise<IMessageSchema>
    message_by_id_and_topic_id : (topicId: number, messageId: number) => Promise<IMessageSchema>
}

export class TaskMessageManager implements IMessageManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public async fetch_messages_by_topic_id(topic_id: number): Promise<Message[]> {
        const response =  await this._httpClient.get<IMessageSchema[]>('task_messages/' + topic_id + '/');
        return response.map((td) => {
            return new Message(td);
        });
    }
    public message_by_id_and_topic_id(topicId: number, messageId: number): Promise<IMessageSchema> {
        return this._httpClient.get<IMessageSchema>('task_messages/' + topicId + '/' + messageId);

    }

    public post_topic_message(topic_id:number, content:string, set_attachments:number[]):Promise<IMessageSchema>{
        return this._httpClient.post<IMessageSchema>('task_messages/' + topic_id + '/', {content,set_attachments})
    }
}

export const taskMessageManager: TaskMessageManager = new TaskMessageManager(httpClient);
