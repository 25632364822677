import {ITagSchema} from '@/datastore/schemas/TagSchema';

export class Tag {

    public readonly id: number;
    public readonly title: string;

    constructor(data: ITagSchema) {
        this.id = data.id;
        this.title = data.title;
    }

    public get MultiselectItem(){
        return {
            title:this.title
        }
    }
}
