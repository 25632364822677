import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {IActivityPaginatedResponseSchema, IActivitySchema} from "@/datastore/schemas/ActivitySchema";
import {Activity} from "@/datastore/entities/Activity";
import {IPaginationRequestSchema} from "@/datastore/schemas/TaskSchema";
import * as qs from "qs";

export interface IActivityManager {
    logs: (pagination: IPaginationRequestSchema, ordering?: string[]) => Promise<IActivityPaginatedResponseSchema>;
    log_by_id: (id: number) => Promise<IActivitySchema>;
    task_logs: (task_id: number) => Promise<Activity[]>;
    task_logs_by_id: (task_id: number, log_id: number) => Promise<IActivitySchema[]>;
}

export class MessagesManager implements IActivityManager {
    constructor(private _httpClient: IHttpClient) {
    }

    public logs(pagination: IPaginationRequestSchema, ordering?: string[]): Promise<IActivityPaginatedResponseSchema> {
        return this._httpClient.get<IActivityPaginatedResponseSchema>('events/', {
            params: {page: null, page_size: null,ordering:[]},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode:false
                    });
            }
        })
    }

    public log_by_id(id: number): Promise<IActivitySchema> {
        return this._httpClient.get<IActivitySchema>('events/' + id + '/')
    }

    public async task_logs(task_id: number): Promise<Activity[]> {
        const response =  await this._httpClient.get<IActivitySchema[]>('task_events/' + task_id + '/');
        return response.map((l) => {
            return new Activity(l);
        });
    }

    public task_logs_by_id(task_id: number, log_id: number): Promise<IActivitySchema[]> {
        return this._httpClient.get<IActivitySchema[]>('task_events/' + task_id + '/' + log_id + '/')
    }

}

export const activityManager: IActivityManager = new MessagesManager(httpClient);
