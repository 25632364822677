


























import {Component, Vue} from 'vue-property-decorator';
import {ETaskStatus, ITaskSchema} from "@/datastore/schemas/TaskSchema";
import {dashboardManager} from "@/datastore/managers/DashboardManager";
import {EventBus} from "@/EventBus";
import {attachmentsManager} from "@/datastore/managers/AttachmentsManager";

@Component({
    components: {},
    name: "DeleteAttachmentModal"
})
export default class DeleteAttachmentModal extends Vue {

    get active_modal_task() {
        return this.$store.getters['modal/activeTask'];
    }

    public handleYes() {
        attachmentsManager.delete_attachment(this.active_modal_task.id, this.$store.getters['modal/attachmentToDelete']).then(() => {
        })
        this.$bvModal.hide('DeleteAttachment');
    }

    public handleNo() {
        this.$bvModal.hide('DeleteAttachment');
    }
}
