import {ETaskStatus, ETaskType} from "@/datastore/schemas/TaskSchema";

export enum WSMessagesType {
    "taskCreated" = "task_created",
    "taskUpdated" = "task_updated",
    "taskDeleted" = "task_deleted",
    "taskTopicCreated" = "task_topic_created",
    "taskMessageCreated" = "task_message_created",
    "transferCreated" = "transfer_created",
    "transferUpdated" = "transfer_updated",
    "transferDeleted" = "transfer_deleted",
    "newCcTask" = "new_cc_task",
    "deleteCcTask" = "delete_cc_task",
    "newAttachment" = "new_attachment",
    "deleteAttachment" = "delete_attachment",
}

export interface IGenericData {
    task_id: number;
}

export interface IGenericTaskData extends IGenericData {
    owner_id: number;
    recipient_id: number;
    status: ETaskStatus;
    type: ETaskType;
    task_parent_id: number | null;
}

export interface IGenericTopicData extends IGenericData {
    topic_id: number;
}

export interface IGenericMessageData extends IGenericData {
    topic_id: number;
    message_id: number;
}

export interface IGenericAttachmentsData extends IGenericData {
    attachment_id: number;
    message_id: number | null
}

export interface IGenericTransferData extends IGenericTaskData {
}

export interface IWsMessage {
    uuid: string;
    type: WSMessagesType;
    data: IGenericData;
    user_email: string;
}

export interface IAttachmentDelete {
    data: IGenericAttachmentsData
    type: WSMessagesType
    user_email: string
    uuid: string
}

