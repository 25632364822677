import {User} from "@/datastore/entities/User";
import {ETaskStatus} from "@/datastore/schemas/TaskSchema";

export interface IActivitySchema {
    id: number;
    event_type: EEventType;
    user: User | null;
    task: number;
    payload: string;
    task_due_date: string;
    task_status: ETaskStatus;
    transfer: number | null;
}

export enum EEventType {
    C = 'create',
    U = 'update',
    D = 'delete',
    R = 'reject',
    A = 'approve',
    MC = 'complete',
    MU = 'uncomplete',
    NT = 'new_trasfer',
    RJ = 'reject_transfer',
    UT = 'update_trasfer',
    AT = 'approve_transfer',
    DT = 'delete_transfer',
}

export enum EEventTranslation {
    create = 'New task has been created',
    update = 'Task has been modified',
    delete = 'Task has been deleted',
    reject = 'Task has been rejected',
    approve = 'Task has been approved',
    complete = 'Task has been completed',
    uncomplete = 'Task has been uncompleted',
    transferCreate = 'New transfer request has been created',
    transferUpdate = 'Transfer request has been modified',
    transferDelete = 'Transfer has been deleted',
    transferReject = 'Transfer has been rejected',
    transferApprove = 'Transfer has been approved',

}

export interface IActivityPaginatedResponseSchema {
    count: number,
    next: string | null,
    previous: string | null,
    results: IActivitySchema[]
}
