import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {ILoginSchema} from '@/datastore/schemas/UserSchema';
import {IUserCountersSchema, IUserMessagesToReadSchema} from '@/datastore/schemas/UserCountersSchema';
import {IPublicSchema} from "@/datastore/schemas/PublicSchema";
import {AxiosRequestConfig} from "axios";
import {IResetPasswordSchema} from "@/datastore/schemas/ResetPasswordSchema";
import {IConfirmEmailSchema} from "@/datastore/schemas/ConfirmEmailSchema";
import {IResetPasswordConfirmSchema} from "@/datastore/schemas/ResetPasswordConfirmSchema";
import {IRegistrationConfirmEmailSchema} from "@/datastore/schemas/RegistrationConfirmEmailSchema";
import {IUsersSettingsSchema} from "@/datastore/schemas/UsersSettingsSchema";
import {UserSettings} from "@/datastore/entities/UserSettings";

export interface IUserManager {
    signIn: (email: string, password: string) => Promise<string>;
    signUp: (email: string, password1: string, password2: string, username: string) => Promise<string>;
    signOut: () => Promise<any>;
    me: (only_token?: boolean) => Promise<IUsersSettingsSchema>;
    counters: () => Promise<IUserCountersSchema>;
    messagesToRead: (taskId?: number, topicId?: number) => Promise<IUserMessagesToReadSchema>;
    public: (token: string, config?: AxiosRequestConfig) => Promise<IPublicSchema>;
    confirmEmail: (key: string) => Promise<IConfirmEmailSchema>;
    resetPassword: (email: string) => Promise<IResetPasswordSchema>;
    resetPasswordConfirmation: (uid: string, token: string, new_password1: string, new_password2: string) => Promise<IResetPasswordConfirmSchema>;
    sendRegistrationConfirmationEmail:(email:string) => Promise<IRegistrationConfirmEmailSchema>;
}

export class UserManager implements IUserManager {

    constructor(private _httpClient: IHttpClient) {
    }

    public async signIn(email: string, password: string): Promise<string> {
        const response = await this._httpClient.post<ILoginSchema>('rest-auth/login/', {
            email, password,
        });
        return response.key;
    }

    public async signUp(email: string, password1: string, password2: string, username: string): Promise<string> {
        const response = await this._httpClient.post<ILoginSchema>('rest-auth/registration/', {
            email, password1, password2, username,
        });
        return response.key;
    }

    public async signOut(): Promise<string> {
        return this._httpClient.post('rest-auth/logout/', {});
    }

    public me(only_token?: boolean): Promise<IUsersSettingsSchema> {
        return this._httpClient.get<IUsersSettingsSchema>('user/me/', {params: {
                only_token: only_token
            }});
    }

    public async counters(): Promise<IUserCountersSchema> {
        return this._httpClient.get<IUserCountersSchema>('user/counters/');
    }

    public async messagesToRead(taskId?: number, topicId?: number): Promise<IUserMessagesToReadSchema> {
        return this._httpClient.get<IUserMessagesToReadSchema>('user/messages_to_read/',
            {params: {
                task_id: taskId, topic_id: topicId
            }
        });
    }

    public public(token: string, config?: AxiosRequestConfig): Promise<IPublicSchema> {
        return this._httpClient.get<IPublicSchema>('public/task/' + token, config);
    }

    public confirmEmail(key: string): Promise<IConfirmEmailSchema> {
        return this._httpClient.post<IConfirmEmailSchema>('rest-auth/account-confirm-email/', {key});
    }

    public resetPassword(email: string): Promise<IResetPasswordSchema> {
        return this._httpClient.post<IResetPasswordSchema>('rest-auth/password/reset/', {email});
    }

    public resetPasswordConfirmation(uid: string, token: string, new_password1: string, new_password2: string): Promise<IResetPasswordConfirmSchema> {
        return this._httpClient.post<IResetPasswordConfirmSchema>('rest-auth/password/reset/confirm/',
            {uid, token, new_password1, new_password2}
        );
    }

    public sendRegistrationConfirmationEmail(email:string) : Promise<IRegistrationConfirmEmailSchema>{
        return this._httpClient.post<IRegistrationConfirmEmailSchema>('rest-auth/account-send-confirm-email/', {email});
    }
}

export const userManager: IUserManager = new UserManager(httpClient);
