








































































import {Component, Vue} from 'vue-property-decorator';
import {taskMessageManager} from '@/datastore/managers/MessagesManager';
import {GenericPageMessage} from '@/datastore/entities/PageMessage';
import Grid from '@/components/Grid/Grid.vue';
import SimpleRow from '@/components/Grid/SimpleRow.vue';
import Loading from 'vue-loading-overlay';
import {EventBus} from '@/EventBus';
import PerfectScrollbar from 'perfect-scrollbar';
import EditorViewer from '@/components/EditorViewer.vue';
import {Message as MessageEntities} from '@/datastore/entities/Message';
import {EPanelType} from '@/store/types';

@Component({
    components: {
        EditorViewer,
        Grid,
        SimpleRow,
        Loading,
    },
    name: 'Message'
})
export default class Message extends Vue {

    private task_topic_list: any[] = [];
    private ps!: PerfectScrollbar;

    private currentPage: number = 1;
    private pageSize: number = 50;
    private totalRows: number = 0;

    private loading: boolean = false;
    private list: any[] = [];

    private sort: string[] = [];

    private gridUpdateDebounce!: number;

    private messageTypeLabel(messageType: string) {
        return MessageEntities.messageTypeLabel(messageType);
    }

    get header() {
        const h = this.$store.getters['account/messagesGridSettings'];
        if (h && h.header) {
            return h.header;
        }
        return [
            {
                name: 'task__code',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: '_task__code'},
                title: 'ID',
                field: 'taskCode',
                width: 10
            },
            {
                name: 'created',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'created'},
                title: 'Date',
                field: 'created',
                width: 10
            },
            {
                name: 'from',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'owner__email'},
                title: 'From',
                field: 'owner',
                width: 10
            },
            {
                name: 'type',
                sortable: false,
                title: 'Type',
                field: 'type',
                width: 10
            },
            {
                name: 'content',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'content'},
                title: 'Content',
                field: 'content',
                width: 10
            }
        ];
    }

    private openPanel(message: any) {
        let topic_id = message.topic;
        let task_id = message.task;

        this.task_topic_list.forEach((m: GenericPageMessage) => {
            if (m.topic === message.topic && m.type === message.type && m.task === message.task){
                m.is_read = true
            }
        });

        const openTopic = () => {
            if (topic_id) {
                this.$store.dispatch('modal/panelType', {type: EPanelType.MESSAGE});
                this.$store.dispatch('modal/setActiveTopicById', {id: topic_id});
                this.$store.dispatch('modal/fetchActiveTopicMessages');
            } else {
                this.$store.dispatch('modal/panelType', {type: EPanelType.SHOW_TOPICS});
                this.$store.dispatch('modal/resetActiveTopic');
            }
        };

        if (task_id !== this.$store.getters['modal/activeTask'].id) {
            EventBus.$once('panelShowed', () => {
                this.$store.dispatch('modal/panelTab', {tab_index: 1}).then(() => {
                    openTopic();
                });
            });
            EventBus.$emit('openPanel', task_id);
        } else {
            openTopic();
        }


    }

    private mounted() {
        if (!this.ps) {
            this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
                suppressScrollX: true
            });
        } else {
            this.ps.update();
        }
        this.fetchMessages();
    }

    private isActiveTask(id: number) {
        return this.$store.getters['modal/activeTask'].id == id;
    }

    private updateSettingsHandler(header: any) {
        clearTimeout(this.gridUpdateDebounce);
        this.gridUpdateDebounce = setTimeout(() => {
            this.$store.dispatch('account/updateUserSettings', {
                settings: {
                    frontend_settings: {messages_grid: {header: header}}
                }
            });
        }, 500);
    }

    private orderChangeHandler(sort: string[]) {
        this.sort = sort;
        this.fetchMessages();
    }

    private fetchMessages() {
        //memorizzo i task che devo eventualmente fetchare successivamente
        this.loading = true;
        let tasks: number[] = [];
        let ret: any[] = [];
        const pag = {page: this.currentPage, page_size: this.pageSize};
        taskMessageManager.messages(pag, this.sort).then(e => {
            this.totalRows = e.count;
            e.results.forEach((i) => {
                tasks.push(i._task); //t.task = id del task
                ret.push(new GenericPageMessage(i));
                this.task_topic_list = ret;
            });
        }).then((e) => {
            return this.$store.dispatch('task/lazyFetchByIds', {ids: tasks});
        }).then((e) => {
            this.list = this.task_topic_list.map((e) => {
                return {t: this.$store.getters['task/taskById'](e.task), c: e};
            });
        }).finally(() => {
            this.loading = false;
            this.$store.dispatch('account/fetchMessagesToRead');
        });
    }

    public beforeDestroy() {
        this.ps.destroy();
    }

}
