import {User} from '@/datastore/entities/User';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {RootState} from '@/store/types';
import {userManager} from '@/datastore/managers/UserManager';
import {router} from '@/router';
import {Statistics} from '@/datastore/entities/Statistics';
import {IWebSocketClient, WebSocketClient} from '@/websocket';
import {usersManager} from "@/datastore/managers/UsersManager";
import {UserSettings} from "@/datastore/entities/UserSettings";
import {IUsersSettingsSchema} from "@/datastore/schemas/UsersSettingsSchema";
import {Vue} from 'vue-property-decorator';

const namespaced: boolean = true;

interface AccountState {
    user: User | undefined;
    statistics: Statistics;
    websocket?: IWebSocketClient | undefined;
    publicToken: string | undefined;
    messageToRead: number;
    settings: UserSettings;
}

const accountState: AccountState = {
    user: undefined,
    statistics: new Statistics(),
    websocket: undefined,
    publicToken: undefined,
    messageToRead: 0,
    settings: new UserSettings(),
};

const getters: GetterTree<AccountState, RootState> = {
    taskGridSettings(state) {
        return state.settings.frontend_settings.task_grid
    },
    taskGridInSettings(state) {
        return state.settings.frontend_settings.task_grid_in
    },
    taskGridOutSettings(state) {
        return state.settings.frontend_settings.task_grid_out
    },
    activityGridSettings(state) {
        return state.settings.frontend_settings.activity_grid
    },
    messagesGridSettings(state) {
        return state.settings.frontend_settings.messages_grid
    },
    isPublic(state) {
        return !!state.publicToken
    },
    publicToken(state) {
        return state.publicToken;
    },
    isAuthenticated(state): boolean {
        return state.publicToken === undefined && state.user !== undefined
    },
    user(state): User | undefined {
        return state.user;
    },
    userTaskStatistics(state): Statistics {
        return state.statistics;
    },
    messageToRead(state): number {
        return state.messageToRead
    },
    settings(state): UserSettings | undefined {
        return state.settings
    },
};

const actions: ActionTree<AccountState, RootState> = {
    signIn({commit, dispatch}, {email, password}) {
        return userManager.signIn(email, password).then((token) => {
            window.localStorage.setItem('auth-token', token);
            return dispatch('getProfile');
        }).catch((e) => {
            throw e.response.data
        })
    },
    signUp({commit, dispatch}, {email, password1, password2, username}) {
        return userManager.signUp(email, password1, password2, username).catch((e) => {
            throw e.response.data
        })
    },
    getProfile({commit}) {
        return userManager.me().then((user) => {
            commit('setUser', new User(user));
            commit('setUserSettings', new UserSettings(user));
            commit('setPublicToken', undefined)
            localStorage.removeItem('public-token');
        })
    },
    signOut({commit}) {
        return userManager.signOut().then(() => {
            window.localStorage.removeItem('auth-token');
            commit('signOut');
            if (router.currentRoute.name !== 'newtask') {
                router.push({name: 'newtask'});
            }
        });
    },

    fetchStatistics({commit}) {
        return userManager.counters().then((e) => {
            commit('updateStatistics', e);
        });
    },

    setPublicUser({commit}, {user}) {
        commit('setUser', user)
    },

    setPublicToken({commit}, {token}) {
        commit('setPublicToken', token)
    },

    fetchMessagesToRead({commit}) {
        userManager.messagesToRead().then(mc => {
            commit('setMessagesToRead', mc.to_read)
        })
    },
    updateUserSettings({commit, rootGetters}, {settings}) {
        const merged: IUsersSettingsSchema = {...rootGetters['account/settings'] || {}, ...settings};
        merged.frontend_settings = JSON.stringify({...rootGetters['account/settings'].frontend_settings || {}, ...settings.frontend_settings});

        return usersManager.update_users_settings(rootGetters['account/user'].id, merged).then((s: IUsersSettingsSchema) => {
            commit('setUserSettings', new UserSettings(s));
            commit('patchUser', new User(s));
        }).catch((e: any) => {
            throw e.response.data
        })
    },

    updateUserAvatar({commit, rootGetters}, {avatar}) {
        return usersManager.update_users_settings(rootGetters['account/user'].id, avatar).then((s: IUsersSettingsSchema) => {
            commit('patchUser', new User(s));
        }).catch((e: any) => {
            throw e.response.data
        })
    },
    deleteUserSettings({commit, rootGetters}) {
        usersManager.delete_users_settings(rootGetters['account/user'].id).then((s) => {
            commit('setUserSettings', new UserSettings(s))
        })
    },
    resetNewCcTasks({commit, rootGetters}) {
        usersManager.reset_new_cc_tasks(rootGetters['account/user'].id).then((s) => {
            commit('setNewCcTasks', false);
        });
    },
    newCcTasks({commit}) {
        commit('setNewCcTasks', true);
    },
    resetNewActivities({commit, rootGetters}) {
        usersManager.reset_new_activities(rootGetters['account/user'].id).then((s) => {
            commit('setNewActivities', false);
        });
    },
    newActivities({commit}) {
        commit('setNewActivities', true);
    }
};

const mutations: MutationTree<AccountState> = {
    setPublicToken(state, t) {
        state.publicToken = t;
    },
    setUser(state, user) {
        state.user = user;
        //in realta' perchè inviare la stirnga vuota per inizializzare la ws?? ha senso?
        // se la setuser viene chiamata due volte nella stessa sessione vanno chiuse le ws aperte
        state.websocket = new WebSocketClient(localStorage.getItem('auth-token') || state.publicToken || '');
    },
    patchUser(state, user) {
        state.user = user
    },
    signOut(state) {
        state.user = undefined;
        if (state.websocket) {
            state.websocket.destroy();
        }

    },
    updateStatistics(state, stat) {
        state.statistics = new Statistics(stat);
    },
    setMessagesToRead(state, mc) {
        state.messageToRead = mc;
    },
    setUserSettings(state, s) {
        state.settings = s
    },
    setNewCcTasks(state, value) {
        if (state.user){
            Vue.set(state.user, 'newCcTasks', value);
        }

    },
    setNewActivities(state, value) {
        if (state.user){
            Vue.set(state.user, 'newActivities', value);
        }

    },
};


export default {
    namespaced,
    state: accountState,
    getters,
    actions,
    mutations,
};
