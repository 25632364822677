























import {Component, Vue} from 'vue-property-decorator';
import {ETaskStatus, ITaskSchema} from "@/datastore/schemas/TaskSchema";

@Component({
    components: {},
    name: "ArchiveTaskModal"
})
export default class ArchiveTaskModal extends Vue {

    get active_modal_task() {
        return this.$store.getters['modal/activeTask'];
    }

    get archived() {
        return this.$store.getters['modal/activeTask'].archived
    }

    get label() {
        return this.archived ? 'Restore' : 'Archive'
    }

    public handleYes() {
        if (this.archived) {
            this.active_modal_task.unarchive().then((id: number) => {
                //la web socket non mi arriva quindi devo togliere il task da tutte le liste
                this.$store.dispatch('task/removeArchived', {id});
                this.$store.dispatch('task/addMy', {id});
                this.active_modal_task.archived = false;
                this.$store.dispatch('account/fetchStatistics');
            }).catch(() => {
            });
        } else {
            this.active_modal_task.archive().then((id : number) => {
                //la web socket non mi arriva quindi devo togliere il task da tutte le liste
                this.$store.dispatch('task/removeIn', {id});
                this.$store.dispatch('task/removeMy', {id});
                this.$store.dispatch('task/removeOut', {id});
                this.active_modal_task.archived = true;
                this.$store.dispatch('account/fetchStatistics');
            }).catch(() => {
            });
        }

        this.$bvModal.hide('ArchiveTask');
    }

    public handleNo() {
        this.$bvModal.hide('ArchiveTask');
    }
}
