



































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Grid from '@/components/Grid/Grid.vue';
import {ITaskPaginatedResponseSchema} from '@/datastore/schemas/TaskSchema';
import {EventBus} from '@/EventBus';
import {Task} from '@/datastore/entities/Task';
import GridRow from '@/components/Grid/GridRow.vue';
import Loading from 'vue-loading-overlay';
import PerfectScrollbar from 'perfect-scrollbar';


@Component({
    components: {Header, Grid, Loading, GridRow},
    name: 'TaskDetails'
})
export default class TaskDetails extends Vue {
    @Prop(String) private readonly type!: string;
    @Prop({type:Boolean, default:false}) private readonly showExpandTool!: boolean;

    private status_filtered: string = '';
    private loading: boolean = false;

    private currentPage: number = 1;
    private pageSize: number = 50;
    private totalRows: number = 0;

    private ps!: PerfectScrollbar;
    private sort: string[] = [];

    private gridUpdateDebounce!: number;

    get header() {
        let h;

        if (this.type === 'in') {
            h = this.$store.getters['account/taskGridInSettings'];
        } else if (this.type === 'out') {
            h = this.$store.getters['account/taskGridOutSettings'];
        } else {
            h = this.$store.getters['account/taskGridSettings'];
        }

        if (h && h.header) {
            return h.header;
        }
        let columns = [
            {name: 'expand', sortable: false, width: 4},
            {
                name: 'id',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'code'},
                title: 'ID',
                field: 'code',
                width: 5
            },
            {
                name: 'object',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'title'},
                title: 'Object',
                field: 'title',
                width: 9
            },
            {
                name: 'from',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'owner__email'},
                title: 'From',
                field: 'owner',
                width: 10
            },
            {
                name: 'to',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'recipient__email'},
                title: 'To',
                field: 'recipient',
                width: 9
            },
            {
                name: 'due_date',
                sortable: true,
                sort: {order: 0, direction: false, sort_field: 'due_date'},
                title: 'Due Date',
                field: 'dueDate',
                width: 7
            },
            {
                name: 'tags',
                sortable: false,
                title: 'Tags',
                field: 'tags',
                width: 50
            },
            {name: 'info', sortable: false, width: 6}
        ];
        if (this.type === 'in') {
            columns = columns.filter((col: any) => {
                return !(col.name == 'to' || col.name === 'info');
            });
        } else if (this.type === 'out') {
            columns = columns.filter((col: any) => {
                return !(col.name == 'from' || col.name == 'info');
            });
        } else if (this.type === 'cc') {
            columns = columns.filter((col: any) => {
                return !(col.name == 'info');
            });
        }
        return columns;
    }

    get getTask() {
        switch (this.$route.name) {
            case 'details':
                if (this.type === 'in') {
                    return this.$store.getters['task/in'];
                } else if (this.type === 'my') {

                    return this.$store.getters['task/my'].filter((t: any) => {
                        if (this.$store.getters['dashboard/showCompleted']) {
                            return true;
                        } else {
                            return t.status == 'approved';
                        }
                    });

                } else if (this.type === 'out') {
                    return this.$store.getters['task/out'];
                }
                break;
            case 'cc':
                return this.$store.getters['task/cc'];
            case 'archived':
                return this.$store.getters['task/archived'];
        }
    }

    public update_tasks() {
        switch (this.$route.name) {
            case 'details':
                if (this.type === 'in') {
                    this.loading = true;
                    this.$store.dispatch('task/fetchIn', {
                        pagination: {
                            page: this.currentPage,
                            page_size: this.pageSize
                        },
                        order: this.sort
                    }).then((e: ITaskPaginatedResponseSchema) => {
                        this.totalRows = e.count;
                    }).finally(() => {
                        this.loading = false;
                    });
                } else if (this.type === 'my') {
                    this.loading = true;
                    this.$store.dispatch('task/fetchMy', {
                        pagination: {
                            page: this.currentPage,
                            page_size: this.pageSize
                        },
                        order: this.sort
                    }).then((e: ITaskPaginatedResponseSchema) => {
                        this.totalRows = e.count;
                    }).finally(() => {
                        this.loading = false;
                    });
                } else if (this.type === 'out') {
                    this.loading = true;
                    this.$store.dispatch('task/fetchOut', {
                        pagination: {
                            page: this.currentPage,
                            page_size: this.pageSize
                        },
                        order: this.sort
                    }).then((e: ITaskPaginatedResponseSchema) => {
                        this.totalRows = e.count;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
                break;
            case 'cc':
                this.$store.dispatch('task/fetchCc', {
                    pagination: {
                        page: this.currentPage,
                        page_size: this.pageSize
                    },
                    order: this.sort
                }).then((e: ITaskPaginatedResponseSchema) => {
                    this.$store.dispatch('account/resetNewCcTasks');
                    this.totalRows = e.count;
                });
                break;
            case 'archived':
                this.$store.dispatch('task/fetchArchived', {
                    pagination: {
                        page: this.currentPage,
                        page_size: this.pageSize
                    },
                    order: this.sort
                }).then((e: ITaskPaginatedResponseSchema) => {
                    this.totalRows = e.count;
                });
                break;
        }
    }

    public mounted() {
        this.update_tasks();
        this.createOrUpdateScroll();
    }

    public updated() {
        this.createOrUpdateScroll();
    }

    @Watch('$route')
    public onUrlChange(newVal: any,) {
        this.currentPage = 1;
        this.update_tasks();
    }

    private created() {
        EventBus.$on('filterChanged', this.update_tasks);
        EventBus.$on('dashboardChanged', this.update_tasks);
    }

    private beforeDestroy() {
        this.ps.destroy();
        EventBus.$off('filterChanged', this.update_tasks);
        EventBus.$off('dashboardChanged', this.update_tasks);
    }

    private orderChangeHandler(sort: string[]) {
        this.sort = sort;
        this.update_tasks();
    }

    private updateSettingsHandler(header: any) {
        clearTimeout(this.gridUpdateDebounce);
        this.gridUpdateDebounce = setTimeout(() => {
            let grid_settings: any = {}
            if (this.type === 'in') {
                grid_settings['task_grid_in'] = {header: header}
            } else if (this.type === 'out') {
                grid_settings['task_grid_out'] = {header: header}
            } else {
                grid_settings['task_grid'] = {header: header}
            }
            this.$store.dispatch('account/updateUserSettings', {
                settings: {
                    frontend_settings: grid_settings
                }
            });
        }, 500);
    }

    private createOrUpdateScroll() {
        if (this.ps) {
            this.ps.update();
        } else {
            this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
                suppressScrollX: true
            });
        }
    }

    private getExpand(id: number) {
        return this.$store.getters['modal/isExpanded'](id) && this.$store.getters['task/taskById'](id).children.length > 0;
    }

    private getColor(t: Task) {

        if (t.isCompleted) {
            return 'task_completed';
        } else if (t.hasPendingTransfer) {
            return 'task_transfer';
        } else if (t.isPending) {
            return 'task_transfer';
        } else {
            return 'task_uncompleted';
        }
    }

    private canBeCompleted(id: number) {
        return this.$store.getters['task/taskById'](id).can_be_completed && this.$store.getters['task/taskById'](id).isApproved;
    }

    private getTitle(id: number) {
        return this.$store.getters['task/taskById'](id).isCompleted ? this.$t('message.task_can_be_completed') : this.$t('message.task_cant_be_completed');
    }

    private toggleExpand(id: number) {
        if (!this.getExpand(id)) {
            this.$store.dispatch('task/fetchByIds', {ids: this.$store.getters['task/taskById'](id).children}).then(() => {
                this.$store.dispatch('modal/toggleExpand', {id});
            });
        } else {
            this.$store.dispatch('modal/toggleExpand', {id});
        }
    }

    private isActiveTask(id: number) {
        return this.$store.getters['modal/activeTask'].id == id;
    }

    private openPanel(id: number) {
        EventBus.$emit('openPanel', id);
    }

    private expandAll() {
        let childrens:number[] = []
        this.$store.getters['task/my'].forEach((t:Task)=>{
            childrens = childrens.concat(t.children)
        })
        this.$store.dispatch('task/lazyFetchByIds',{ids:childrens}).then(()=>{
            this.$store.getters['task/my'].forEach((t:Task)=>{
                this.$store.dispatch('modal/expand', {id:t.id})
            })
        })
    }

    private collapseAll() {
        this.$store.getters['task/my'].forEach((t:Task)=>{
            this.$store.dispatch('modal/collapse', {id:t.id})
        })
    }
}
