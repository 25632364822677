





























import {Component, Prop, Vue} from 'vue-property-decorator';
import {NewTaskFormFieldError} from '@/store/types';
import ModalHeader from '@/components/ModalHeader.vue';
import {ITaskNS} from "@/datastore/schemas/TaskSchema";
import TaskForm from "@/components/TaskForm.vue";
import {Mixins} from "vue-mixin-decorator";
import {ISubTaskModalMixin} from "@/components/Mixin/Mixin";
import StoreMixin from "@/components/Mixin/StoreMixin.vue";

@Component({
    components: {TaskForm, ModalHeader},
    name: "SubTaskModal"
})
export default class SubTaskModal extends Mixins<ISubTaskModalMixin>(StoreMixin) {

    private taskError: NewTaskFormFieldError = new NewTaskFormFieldError();

    public close() {
        this.$bvModal.hide('SubTask');
    }

    public send(el: ITaskNS) {
        this.taskError.reset();
        this.activeTask.subtask(el).then(()=>{
            this.close();
        }).catch((e:any)=>{
            /* errore durante l'accettazione del messaggio*/
            this.taskError = new NewTaskFormFieldError(e.response.data);
        })
    }
}
