


































import {Component, Prop, Vue} from 'vue-property-decorator';
import PerfectScrollbar from "perfect-scrollbar";
import AttachmentBox from "@/components/SidePanel/AttachmentBox.vue";
import {Attachment} from "@/datastore/entities/Attachment";
import {EAttachmentType} from "@/store/types";

@Component({
    components: {AttachmentBox},
    name: "AttachmentList",
})
export default class AttachmentList extends Vue {
    @Prop(Number) private readonly type!: EAttachmentType;
    @Prop(Array) private readonly attachments!: Attachment[];
    @Prop(Number) private readonly selectionId!: number;

    private ps!: PerfectScrollbar;

    get focused() {
        return this.activeMediaIndex == this.type;
    }

    get label(){
        switch (this.type) {
            case EAttachmentType.link:
                return this.$tc('message.link',2);
            case EAttachmentType.file:
                return this.$tc('message.doc',2);
            case EAttachmentType.media:
                return this.$tc('message.media',2);

        }
    }

    get activeMediaIndex() {
        return this.$store.getters['modal/activeMedia']
    }

    mounted() {
        this.ps = new PerfectScrollbar(this.$refs.scroll as Element, {
            useBothWheelAxes: true,
            suppressScrollY: true
        });
    }

    public beforeDestroy() {
        this.ps && this.ps.destroy();
    }

    updated() {
        if (this.ps) {
            this.ps.update()
        }
    }

    private setActive() {
        this.$store.dispatch('modal/activeMedia', {type: this.type})
    }

    private back() {
        if (this.focused) {
            this.$store.dispatch('modal/resetActiveMedia')
        }
    }
}
