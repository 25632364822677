import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {IAttachmentDict, ITaskAttachmentDict, RootState} from '@/store/types';
import Vue from 'vue';
import {Attachment} from "@/datastore/entities/Attachment";
import {attachmentsManager} from "@/datastore/managers/AttachmentsManager";
import store from "@/store";

const namespaced: boolean = true;

interface IAttachmentState {
    dictionary: IAttachmentDict;
    task_attachments: ITaskAttachmentDict;
}

const attachmentState: IAttachmentState = {
    dictionary: {},
    task_attachments: {},
};

const getters: GetterTree<IAttachmentState, RootState> = {
    dictionary(state): IAttachmentDict {
        return state.dictionary;
    },
    attachmentById: (state) => (id: number) => {
        return state.dictionary[id];
    },
    attachmentsByTaskId: (state) => (id: number) => {
        let ret: Attachment[] = [];
        if (state.task_attachments[id]) {
            state.task_attachments[id].forEach((c: number) => {
                ret.push(state.dictionary[c] as Attachment)
            });
            return ret;
        }
        return null;
    },
    attachmentsCountByTaskId: (state) =>(id:number)=>{
        if (state.task_attachments[id]) {
            return state.task_attachments[id].length
        }
        return 0;
    }
};

const actions: ActionTree<IAttachmentState, RootState> = {
    fetchAttachmentsByTaskId({commit}, {id}) {
        if (id as number > 0) {
            attachmentsManager.task_attachments(id).then((cs) => {
                const new_att: IAttachmentDict = {};
                const new_list: number[] = [];
                cs.forEach((a) => {
                    new_att[a.id] = new Attachment(a);
                    new_list.push(a.id);
                });
                commit('updateTaskAttachment', {id, new_att, new_list});
            });
        }
    },
    removeAttachment({commit}, {id}){
        commit('removeAttachment', {task_id:store.getters['modal/activeTaskId'], attach_id: id})
    }
};

const mutations: MutationTree<IAttachmentState> = {
    updateTaskAttachment(state, c) {
        state.dictionary = {...state.dictionary, ...c.new_att};
        Vue.set(state.task_attachments, c.id, c.new_list);
    },
    removeAttachment(state, atd){
        delete state.dictionary[atd.attach_id]
        Vue.set(state.task_attachments, atd.task_id, state.task_attachments[atd.task_id].filter((a)=>{return a != atd.attach_id}));
    }
};


export default {
    namespaced,
    state: attachmentState,
    getters,
    actions,
    mutations,
};
