import {IUserSchema} from '@/datastore/schemas/UserSchema';
import {IAttachmentSchema} from '@/datastore/schemas/AttachmentSchema';
import {ITagSchema} from '@/datastore/schemas/TagSchema';
import {ITransferSchema} from "@/datastore/schemas/TransferSchema";

export enum ETaskStatus {
    unknown = -1,
    pending = 'pending',
    approved = 'approved',
    rejected = 'rejected',
    completed = 'completed',
    archived = 'archived',
}

export enum ETaskType {
    none = 'none',
    unknown = '',
    in = 'in',
    my = 'my',
    out = 'out',
    cc = 'cc',
    rejected = 'rejected',
    archived = 'archived',
}

export interface ITaskNS {
    tags: string[];
    recipient: string;
    due_date: string|null;
    description: string;
    title: string;
    attachments: number[];
    cc: string[]
}

export interface ITaskSchema {
    attachments: IAttachmentSchema[];
    archived: boolean;
    archiviation_date: string;
    children: ITaskSchema[];
    messages_count: number;
    messages_to_read: number;
    content: string;
    created: string;
    due_date: string;
    id: number;
    modified: string;
    owner: IUserSchema;
    permission: number;
    recipient: IUserSchema;
    status: ETaskStatus;
    tags: ITagSchema[];
    title: string;
    cc:IUserSchema[];
    pending_transfer: ITransferSchema | null
    can_be_completed: boolean;
}

export interface ITaskUpdateSchemaRequest {
    title?: string
    recipient?: string
    content?: string
    due_date?: Date
    attachments?: number[]
    tags?: string[]
    parent?: number
    cc?: string[]
}


export interface INewPublicTask {
    owner: string;
    recipient: string;
    title: string;
    content: string;
    due_date: Date;
}

export interface IPaginationRequestSchema {
    page_size: number
    page: number
}

export interface ITaskPaginatedResponseSchema {
    count: number,
    next: string | null,
    previous: string | null,
    results: ITaskSchema[]
}
