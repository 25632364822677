































import {Component, Prop, Vue} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import {usersManager} from '@/datastore/managers/UsersManager';
import {MultiselectTagSchema} from '@/datastore/schemas/TagSchema';
import {Tag} from '@/datastore/entities/Tag';
import {tagManager} from '@/datastore/managers/TagManager';
import {MultiselectUserSchema} from "@/datastore/schemas/UserSchema";

@Component({
    components: {Multiselect},
    name: 'TagSelect'
})
export default class TagSelect extends Vue {
    @Prop(Boolean) private readonly allowMultiple!: boolean;
    @Prop(Boolean) private readonly allowCreation!: boolean;
    @Prop(Array) private readonly defaultSelected!: Tag[];
    @Prop({type: Boolean, default: false}) private readonly disabled!: boolean;
    @Prop(String) private readonly placeholder!: string;
    @Prop() private readonly value!: MultiselectTagSchema | MultiselectTagSchema[];

    private options: MultiselectTagSchema[] = [];
    private isLoading = false;

    private created() {
        // if (this.allowMultiple) {
        //     if (this.defaultSelected) {
        //         this.options = this.defaultSelected.map((u) => {
        //             return u.MultiselectItem;
        //         });
        //         this.value = this.defaultSelected.map((u) => {
        //             return u.MultiselectItem;
        //         });
        //     }
        // } else {
        //     if (this.defaultSelected) {
        //         this.options = this.defaultSelected.map((u) => {
        //             return u.MultiselectItem;
        //         });
        //         this.value = (this.defaultSelected && this.defaultSelected[0]) ? this.defaultSelected[0].MultiselectItem : [];
        //
        //     }
        // }
        // this.$emit('input', this.value);
    }

    private addTag(query: string) {
        if (this.allowCreation) {
            const tag: MultiselectTagSchema = {
                title: query
            };
            if (this.allowMultiple) {
                let v: MultiselectTagSchema[] = [];
                //evito di rimettere i duplicati
                this.options = this.options.filter((e) => {
                    return e.title != tag.title;
                });
                this.options.push(tag);
                v = (this.value as MultiselectTagSchema[]).filter((e) => {
                    return e.title != tag.title;
                });
                v.push(tag);
                this.$emit('input', v);
            } else {
                //evito di rimettere i duplicati
                this.options = this.options.filter((e) => {
                    return e.title != tag.title;
                });
                this.options.push(tag);
                this.$emit('input', tag);
            }
        }
    }

    private asyncFind(query: string) {
        this.isLoading = true;
        if (query.length > 0) {
            tagManager.fetch_tags(query, 1).then(response => {
                this.options = response.map((e: Tag) => {
                    return e.MultiselectItem;
                });
                this.isLoading = false;
            });
        } else {
            this.options = [];
            this.isLoading = false;
        }
    }

    private tabEvent(event: any) {
        //@ts-ignore
        this.$refs['multiselect'].addPointerElement();
    }
}
