





















































































































import {Component, Mixins} from 'vue-property-decorator';
import Topic from '@/components/Topic.vue';
import IMOEditor from "@/components/IMOEditor.vue";
import {EPanelType} from "@/store/types";
import PanelContentTemplate from "@/components/SidePanel/PanelContentTemplate.vue";
import Chat from "@/components/Chat.vue";
import {IMOeditorDataSchema} from "@/datastore/schemas/IMOeditorDataSchema";

import AttachmentBox from "@/components/SidePanel/AttachmentBox.vue";
import PanelMixin from "@/components/Mixin/PanelMixin.vue";
import {IMessageTabMixin} from "@/components/Mixin/Mixin";
import StoreMixin from "@/components/Mixin/StoreMixin.vue";
import PermissionMixin from '@/components/Mixin/PermissionMixin.vue';
import jQuery from "jquery";

@Component({
    components: {Topic, IMOEditor, PanelContentTemplate, Chat, AttachmentBox},
    name: "MessageTab",
})
export default class MessageTab extends Mixins<IMessageTabMixin>(PanelMixin, StoreMixin, PermissionMixin) {
    private scrollEnd: boolean = false;

    private handleNewTopic() {
        this.panelType = EPanelType.TOPIC;
    }

    private handleSend(el: IMOeditorDataSchema) {
        if (this.panelCreateTopic) {
            this.handleSendTopic(el)
        } else if (this.panelShowingChat) {
            this.handleSendMessage(el)
        }
    }

    private handleSendMessage(el: IMOeditorDataSchema) {
        this.postMessage(el).then(() => {
            (this.$refs.editor as IMOEditor).clearContent();
            this.panelType = EPanelType.MESSAGE;
        })
    }

    private handleSendTopic(el: IMOeditorDataSchema) {
        this.postTopic(el).then(() => {
            (this.$refs.editor as IMOEditor).clearContent();
            this.panelType = EPanelType.SHOW_TOPICS;
        })
    }

    private handleOnTopicToggle() {

    }

    private afterLeave(bind: any) {
        this.scrollEnd = true;
        this.animationEnd(bind);
    }

    private beforeEnter(bind: any) {
        this.scrollEnd = false;
        const sc = bind.$refs.scroll as HTMLElement;
        sc.scrollTo({top: 0});
    }

    private animationEnd(bind: any) {
        const sc = bind.$refs.scroll as HTMLElement;
        jQuery(sc).scrollTop(0);

        if (this.scrollEnd) {
            const sc = bind.$refs.scroll as HTMLElement;
            jQuery(sc).scrollTop(99999);
        }
    }

}
