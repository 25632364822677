import {ICounterSchema, IUserCountersSchema} from '@/datastore/schemas/UserCountersSchema';

export class TaskStatistics {
    private readonly total: number;
    private readonly warning: number;
    private readonly expired: number;

    constructor();
    constructor(data: ICounterSchema);
    constructor(data?: any) {
        this.total = data && data.total || 0;
        this.expired = data && data.expired || 0;
        this.warning = data && data.warning || 0;
    }
}
export class Statistics {

    public readonly in: TaskStatistics;
    public readonly my: TaskStatistics;
    public readonly out: TaskStatistics;

    constructor();
    constructor(data: IUserCountersSchema);
    constructor(data?: any) {
        this.in = new TaskStatistics(data && data.in);
        this.my = new TaskStatistics(data && data.my);
        this.out = new TaskStatistics(data && data.out);
    }
}

