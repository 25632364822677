























import {Component, Prop, Vue} from 'vue-property-decorator';
import DashboardBox from '@/components/DashboardBox.vue';
import {dashboardManager} from '@/datastore/managers/DashboardManager';
import {Dashboard} from '@/datastore/entities/Dashboard';

@Component({
    components: {DashboardBox},
    name: "DashboardBoxContainer"
})
export default class DashboardBoxContainer extends Vue {

    get d_box() {
        //fetcho dallo store e aggiungo un elemento fittizio per il +
        return this.$store.getters['dashboard/dashboardArraySort'].concat({id:-1,is_add:true});
    }
}
