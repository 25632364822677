import { render, staticRenderFns } from "./PanelContentTemplate.vue?vue&type=template&id=47a5b36e&scoped=true&"
import script from "./PanelContentTemplate.vue?vue&type=script&lang=ts&"
export * from "./PanelContentTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./PanelContentTemplate.vue?vue&type=style&index=0&id=47a5b36e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a5b36e",
  null
  
)

export default component.exports