import {httpClient, IHttpClient} from '@/datastore/HttpClient';
import {
    ITaskPaginatedResponseSchema,
    IPaginationRequestSchema,
    ITaskSchema, ITaskUpdateSchemaRequest
} from '@/datastore/schemas/TaskSchema';
import * as qs from "qs";
import {CancelToken} from 'axios';
import {
    ITaskTransferResponseSchema,
    ITransferAcceptResponseSchema,
    ITransferRejectResponseSchema
} from "@/datastore/schemas/TransferSchema";

export interface ITaskManager {
    fetch_by_id: (id: number, token?: CancelToken) => Promise<ITaskSchema>;
    fetch_by_ids: (ids: number[]) => Promise<ITaskSchema[]>
    in: (pagination: IPaginationRequestSchema, search?: string, ordering?: string[]) => Promise<ITaskPaginatedResponseSchema>;
    my: (pagination: IPaginationRequestSchema, dashboard_id?: string, search?: string, ordering?: string[]) => Promise<ITaskPaginatedResponseSchema>;
    out: (pagination: IPaginationRequestSchema, search?: string, ordering?: string[]) => Promise<ITaskPaginatedResponseSchema>;
    cc: (pagination: IPaginationRequestSchema, search?: string, ordering?: string[]) => Promise<ITaskPaginatedResponseSchema>;
    archived: (pagination: IPaginationRequestSchema, search?: string, ordering?: string[]) => Promise<ITaskPaginatedResponseSchema>;
    accept: (id: number) => Promise<ITaskSchema>;
    reject: (id: number, reject_message: string) => Promise<ITaskSchema>;
    new_task: (title: string,
               recipient: string,
               description: string,
               due_date: string,
               tags: (number | string)[],
               attachments: number[],
               cc: string[]) => Promise<ITaskSchema>;
    new_public_task: (
        owner: string,
        recipient: string,
        title: string,
        content: string,
        attachments: number[],
        due_date: string | null,
    ) => Promise<ITaskSchema>;
    sub_task: (parent: number,
               attachments: number[],
               title: string,
               recipient: string,
               description: string,
               due_date: string,
               tags: Array<number | string>,
               cc: string[]) => Promise<ITaskSchema>;
    complete: (id: number) => Promise<ITaskSchema>;
    uncomplete: (id: number) => Promise<ITaskSchema>;
    delete: (id: number, delete_message: string) => Promise<ITaskSchema>;
    archive: (id: number) => Promise<ITaskSchema>;
    unarchive: (id: number) => Promise<ITaskSchema>;
    transfer: (new_recipient: string, transfer_message: string, id: number) => Promise<ITaskTransferResponseSchema>;
    transfer_delete: (id: number) => Promise<ITaskTransferResponseSchema>;
    transfer_approve: (id: number) => Promise<ITransferAcceptResponseSchema>;
    transfer_reject: (id: number, reject_message: string) => Promise<ITransferRejectResponseSchema>;
    update_task: (id: number, settings: ITaskUpdateSchemaRequest) => Promise<ITaskSchema>;
}

export class TaskManager implements ITaskManager {

    constructor(private _httpClient: IHttpClient) {
    }

    public fetch_by_ids(ids: number[]): Promise<ITaskSchema[]> {
        return this._httpClient.get<ITaskSchema[]>('tasks/',
            {
                params: {ids: ids},
                paramsSerializer: params => {
                    return qs.stringify({ids: ids}, {indices: false});
                }
            });
    }

    public fetch_by_id(id: number, token?: CancelToken): Promise<ITaskSchema> {

        return this._httpClient.get<ITaskSchema>('tasks/' + id, {
            cancelToken: token
        });
    }

    public accept(id: number): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/approve/');
    }

    public reject(id: number, reject_message: string): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/reject/', {reject_message});
    }

    public new_public_task(
        owner: string,
        recipient: string,
        title: string,
        content: string,
        attachments: number[],
        due_date: string | null,
    ): Promise<ITaskSchema> {
        return this._httpClient.post<ITaskSchema>('/public/new_task/', {
            owner,
            title,
            recipient,
            content,
            due_date,
            attachments,
        }).catch((e) => {
            throw e.response.data
        })
    }

    public new_task(title: string,
                    recipient: string,
                    description: string,
                    due_date: string | null,
                    tags: (number | string)[],
                    attachments: number[],
                    cc: string[]): Promise<ITaskSchema> {
        return this._httpClient.post<ITaskSchema>('tasks/', {
            title,
            recipient,
            content: description,
            due_date,
            tags,
            topics: [],
            attachments,
            cc
        });
    }

    public complete(id: number, message?: string): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/complete/', {mark_complete_message: message});
    }

    public uncomplete(id: number,  message?: string): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/uncomplete/', {mark_uncomplete_message: message});
    }

    public sub_task(parent: number,
                    attachments: number[],
                    title: string,
                    recipient: string,
                    description: string,
                    due_date: string | null,
                    tags: Array<number | string>,
                    cc: string[]
    ): Promise<ITaskSchema> {

        return this._httpClient.post<ITaskSchema>('tasks/', {
            parent,
            title,
            recipient,
            tags,
            content: description,
            due_date,
            attachments,
            cc
        });
    }

    public in(pagination?: IPaginationRequestSchema, search?: string, ordering?: string[]): Promise<ITaskPaginatedResponseSchema> {
        return this._httpClient.get<ITaskPaginatedResponseSchema>('tasks/', {
            params: {type: 'in', page: null, search, page_size: null, ordering: []},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        type: 'in',
                        search,
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode: false
                    });
            }
        })
    }

    public my(pagination: IPaginationRequestSchema, dashboard_id?: string, search?: string, ordering?: string[]): Promise<ITaskPaginatedResponseSchema> {
        if(dashboard_id){
            search = undefined
        }
        return this._httpClient.get<ITaskPaginatedResponseSchema>('tasks/', {
            params: {type: 'my', dashboard_id, search, page: null, page_size: null, ordering: []},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        type: 'my',
                        dashboard_id,
                        search,
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode: false
                    });
            }
        })
    }

    public out(pagination: IPaginationRequestSchema, search?: string, ordering?: string[]): Promise<ITaskPaginatedResponseSchema> {
        return this._httpClient.get<ITaskPaginatedResponseSchema>('tasks/', {
            params: {type: 'out', page: null, search, page_size: null, ordering: []},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        type: 'out',
                        search,
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode: false
                    });
            }
        });
    }

    public cc(pagination: IPaginationRequestSchema, search?: string, ordering?: string[]): Promise<ITaskPaginatedResponseSchema> {
        return this._httpClient.get<ITaskPaginatedResponseSchema>('tasks/', {
            params: {type: 'cc', page: null, search, page_size: null, ordering: []},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        type: 'cc',
                        search,
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode: false
                    });
            }
        });
    }

    public archived(pagination: IPaginationRequestSchema, search?: string, ordering?: string[]): Promise<ITaskPaginatedResponseSchema> {
        return this._httpClient.get<ITaskPaginatedResponseSchema>('tasks/', {
            params: {type: 'archived', page: null, search, page_size: null, ordering: []},
            paramsSerializer: params => {
                return qs.stringify(
                    {
                        page: (pagination ? pagination.page : null),
                        page_size: (pagination ? pagination.page_size : null),
                        type: 'archived',
                        search,
                        ordering
                    }, {
                        indices: false,
                        strictNullHandling: true,
                        arrayFormat: 'comma',
                        encode: false
                    });
            }
        });
    }

    public delete(id: number, delete_message: string): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/delete/', {delete_message});
    }

    public archive(id: number): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/archive/');
    }

    public unarchive(id: number): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('tasks/' + id + '/unarchive/');
    }

    public transfer(new_recipient: string, transfer_message: string, id: number): Promise<ITaskTransferResponseSchema> {
        return this._httpClient.post<ITaskTransferResponseSchema>('/task_transfers/', {
            new_recipient,
            transfer_message,
            task: id
        });
    }

    public transfer_delete(id: number):Promise<ITaskTransferResponseSchema> {
        return this._httpClient.delete<ITaskTransferResponseSchema>('/task_transfers/' + id +'/')
    }

    public transfer_approve(id: number): Promise<ITransferAcceptResponseSchema> {
        return this._httpClient.patch<ITransferAcceptResponseSchema>('/task_transfers/' + id + '/approve/');
    }

    public transfer_reject(id: number, reject_message: string): Promise<ITransferRejectResponseSchema> {
        return this._httpClient.patch<ITransferRejectResponseSchema>('/task_transfers/' + id + '/reject/', {reject_message});
    }

    public update_task(id: number, settings: ITaskUpdateSchemaRequest): Promise<ITaskSchema> {
        return this._httpClient.patch<ITaskSchema>('/tasks/' + id + '/', settings);
    }
}

export const taskManager: TaskManager = new TaskManager(httpClient);
