




import {Vue} from 'vue-property-decorator';
import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import {IMOeditorDataSchema} from "@/datastore/schemas/IMOeditorDataSchema";
import {Topic as Topics} from "@/datastore/entities/Topic";
import {Attachment} from "@/datastore/entities/Attachment";
import {EAttachmentType} from "@/store/types";

@Mixin
export default class StoreMixin extends Vue {

    get activeTopic() {
        return this.$store.getters['modal/activeTopic'];
    }

    get activeTopicId() {
        return this.$store.getters['modal/activeTopicId'];
    }

    public postTopic(el: IMOeditorDataSchema){
        return this.activeTask.postTopic(el.content, el.attachments)
    }
    public postMessage(el: IMOeditorDataSchema){
        return Topics.postMessage(this.activeTopicId, el.content, el.attachments)
    }

    get activeTask() {
        return this.$store.getters['modal/activeTask'];
    }

    get from() {
        return this.activeTask.owner.fullNameOrEmail;
    }

    get to() {
        return this.activeTask.recipient.fullNameOrEmail;
    }

    get to_email() {
        return this.activeTask.recipient.email;
    }

    get createdAt() {
        return this.activeTask.created.toLocaleDateString();
    }

    get tags() {
        return this.activeTask.tags;
    }

    get cc() {
        return this.activeTask.cc;
    }

    get dueDate() {
        return this.activeTask.dueDate;
    }

    get title() {
        return this.activeTask.title;
    }

    get description() {
        return this.activeTask.content;
    }

    get taskAttachments(){
        return this.activeTask.attachments;
    }

    get topicsCount() {
        return this.activeTask.topics_count;
    }

    get topics() {
        return this.$store.getters['topics/topicsByTaskId'](this.activeTask.id)
    }

    get topicsToShow() {
        if (this.activeTopicId == -1) {
            return this.topics;
        }
        return [this.activeTopic];
    }

    get topicAttachments(){
        if(this.activeTopicId != -1){
            return this.activeTopic.attachments
        }
        return [];
    }

    private noLinkAttachment(attachments:Attachment[]){
        return attachments.filter((a)=>{return a.attachment_type != EAttachmentType.link})
    }

    get messages() {
        return this.$store.getters['modal/activeTopicMessages'];
    }
}
