


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import CommonTabContent from "@/components/SidePanel/CommonTabContent.vue";
import EditorViewer from "@/components/EditorViewer.vue";


@Component({
    components: {EditorViewer, CommonTabContent},
    computed: {
        ...mapGetters({
            activeTask: 'modal/activeTask',
        })
    },
    name: "TabContent",
})
export default class TabContent extends Vue {

}
