































































import {Component} from 'vue-property-decorator';
import ModalHeader from '@/components/ModalHeader.vue';
import {Mixins} from 'vue-mixin-decorator';
import {ISubTaskModalMixin} from '@/components/Mixin/Mixin';
import StoreMixin from '@/components/Mixin/StoreMixin.vue';
import UserSelect from '@/components/UserSelect.vue';
import {MultiselectUserSchema} from '@/datastore/schemas/UserSchema';
import {taskManager} from '@/datastore/managers/TaskManager';
import IMOEditor from '@/components/IMOEditor.vue';
import {IMOeditorDataSchema} from '@/datastore/schemas/IMOeditorDataSchema';
import {EventBus} from '@/EventBus';

@Component({
    components: {ModalHeader, UserSelect, IMOEditor},
    name: 'TransferModal'
})
export default class TransferModal extends Mixins<ISubTaskModalMixin>(StoreMixin) {

    private transfer_msg: string = '';
    private usr: MultiselectUserSchema = {name: '', email: ''};

    get user() {
        return this.usr;
    }

    set user(val: MultiselectUserSchema) {
        this.usr = val;
    }

    public close() {
        this.$bvModal.hide('Transfer');
    }

    public handleYes() {
        this.trasferisci()
    }

    public handleNo() {
        this.close();
    }

    private trasferisci() {
        taskManager.transfer(this.usr.email, this.transfer_msg, this.$store.getters['modal/activeTask'].id).then((a: any) => {
            // console.log('transfer inviato', a)
            this.$bvModal.hide('Transfer');
        }).catch((e: any) => {
            // console.log("errore transfer", e)
        });
    }
}
