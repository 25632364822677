






























import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import PanelContentTemplate from "@/components/SidePanel/PanelContentTemplate.vue";
import Log from "@/components/SidePanel/Log.vue";
import {taskManager} from "@/datastore/managers/TaskManager";
import {EPanelType,} from "@/store/types";
import RejectForm from "@/components/RejectForm.vue";
import {ITaskTabMixin} from "@/components/Mixin/Mixin";
import ActivityMixin from "@/components/Mixin/ActivityMixin.vue";
import PanelMixin from "@/components/Mixin/PanelMixin.vue";
import {ETaskStatus} from "@/datastore/schemas/TaskSchema";


@Component({
    components: {Log, PanelContentTemplate, RejectForm},
    computed: {
        ...mapGetters({
            activeTask: 'modal/activeTask',
        })
    },
    name: "TaskTab",
})
export default class TaskTab extends Mixins<ITaskTabMixin>(ActivityMixin, PanelMixin) {

    private disable_input: boolean = false;

    get log() {
        return this.$store.getters['modal/activeTaskLog'];
    }

    public handleReject(msg: string) {
        this.disable_input = true;
        const ret = this.processReject(msg);
        if (ret) {
            ret.then((e) => {
                /**
                 * nel caso mi trovi nellavisualizzazione pubblica allora devo anche cambiare lo stato del modal anche se non esiste
                 */
                this.$store.dispatch('modal/panelType', {type: EPanelType.OUT});
                this.disable_input = false;
            }).catch((e) => { /* errore durante l'accettazione del messaggio*/
                this.disable_input = false;
            });
        }
    }

    private processReject(msg: string) {
        if (this.$store.getters['modal/activeTask'].isPending) {
            return taskManager.reject(this.$store.getters['modal/activeTask'].id, msg)
        } else if (this.$store.getters['modal/activeTask'].hasPendingTransfer) {
            return taskManager.transfer_reject(this.$store.getters['modal/activeTask'].pending_transfer.id, msg)
        }
    }
}
