



























import {Component, Vue} from 'vue-property-decorator';
import {EventBus} from "@/EventBus";

@Component({
    components: {},
    name: "SearchBar"
})
export default class SearchBar extends Vue {

    private tout: number = -1;

    get bg_color() {
        const fd = this.$store.getters['dashboard/filterDashboard'];
        if (fd) {
            return "background-color: " + fd.color;
        }
        return '';
    }

    get filterDashboard() {
        if (this.$route.params.type === 'my') {
            return this.$store.getters['dashboard/filterDashboard'];
        }
        return undefined;
    }

    get filterString() {
        return this.$store.getters['dashboard/filterString'];
    }

    get search() {
        return this.filterDashboard ? this.filterDashboard.title : this.filterString;
    }

    set search(val: string) {
        //resetto l'eventuale filtro presente nelle dashboard
        this.$store.dispatch('dashboard/resetFilterDashboardId').then(() => {
            return this.$store.dispatch('dashboard/setFilterString', {filter: val});
        }).then(() => {
            if (this.tout > 0) {
                window.clearTimeout(this.tout);
            }
            this.tout = window.setTimeout(() => {
                EventBus.$emit('filterChanged');
            }, 200)
        })
    }
}
