import {IUserSchema} from "@/datastore/schemas/UserSchema";
import {IActivitySchema} from '@/datastore/schemas/ActivitySchema';

export interface ITransferSchema {
    id: number
    status: ETransferStatus
    request_by: IUserSchema,
    new_recipient: IUserSchema,
}

export interface ITaskTransferResponseSchema {
    new_recipient: number
    task: number
}

export interface ITransferAcceptResponseSchema {

}

export interface ITransferRejectResponseSchema {

}

export enum ETransferStatus {
    pending = 'pending',
    approved = 'approved',
    rejected = 'rejected',
}
