

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
    name: "RejectForm"
})
export default class RejectForm extends Vue {
    @Prop(Boolean) private disable!: boolean;
    @Prop(Boolean) private disableIfEmpty!: boolean;

    private text: string = '';
    private disable_inputs: boolean = this.disable;

    get disableInput() {
        return this.disable;
    }

    get disableButton() {
        return this.text.length == 0 || this.disable;
    }

    public reject() {
        this.$emit('onReject', this.text);
    }
}
