import {ITaskSchema} from "@/datastore/schemas/TaskSchema";
import {IUserSchema} from "@/datastore/schemas/UserSchema";

export enum ERole {
    'recipient'='recipient',
    'owner'='owner',
}

export interface IPublicSchema {
    task: ITaskSchema,
    user: IUserSchema,
    role: ERole

}
