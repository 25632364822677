






import {Component, Prop, Vue} from 'vue-property-decorator';
import {Editor, EditorContent} from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';

@Component({
    components: {EditorContent},
    name: "EditorViewer"
})
export default class EditorViewer extends Vue {
    @Prop(String) private readonly content!: string;
    private editor = new Editor({
        extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({levels: [1, 2, 3]}),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem({
                nested: true,
            }),
            new TodoList(),
            new Bold(),
            new Code(),
            new Italic(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
        ],
        content: this.content,
        editable: false,
    });

    public beforeDestroy() {
        this.editor.destroy();
    }
}
