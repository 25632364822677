import { render, staticRenderFns } from "./ConfirmEmail.vue?vue&type=template&id=859bd046&scoped=true&"
import script from "./ConfirmEmail.vue?vue&type=script&lang=ts&"
export * from "./ConfirmEmail.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmEmail.vue?vue&type=style&index=0&id=859bd046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "859bd046",
  null
  
)

export default component.exports