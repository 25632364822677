









































import {Component, Prop, Vue} from 'vue-property-decorator';
import {userManager} from "@/datastore/managers/UserManager";

@Component({
    components: {},
    name: "ResetPwdConfirm"
})
export default class ResetPwdConfirm extends Vue {
    @Prop(String) private readonly uid!: string;
    @Prop(String) private readonly token!: string;

    private password1: string = '';
    private password2: string = '';
    private error: string = '';
    private message: string = '';
    private showForm: boolean = true;
    private disable: boolean = false;

    public resetPwdConfirm() {
        this.disable = true;
        userManager.resetPasswordConfirmation(this.uid, this.token, this.password1,this.password2).then(()=>{
            this.message = 'Reset password avvenuto con successo';
            this.showForm = false;
        }).catch(()=>{
            this.error = `Impossibile completare l'azione`
            this.disable = false;
        })
    }
}
