




import {Vue} from 'vue-property-decorator';
import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import {Activity as ActivityEntity} from "@/datastore/entities/Activity";
import {EEventTranslation, EEventType} from "@/datastore/schemas/ActivitySchema";

@Mixin
export default class ActivityMixin extends Vue {

    private message(activity:ActivityEntity, includeUser:boolean = true){

        let userInfo: string  = includeUser ? this.byUser(activity) : '';
        let res = ''

        switch (activity.event_type) {
            case EEventType.U:
                res = EEventTranslation.update + ' ' + userInfo + this.beautify(activity.payload);
                break;
            case EEventType.C:
                res = EEventTranslation.create + ' ' + userInfo;
                break;
            case EEventType.D:
                res = EEventTranslation.delete + ' ' + userInfo ;
                break;
            case EEventType.A:
                res = EEventTranslation.approve + ' ' + userInfo;
                break;
            case EEventType.MC:
                res = EEventTranslation.complete + ' ' + userInfo;
                break;
            case EEventType.MU:
                res = EEventTranslation.uncomplete + ' ' + userInfo;
                break;
            case EEventType.R:
                res = EEventTranslation.reject + ' ' + userInfo;
                break;
            case EEventType.NT:
                res = EEventTranslation.transferCreate + ' ' + userInfo;
                break;
            case EEventType.DT:
                res = EEventTranslation.transferDelete + ' ' + userInfo;
                break;
            case EEventType.UT:
                res = EEventTranslation.transferUpdate + ' ' + userInfo;
                break;
            case EEventType.RJ:
                res = EEventTranslation.transferReject + ' ' + userInfo;
                break;
            case EEventType.AT:
                res = EEventTranslation.transferApprove + ' ' + userInfo;
                break;
            default:
                res = 'unknow event'
        }
        if (activity.message)
            res += ' <p>' + activity.message + '</p>'
        return res
    }


    private byUser(a:ActivityEntity){
        let res =  '';
        if (a.user && a.user.id > -1){
            if(a.user.email != this.$store.getters['account/user'].email){
                res = 'by <strong>' + a.user.email + '</strong>'
            } else {
                res = 'by <strong>me</strong>'
            }
        }
        return res
    }

    private beautify(payload:string){
        try{
            const p:any = JSON.parse(payload)[0].fields;
            let msg = '';
            for(let i in p){
                msg += '<p>Attribute <strong>' + i + '</strong> changed from <strong> ' + p[i][0] + '</strong> to <strong>' + p[i][1] + '</strong></p>';
            }
            return msg
        }
        catch (e) {
            return '';
        }
    }
}
