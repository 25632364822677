

























import {Component} from 'vue-property-decorator';
import ModalHeader from '@/components/ModalHeader.vue';
import TaskForm from '@/components/TaskForm.vue';
import TaskModal from './TaskModal.vue';

@Component({
    components: {TaskForm, ModalHeader},
    name: 'AskSupportModal'
})
export default class AskSupportModal extends TaskModal {

    public close() {
        this.$bvModal.hide('AskSupport');
    }

}
