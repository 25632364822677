
























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {ITableConfig, ITableResize} from '@/store/types';
import Sorter from '@/components/Grid/Sorter.vue';

@Component({
    components: {Sorter, draggable},
    name: 'Grid',
})
export default class Grid extends Vue {
    @Prop({type: Boolean, default: true}) private readonly isPaginated!: boolean;
    @Prop({
        type: Array, default: function () {
            return [];
        }
    }) private readonly header!: ITableConfig[];
    @Prop(Number) private readonly min_col_width!: number;

    private dragging: boolean = false;

    private disable_ordering: boolean = false;
    private min_width: number = Math.max(this.min_col_width || 2, 2);
    private sort!: string[];

    private dragInfo: ITableResize = {} as ITableResize;

    get dragOptions() {
        return {
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        };
    }

    styleBuilder(h: ITableConfig) {
        return {
            flex: '0 0 ' + h.width + '%'
        };
    }

    private buildDirection(h: ITableConfig) {
        if (h && h.sort && h.sort.direction) {
            return h.sort.direction;
        }
        return false;
    }

    private buildOrder(h: ITableConfig) {
        if (h && h.sort && h.sort.order) {
            return h.sort.order;
        }
        return 0;
    }

    private buildSort() {
        this.sort = [];
        let count = 0;
        this.sort = this.header.reduce((ret, h, idx) => {
            if (h.sortable) {
                count++;
                if (h.sort.order > 0) {
                    ret[h.sort.order] = h.name;
                }
            }
            return ret;
        }, [] as string[]);
        this.sort.length = count + 1;
    }

    private mounted() {
        this.checkColWidth();
        this.buildSort();
    }

    private dragEnd() {
        this.dragging = false;
        this.emitUpdate();
    }

    private prevent(e: MouseEvent) {
        // e.preventDefault();
        // e.stopPropagation();
    }

    private enableDrag(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.dragInfo.resize_drag = true;
        this.dragInfo.start_drag_evt = e;
        const col = (e.target as any).closest('.cell_cont');
        this.dragInfo.drag_col = col;
        this.dragInfo.col_idx = parseInt(col.getAttributeNode('data_col_id').value);
        const width = col.offsetWidth;
        this.dragInfo.perc_on_px = this.header[this.dragInfo.col_idx].width / width;
        this.dragInfo.start_conf_width = this.header.map((e) => {
            return e.width
        })
    }

    private disableDrag(e: MouseEvent) {
        this.dragInfo.resize_drag = false;
        this.disable_ordering = false;
    }

    private dragMove(e: MouseEvent) {
        if (this.dragInfo.resize_drag) {
            e.stopPropagation();
            e.preventDefault();

            const offset = e.pageX - this.dragInfo.start_drag_evt.pageX;
            const perc = this.dragInfo.perc_on_px * offset;

            /**
             *devo ripristinare le dimensioni per mantenere la coenreza quando ridimensiono, se perc passa da 100 a -100 all'improvviso
             *perdo la dimensione effettiva iniziale delle colonne e il ridimensionamento si sputtana
             */

            this.dragInfo.start_conf_width.forEach((e, i) => {
                this.header[i].width = e;
            });

            /**
             * quando allargo una colonna viene tolto spazio alle colonne che le stanno a destra via via fino a
             * raggiungere la la dimesione minima, quando la colonna all'immediata destra raggiunge la dimensione
             * minima si passa alla successiva, finché non  arrivo all'ultima.
             * Quando si stringe una colonna funziona allo stesso modo ma è come se si allargasse qualla successiva,
             * e lo spazio viene tolto da quelle che la precedono in ordine decrescente
             */
            if (perc >= 0) {
                let da_ripartire = perc;
                for (let i = this.dragInfo.col_idx + 1; i < this.header.length; i++) {
                    const next_col_width = Math.max(this.min_width, this.dragInfo.start_conf_width[i] - da_ripartire);
                    da_ripartire += next_col_width - this.dragInfo.start_conf_width[i];
                    this.header[i].width = next_col_width;
                    if (Math.round(da_ripartire) == 0) {
                        break
                    }
                }
                this.adjustTableSizes(this.dragInfo.col_idx);
            } else {
                let da_ripartire = perc;
                for (let i = Math.max(0, this.dragInfo.col_idx); i >= 0; i--) {
                    const prev_col_width = Math.max(this.min_width, this.dragInfo.start_conf_width[i] + da_ripartire);
                    da_ripartire -= prev_col_width - this.dragInfo.start_conf_width[i];
                    this.header[i].width = prev_col_width;
                    if (Math.round(da_ripartire) == 0) {
                        break
                    }
                }
                this.adjustTableSizes(this.dragInfo.col_idx + 1);
            }
            this.emitUpdate();
        }
    }

    private adjustTableSizes(col: number) {
        const non_drag_sum = this.header.reduce((sum, h, index) => {
            if (this.dragInfo.resize_drag && index != col) {
                return sum + h.width;
            }
            return sum;
        }, 0);
        this.header[col].width = 100 - non_drag_sum;
    }

    private emitUpdate() {
        this.$emit('settingsUpdate', this.header);
    }

    private emitOrderChange() {
        const s = this.sort.reduce((ret, x) => {
            if (x) {
                const hd = this.header.find((h) => {
                    return h.name == x;
                });
                if (hd) {
                    ret.push((hd.sort.direction ? '' : '-') + hd.sort.sort_field);
                }
            }
            return ret;
        }, [] as string[]);
        this.$emit('orderChange', s);
    }

    private checkColWidth() {
        if (this.header.length) {

            const sum = this.header.reduce((sum, h, index) => {
                return sum + h.width;

            }, 0);
            if (sum > 100) {
                const avg = 100 / this.header.length;
                this.header.map((h) => {
                    return h.width = avg;
                });
            } else if (sum < 100) {
                this.header[this.header.length - 1].width += 100 - sum;
            }
        }
    }

    private directionChangeHandle(field: string) {
        const h = this.header.find((el) => {
            return el.name == field;
        });
        if (h) {
            h.sort.direction = !h.sort.direction;
            this.emitOrderChange();
        }
    }

    // private orderChange(field: string) {
    //     const h = this.header.find((el) => {
    //         return el.name == field;
    //     });
    //
    //     if (h) {
    //
    //         if (!h.sortable) {
    //             return false;
    //         }
    //
    //         let last_order = h.sort.order;
    //         if (h.sort.order === 1) {
    //             h.sort.direction = !h.sort.direction;
    //         } else {
    //             this.header.map((col) => {
    //                 if (col === h) {
    //                     col.sort.order = 1;
    //                 } else if (col.sortable && col.sort.order > 0) {
    //                     if (last_order === 0 || col.sort.order < last_order) {
    //                         col.sort.order = col.sort.order + 1;
    //                     }
    //                 }
    //             });
    //         }
    //         this.buildSort();
    //         this.emitOrderChange();
    //     }
    // }

    private orderChangeHandle(field: string, index: number) {
        const h = this.header.find((el) => {
            return el.name == field;
        });
        if (h) {
            // console.log(this.checkSortSpace(h.sort.order));
            h.sort.order = 1;
            this.recalculateOrder(h);
            this.buildSort();
            this.emitOrderChange();
        }
    }

    private orderCancelHandler(field: string) {
        const h = this.header.find((el) => {
            return el.name == field;
        });
        if (h) {
            h.sort.order = 0;
            this.recalculateOrder(h);
            this.buildSort();
            this.emitOrderChange();
        }

    }

    private recalculateOrder(h: any) {
        let ord_val = h.sort.order;
        let a = this.header.reduce((ret: any, hh, idx) => {
                if (hh.sortable && hh.sort.order > 0 && hh !== h) {
                    ret.push(hh)
                }
                return ret;
            }, []
        )
        a.sort((a: any, b: any) => {
            return b.sort.order - a.sort.order;
        }).map((el: any) => {
            return el.sort.order = ++ord_val
        })
    }

    private checkSortSpace(idx: number) {
        for (let i = 0; i < this.sort.length; i++) {
            if (!this.sort[(idx + i + 1) % this.sort.length]) {
                return (idx + i + 1) % this.sort.length;
            }
        }
        return idx;
    }
}
