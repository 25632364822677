
























import {Component, Prop, Vue} from 'vue-property-decorator';
import {NewTaskFormFieldError} from '@/store/types';
import ModalHeader from '@/components/ModalHeader.vue';
import {taskManager} from '@/datastore/managers/TaskManager';
import {ITaskNS} from "@/datastore/schemas/TaskSchema";
import TaskForm from "@/components/TaskForm.vue";

@Component({
    components: {TaskForm, ModalHeader},
    name: "TaskModal"
})
export default class TaskModal extends Vue {

    private taskError: NewTaskFormFieldError = new NewTaskFormFieldError();

    public close() {
        this.$bvModal.hide('Task');
    }

    public send(el: ITaskNS) {
        const values = el as ITaskNS;
        this.taskError.reset();
        taskManager.new_task(
            values.title,
            values.recipient,
            values.description,
            values.due_date,
            values.tags,
            values.attachments,
            values.cc
        ).then((e) => {
            this.close();
        }).catch((e) => { /* errore durante l'accettazione del messaggio*/
            this.taskError = new NewTaskFormFieldError(e.response.data);
        });
    }
}
