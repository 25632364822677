











































import {Component, Vue} from 'vue-property-decorator';
import {ETaskStatus, ITaskSchema} from "@/datastore/schemas/TaskSchema";
import {dashboardManager} from "@/datastore/managers/DashboardManager";
import {EventBus} from "@/EventBus";

@Component({
    components: {},
    name: "DeleteTaskModal"
})
export default class DeleteTaskModal extends Vue {
    private delete_msg:string = "";

    get active_modal_task() {
        return this.$store.getters['modal/activeTask'];
    }

    public handleYes() {
        this.$bvModal.hide('DeleteTask');
        this.$store.dispatch('task/delete', {id: this.active_modal_task.id, msg:this.delete_msg}).then(() => {
            this.delete_msg = '';
            EventBus.$emit('hidePanel');
        }).catch(() => {
            // console.log('errore cancellazione task')
        })
    }

    public handleNo() {
        this.$bvModal.hide('DeleteTask');
    }
}
