








































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Box from '@/components/Box.vue';
import SearchBar from '@/components/SearchBar.vue';
import {ToggleButton} from 'vue-js-toggle-button'
import {MenuRoutes} from '@/routes.ts';

@Component({
    components: {Box, SearchBar, ToggleButton},
    name: "Header"
})
export default class Header extends Vue {
    @Prop(String) private readonly type!: string;

    private routes = MenuRoutes;

    get statistics() {
        return this.$store.getters['account/userTaskStatistics'];
    }

    get completed() {
        return this.$store.getters['dashboard/showCompleted'];
    }

    get toggleTitle(){
        return this.completed ? this.$t('message.hide_completed') : this.$t('message.show_completed');
    }

    set completed(b: boolean) {
        if (b) {
            this.$store.dispatch('dashboard/showCompleted')
        } else {
            this.$store.dispatch('dashboard/hideCompleted')
        }
    }

}
