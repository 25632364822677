import {Task} from '@/datastore/entities/Task';
import {Topic} from '@/datastore/entities/Topic';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {EAttachmentType, EPanelType, ITaskExpandedDict, RootState} from '@/store/types';
import store from '@/store/index'
import {Message} from "@/datastore/entities/Message";
import {taskMessageManager} from "@/datastore/managers/TaskMessageManager";
import {Dashboard} from "@/datastore/entities/Dashboard";
import Vue from 'vue';
import {Activity} from "@/datastore/entities/Activity";
import {activityManager} from "@/datastore/managers/ActivityManager";

const namespaced: boolean = true;

interface IModalState {
    empty_task: Task;
    empty_topic: Topic;
    empty_dashboard: Dashboard;
    panel_type: EPanelType;
    panel_tab: number;
    active_task_id: number;
    active_dashboard: Dashboard | null;
    active_topic: Topic | null;
    active_topic_messages: Message[];
    active_media: EAttachmentType;
    active_task_log: Activity[]
    expanded: ITaskExpandedDict;
    attachment_to_delete_id: number
}

const modalState: IModalState = {
    empty_task: new Task(),
    empty_topic: new Topic(),
    empty_dashboard: new Dashboard(),
    panel_type: EPanelType.NONE,
    panel_tab: 0,
    active_task_id: -1,
    active_dashboard: null,
    active_topic: null,
    active_topic_messages: [],
    active_media: EAttachmentType.NONE,
    active_task_log: [],
    expanded: {},
    attachment_to_delete_id: -1
};

const getters: GetterTree<IModalState, RootState> = {
    isExpanded: (state) => (id: number) => {
        return state.expanded[id] || false;
    },

    attachmentToDelete:(state) =>{
        return state.attachment_to_delete_id
    },

    activeTaskLog:(state)=>{
        return state.active_task_log;
    },
    activeMedia: (state) => {
        return state.active_media;
    },
    activeTopicMessages(state): Message[] {
        return state.active_topic_messages;
    },
    activeTopic(state): Topic {
        return state.active_topic ? state.active_topic : state.empty_topic;
    },
    activeTaskId(state): number {
        return state.active_task_id;
    },
    activeTask(state, getters, rootState, rootGetters): Task {
        if (state.active_task_id > 0) {
            return rootGetters['task/taskById'](state.active_task_id);
        }
        return state.empty_task;
    },
    panelMatch: (state) => (arr: number[]) => {
        return arr.includes(state.panel_type);
    },
    panelType(state): EPanelType {
        return state.panel_type;
    },
    panelTab(state): number {
        return state.panel_tab;
    },
    activeTopicId(state): number {
        return state.active_topic ? state.active_topic.id : -1;
    },
    activeDashboard(state): Dashboard {
        return state.active_dashboard ? state.active_dashboard : state.empty_dashboard;
    },
    activeDashboardId(state): number {
        return state.active_dashboard ? state.active_dashboard.id : -1;
    },
};

const actions: ActionTree<IModalState, RootState> = {
    setDeleteAttachment({state,commit},{id}){
        commit('deleteAttachment',id)
    },

    /**
     * attenzione: esegue il push del topic forzatamente solo per renderizzarlo nel modal
     * non e quindi permanente, una succesiva update lo cancella, usato solo nelle websocket quando arriva un messaggio
     * @param state
     * @param commit
     * @param topicId
     * @param messageId
     */
    pushMessage({state, commit, dispatch}, {topicId, messageId}) {
        return taskMessageManager.message_by_id_and_topic_id(topicId, messageId).then((m) => {
            commit('pushMessage', new Message(m));
        });

    },
    fetchActiveTaskLogs({state, commit},{id}){
        if(state.active_task_id >= 0){
            activityManager.task_logs(state.active_task_id).then((l)=>{
                commit('setActiveTaskLogs', l);
            })
        } else {
            commit('setActiveTaskLogs', []);
        }
    },
    activeMedia({commit}, {type}) {
        commit('activeMedia', type)
    },
    resetActiveMedia({dispatch}) {
        dispatch('activeMedia', {type: EAttachmentType.NONE})
    },
    expand({commit}, {id}) {
        commit('setExpand', {id, val: true},);
    },
    collapse({commit}, {id}) {
        commit('setExpand', {id, val: false},);
    },
    toggleExpand({commit}, {id}) {
        commit('toggleExpand', id);
    },
    fetchActiveTopicMessages({state, commit, dispatch}) {
        if (state.active_topic && state.active_topic.id > 0) {
            taskMessageManager.fetch_messages_by_topic_id(state.active_topic.id).then((m) => {
                commit('setActiveTopicMessages', m);
                dispatch('account/fetchMessagesToRead', {}, {root:true});
                if (state.active_topic && state.active_topic.id > 0) {
                    dispatch('task/fetchToReadCount', {id: state.active_topic.task}, {root:true});
                    dispatch('topics/fetchToReadCount', {id: state.active_topic.id}, {root:true});
                }
            });
        }
    },
    removeMessageAttachment({state, commit, dispatch},p) {
        commit('removeMessageAttachment', p )
    },
    resetActiveTopic({state, commit, dispatch}) {
        commit('setActiveTopic', state.empty_topic);
        commit('setActiveTopicMessages', []);
    },
    resetActiveTask({state, commit, dispatch}) {
        commit('setActiveTask', -1);
    },
    setActiveTopic({commit}, {topic}) {
        commit('setActiveTopic', topic);
    },
    setActiveTopicById({commit}, {id}) {
        commit('setActiveTopic', store.getters['topics/topicById'](id));
    },
    panelType({commit}, {type}) {
        commit('setPanelType', type);
    },
    panelTab({commit}, {tab_index}) {
        commit('setPanelTab', tab_index);
    },
    setActiveTaskById({commit, dispatch, getters, rootState}, {taskid}) {
        commit('setActiveTask', taskid);
    },
    setActiveDashboardById({commit, dispatch, getters, rootState}, {id}) {
        commit('setActiveDashboard', store.getters['dashboard/dashboardById'](id));
    }
};

const mutations: MutationTree<IModalState> = {
    deleteAttachment(state, id) {
        state.attachment_to_delete_id = id;
    },
    setActiveTopic(state, c) {
        state.active_topic = c;
    },
    activeMedia(state, type) {
        state.active_media = type
    },
    setPanelType(state, type) {
        state.panel_type = type;
    },
    setPanelTab(state, tab_index) {
        state.panel_tab = tab_index;
    },
    setActiveTask(state, t_id) {
        state.active_task_id = t_id;
    },
    setActiveTopicMessages(state, m) {
        state.active_topic_messages = m;
    },
    setActiveTaskLogs(state, l) {
        state.active_task_log = l;
    },
    pushMessage(state, m) {
        state.active_topic_messages.push(m);
    },
    setActiveDashboard(state, d) {
        state.active_dashboard = d;
    },
    setExpand(state, d) {
        Vue.set(state.expanded, d.id, d.val);
    },
    toggleExpand(state, id) {
        Vue.set(state.expanded, id, !(state.expanded[id] || false));
    },
    removeMessageAttachment(state, payload){
        //cerco il messaggio
        const m = state.active_topic_messages.find((msg)=>{
            return msg.id == payload.m_id;
        })
        if(m){
            //cerco l'allegato
            const a = m.attachments.findIndex((att)=>{
                return att.id == payload.a_id
            })

            //lo rimuovo
            if(a != -1){
                m.attachments.splice(a,1);
            }
        }
    }
};


export default {
    namespaced,
    state: modalState,
    getters,
    actions,
    mutations,
};
