



















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    components: {},
    name: "Sorter",
})
export default class Sorter extends Vue {
    @Prop({type: Boolean, default: false}) private readonly direction!: boolean;
    @Prop({type: Number, default: 0}) private readonly order!: number;
    @Prop(String) private readonly field!: string;
}
