export class EnvironmentHelper {

    public static get apiBaseUrl(): string {
        return process.env.VUE_APP_API_BASE_URL || '';
    }

    public static get wsBaseUrl(): string {
        return process.env.VUE_APP_WS_BASE_URL || '';

    }

    public static get isDevelopment(): boolean {
        return process.env.NODE_ENV === 'development';
    }

    public static get isProduction(): boolean {
        return process.env.NODE_ENV === 'production';
    }

    public static get facebookId(): string {
        return process.env.VUE_APP_FACEBOOK_ID || '';

    }

    public static get facebookUrl(): string {
        return process.env.VUE_APP_FACEBOOK_URL || '';

    }

    public static get googleId(): string {
        return process.env.VUE_APP_GOOGLE_ID || '';

    }

    public static get googleUrl(): string {
        return process.env.VUE_APP_GOOGLE_URL || '';

    }

    public static get microsoftId(): string {
        return process.env.VUE_APP_MICROSOFT_ID || '';

    }

    public static get microsoftUrl(): string {
        return process.env.VUE_APP_MICROSOFT_URL || '';

    }

    public static get supportEmail(): string {
        return process.env.VUE_APP_SUPPORT_EMAIL || '';

    }

    public static get premiumUploadSize(): string {
        return process.env.VUE_APP_PREMIUM_UPLOAD_SIZE || '';

    }


}
