import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import store from './store/index';
import VueScrollTo from 'vue-scrollto'
import BootstrapVue from 'bootstrap-vue';
import VCalendar from 'v-calendar';
import "vue-swatches/dist/vue-swatches.min.css"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'
import axios from 'axios';
import VueI18n from 'vue-i18n'
import {en} from '@/locale/en'

const messages = { en,}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    messages,
})


import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (EnvironmentHelper.isProduction) {
    Sentry.init({
        dsn: 'https://ab3ced027b2946ffa4bc6a3bc551125c@sentry.io/1833332',
        integrations: [new Integrations.Vue({Vue, attachProps: true})],
    });
}

Vue.use(VueScrollTo);
Vue.use(BootstrapVue);
Vue.use(VCalendar);
Vue.use(VueAxios, axios)

Vue.use(VueSocialauth, {

    providers: {
        facebook: {
            clientId: EnvironmentHelper.facebookId.toString(),
            redirectUri: EnvironmentHelper.facebookUrl.toString(),
        },
        google: {
            clientId: EnvironmentHelper.googleId.toString(),
            redirectUri: EnvironmentHelper.googleUrl.toString(),
        },
        live: {
            clientId: EnvironmentHelper.microsoftId.toString(),
            redirectUri: EnvironmentHelper.microsoftUrl.toString(),
        },
    }
});

import {library , IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {
    faEnvelope,
    faSquare,
    faExclamationTriangle,
    faTimesCircle,
    faPen,
    faStar,
    faPlus,
    faComment,
    faTimes,
    faBold,
    faUnderline,
    faItalic,
    faListUl,
    faPaperclip,
    faAt,
    faReply,
    faAngleUp,
    faAngleDown,
    faAngleRight,
    faAngleLeft,
    faDownload,
    faClock,
    faPencilAlt,
    faCheck,
    faTrashAlt,
    faSort,
} from '@fortawesome/free-solid-svg-icons';

import {faComment as farComment} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faFacebookF, faGoogle, faWindows} from '@fortawesome/free-brands-svg-icons'
import {MenuRoutes} from "@/routes";
import {EnvironmentHelper} from "@/EnvironmetHelper";

library.add(
    faExclamationTriangle,
    faEnvelope,
    faSquare,
    faTimesCircle,
    faPen,
    faStar,
    faPlus,
    faComment,
    farComment,
    faTimes,
    faBold,
    faUnderline,
    faItalic,
    faListUl,
    faPaperclip,
    faAt,
    faReply,
    faAngleUp,
    faAngleDown,
    faAngleRight,
    faAngleLeft,
    faDownload,
    faClock,
    faPencilAlt,
    faCheck,
    faTrashAlt,
    faSort,
    faFacebookF as IconDefinition,
    faGoogle as IconDefinition,
    faWindows as IconDefinition
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

export const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
    created() {
        if(router.currentRoute.name !== 'publicTask'){
            store.dispatch('account/getProfile').then(() => {
                return router.push(MenuRoutes.dashboard.to);
            }).catch(() => {
                window.localStorage.removeItem('auth-token');
            }).finally(() => {
                this.$mount('#app');
            })
        } else {
            this.$mount('#app');
        }

    },
});






