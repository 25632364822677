































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DashboardFormFieldError} from '@/store/types';
import {EditorContent} from 'tiptap';
import {INewDashboardSchema} from "@/datastore/schemas/DashboardSchema";
import Swatches from 'vue-swatches'
import UserSelect from "@/components/UserSelect.vue";
import {MultiselectUserSchema} from "@/datastore/schemas/UserSchema";
import TagSelect from "@/components/TagSelect.vue";
import {MultiselectTagSchema} from "@/datastore/schemas/TagSchema";
import {User} from "@/datastore/entities/User";
import {Tag} from "@/datastore/entities/Tag";

@Component({
    components: {TagSelect, UserSelect, EditorContent, Swatches},
    name: "DashboardForm"
})
export default class DashboardForm extends Vue {

    @Prop(Object) private readonly error!: DashboardFormFieldError;

    private name: string = '';
    private usrs: MultiselectUserSchema[] = [];
    private tgs: MultiselectTagSchema[] = [];
    private color: string = '';
    private colors: string[] = [
        '#6899AA',
        '#B9DDAD',
        '#89BBE0',
        '#FBBE7C',
        '#B4B3DB',
        '#F59597',
        '#A9DCD3',
        '#BAC9B2',
        '#90D0B6'
    ];

    get activeDashboard() {
        return this.$store.getters['modal/activeDashboard'];
    }

    private mounted() {
        this.usrs = (this.activeDashboard ? this.activeDashboard.users : []).map((u:User) => {
            return u.MultiselectItem;
        });
        this.tgs = (this.activeDashboard ? this.activeDashboard.tags : []).map((u:Tag) => {
            return u.MultiselectItem;
        });
        this.name = this.activeDashboard ? this.activeDashboard.title : '';
        //devo fare il controllo perchè lo script di generazione dati potrebbe non includere quel colore e fa schiantare la modifica, a regime non servirà più
        this.color = (this.activeDashboard && this.activeDashboard.color && this.colors.includes(this.activeDashboard.color)) ? this.activeDashboard.color : this.colors[0];
    }

    private send() {
        const d = {
            title: this.name,
            users: this.usrs.map((u) => {
                return u.email
            }),
            tags: this.tgs.map((t) => {
                return t.title
            }),
            color: this.color,
        } as INewDashboardSchema;
        this.$emit('onSend', d)
    }

    private remove() {
        this.$bvModal.show('DeleteDashboard');
    }
}
