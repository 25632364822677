import {IFrontSettings, IUsersSettingsSchema} from "@/datastore/schemas/UsersSettingsSchema";

export class UserSettings {
    public readonly notify_new_message: boolean;
    public readonly notify_new_topic: boolean;
    public readonly notify_new_task: boolean;
    public readonly notify_task_status_updated: boolean;
    public readonly notify_task_pending_status_updated: boolean;
    public readonly frontend_settings: IFrontSettings;


    constructor();
    constructor(data: IUsersSettingsSchema);
    constructor(data?: any) {
        this.notify_new_message = (data && data.notify_new_message) || false;
        this.notify_new_topic = (data && data.notify_new_topic) || false;
        this.notify_new_task = (data && data.notify_new_task) || false;
        this.notify_task_status_updated = (data && data.notify_task_status_updated) || false;
        this.notify_task_pending_status_updated = (data && data.notify_task_pending_status_updated) || false;
        this.frontend_settings = JSON.parse(data && data.frontend_settings || '{}');

    }

}

