

























import {Component, Vue} from "vue-property-decorator";
import {EnvironmentHelper} from "@/EnvironmetHelper";
import {EventBus} from "@/EventBus";
import {usersManager} from "@/datastore/managers/UsersManager";
import {MenuRoutes} from "@/routes";
import {ISignUpError} from "@/store/types";

@Component({
    components: {},
    name: "Menu"
})
export default class Debugger extends Vue {
    eh = EnvironmentHelper;
    private wsConnectionStatus: boolean = false;
    private users: any = [];

    public mounted() {
        if (!this.eh.isDevelopment) {
            throw 'Debugger enable in non production enviroment'
        }
        EventBus.$on('wsOpen', (data: string) => {
            this.wsConnectionStatus = true;
        });

        EventBus.$on('wsError', (data: string) => {
            this.wsConnectionStatus = false;
        });

        EventBus.$on('wsClose', (data: string) => {
            this.wsConnectionStatus = false;
        });

        usersManager.users().then((e) => {
            e.forEach((o) => {
                const opt = document.createElement('option');
                opt.value = o.email;
                opt.innerText = o.email + ' - ' + o.id;
                (this.$refs.select as Element).appendChild(opt);
            })
        })
    }

    private changeUser(e: any) {
        const email = (this.$refs.select as HTMLSelectElement).options[(this.$refs.select as HTMLSelectElement).selectedIndex].value;
        //@ts-ignore
        const password = 'password' + email.match(/\d+/g)[0];

        this.$store.dispatch('account/signOut').then((e) => {
            return this.$store.dispatch('account/signIn', {email, password})
        }).then((e) => {
            this.$router.push(MenuRoutes.dashboard.to);
            this.$store.dispatch('account/fetchStatistics');
        }).catch((e: ISignUpError) => {
        })
    }
}
