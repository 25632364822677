import {IAttachmentSchema} from '@/datastore/schemas/AttachmentSchema';
import {User} from '@/datastore/entities/User';
import {EAttachmentType} from "@/store/types";

export class Attachment {

    public readonly id: number;
    public readonly file: string;
    public readonly user: User;
    public readonly created: Date;
    public readonly modified: Date;
    public readonly name: string;
    public readonly url: string;
    public readonly description: string;
    public readonly preview: null | string;
    public readonly task_set: number[];
    public readonly message_set: number[];
    public readonly attachment_type: EAttachmentType;

    constructor();
    constructor(data: IAttachmentSchema);
    constructor(data?: any) {
        this.id = data && data.id || -1;
        this.file = data && data.file || '';
        this.user = new User(data && data.user);
        this.created = new Date(data && data.created || 0);
        this.modified = new Date(data && data.modified || 0);
        this.name = data && data.name || '';
        this.preview = data && data.preview || '';
        this.description = data && data.description || '';
        this.url = data && data.url || '';
        this.task_set = data && data.task_set || [];
        this.message_set = data && data.message_set || [];
        //@ts-ignore
        this.attachment_type = EAttachmentType[data.attachment_type] as EAttachmentType;
    }
}
