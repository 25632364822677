import {Extension, Plugin} from 'tiptap';

export interface MaxSizeConf {
    maxSize: number
}

export class sendShortcut extends Extension {
    private callback: () => boolean | undefined;

    constructor(callback: () => boolean | undefined) {
        super();
        this.callback = callback
    }

    private keys() {
        return {
            'Ctrl-Enter': this.callback,
        }
    }
}

export class MaxSize extends Extension {
    private max_size: number | undefined

    constructor(options: MaxSizeConf) {
        super();
        this.max_size = options.maxSize || undefined
    }

    get name() {
        return 'maxsize';
    }

    get plugins() {
        const self = this
        return [
            new Plugin({
                filterTransaction: (transaction: any) => {
                    if (!transaction.docChanged || !self.max_size) {
                        return true
                    }

                    const size = transaction.doc && transaction.doc.textContent.length
                    const maxSizeReached = size > self.max_size
                    return !maxSizeReached
                }
            }),
        ]
    }

}

