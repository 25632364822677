























import {Component, Prop, Vue} from 'vue-property-decorator';
import {userManager} from "@/datastore/managers/UserManager";
import {Home} from "@/routes";
import Loading from 'vue-loading-overlay';


@Component({
    components: {Loading},
    name: "EmailConfirm"
})
export default class EmailConfirm extends Vue {
    @Prop(String) private readonly qkey!: string;

    private confirmed: boolean = false;
    private wait: boolean = true;
    private message: string = '';
    private Home = Home;

    mounted() {
        userManager.confirmEmail(this.qkey).then(() => {
            this.confirmed = true;
            this.wait = false;
            this.message = `Email successfully confirmed!`;
        }).catch((e) => {
            this.confirmed = false;
            this.wait = false;
            this.message = `Unable to confirm your email.`
        })
    }
}
