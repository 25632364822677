import {Topic} from '@/datastore/entities/Topic';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {ITopicDict, ITaskTopicsDict, RootState} from '@/store/types';
import {taskTopicManager} from '@/datastore/managers/TaskTopicManager';
import Vue from 'vue';
import {userManager} from '@/datastore/managers/UserManager';
import store from '@/store';

const namespaced: boolean = true;

interface ITopicState {
    dictionary: ITopicDict;
    task_topics: ITaskTopicsDict;
}

const topicState: ITopicState = {
    dictionary: {},
    task_topics: {},
};

const getters: GetterTree<ITopicState, RootState> = {
    dictionary(state): ITopicDict {
        return state.dictionary;
    },
    topicById: (state) => (id: number) => {
        return state.dictionary[id];
    },
    topicsByTaskId: (state) => (id: number) => {
        let ret: Topic[] = [];
        if (state.task_topics[id]) {
            state.task_topics[id].forEach((c: number) => {
                ret.push(state.dictionary[c] as Topic);
            });
            return ret;
        }
        return null;
    }

};

const actions: ActionTree<ITopicState, RootState> = {
    fetchToReadCount({commit}, {id}) {
        if (id as number > 0) {
            userManager.messagesToRead(undefined, id).then(mc => {
                commit('setMessagesToRead', {to_read: mc.to_read, id});
            });
        }
    },
    resetToReadCount({commit}, {id}) {
        commit('setMessagesToRead', {to_read: 0, id});
    },
    fetchTopicsByTaskId({commit, dispatch}, {id}) {
        if (id as number > 0) {
            taskTopicManager.fetch_task_topics(id).then((cs) => {
                commit('emptyTaskTopic', id);
                cs.forEach((c) => {
                    const co = new Topic(c);
                    commit('setTopic', co);
                    commit('pushTaskTopics', {id, c_id: co.id});
                });
                dispatch('account/fetchMessagesToRead', {}, {root: true});
                dispatch('task/fetchToReadCount', {id}, {root: true});
            });
        }

    },
    //todo BRUTTO il settaggio di un topico dovrebbe sempre passare dallo store e non permettere chiamate dirette
    setTopic({commit}, {c}) {
        // console.log(c)
        commit('setTopic', c);
        commit('pushTaskTopics', {id: c.task_id, c_id: c.id});
    }
};

const mutations: MutationTree<ITopicState> = {
    setTopic(state, c) {
        Vue.set(state.dictionary, c.id, c);
    },

    emptyTaskTopic(state, id) {
        Vue.set(state.task_topics, id, []);
    },

    pushTaskTopics(state, t) {
        let arr = state.task_topics[t.id];
        if (!arr) {
            arr = [];
            Vue.set(state.task_topics, t.id, arr);
        }
        arr.push(t.c_id);
    },
    setMessagesToRead(state, {to_read, id}) {
        if (state.dictionary[id]) {
            state.dictionary[id].messages_to_read = to_read;
        }
    }

};


export default {
    namespaced,
    state: topicState,
    getters,
    actions,
    mutations,
};
